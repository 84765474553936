import React, { useCallback, useContext, useEffect, useState } from "react";
import { ClimaAtualStyled } from "../../styles/meteorologia/tempoAtual";
import { MdLocationOn } from "react-icons/md";
import { ClimaContext } from "../../contexts/contexClima";
import temperatura from "../../assets/estacao-meteorologica/temperatura.svg";
import { SquareLoading } from "../../components/loaders";
import { apiConnect } from "../../services/api";

export const ClimaAtual = () => {
  const { selecionarImagemClima, setClimaIdSelect, climaAtual, setClimaAtual } =
    useContext(ClimaContext);

  const [listLocalidadeClima, setListLocalidadeClima] = useState([]);

  const listLocalidade = useCallback(async () => {
    const token = sessionStorage.getItem("connectionsToken");
    const idUnidade = sessionStorage.getItem("connectionsUnitId");
    const headerApi = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await apiConnect.get(
        "/meteorologia/localidade/LeituraTodos",
        headerApi
      );
      const filterUnidade = response.data.filter(
        (e) => e.unidade.id === Number(idUnidade)
      );
      setListLocalidadeClima(filterUnidade);
      if (filterUnidade.length > 0) {
        setClimaIdSelect(filterUnidade[0].meteorologiaAtual[0].id);
      }
    } catch (error) {
      setListLocalidadeClima([]);
      setClimaIdSelect(null);
    }
  }, [setClimaIdSelect]);

  const listClimaAtualSelect = useCallback(
    async (valueId) => {
      const token = sessionStorage.getItem("connectionsToken");
      const headerApi = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      try {
        const response = await apiConnect.get(
          `/meteorologia/atual/Leitura/${valueId}`,
          headerApi
        );
        setClimaAtual(response.data);
        setClimaIdSelect(response.data.id);
      } catch (error) {
        // Lidar com o erro, se necessário
      }
    },
    [setClimaAtual, setClimaIdSelect]
  );

  useEffect(() => {
    listLocalidade();
  }, [listLocalidade]);

  useEffect(() => {
    if (listLocalidadeClima.length > 0) {
      listClimaAtualSelect(listLocalidadeClima[0].meteorologiaAtual[0].id);
    }
  }, [listLocalidadeClima, listClimaAtualSelect]);

  if (!climaAtual || !listLocalidadeClima.length > 0) {
    return (
      <ClimaAtualStyled>
        <SquareLoading />
      </ClimaAtualStyled>
    );
  }

  const formatarData = (data) => {
    const diaSemana = data.toLocaleDateString("pt-BR", { weekday: "long" });
    const diaSemanaCapitalizado =
      diaSemana.charAt(0).toUpperCase() + diaSemana.slice(1);
    const dataFormatada = data.toLocaleDateString("pt-br", {
      day: "2-digit",
      month: "long",
      year: "numeric",
    });
    return { diaSemana: diaSemanaCapitalizado, dataFormatada };
  };

  const { diaSemana, dataFormatada } = formatarData(new Date());

  const imagemClima = selecionarImagemClima(climaAtual.description, new Date());

  const sunriseTimestamp = 1729499919 * 1000; // Converter para milissegundos
  const sunsetTimestamp = 1729545154 * 1000;

  return (
    <ClimaAtualStyled>
      <div className="climaAtual">
        <div className="localClima">
          <MdLocationOn className="iconeLocation" />
          <select
            name=""
            id=""
            onChange={(e) => listClimaAtualSelect(Number(e.target.value))}
          >
            {listLocalidadeClima.map((listClima) => {
              return (
                <option
                  key={listClima.id}
                  value={listClima.meteorologiaAtual[0].id}
                >
                  <span>
                    {listClima.nome} {listClima.cidade} - {listClima.uf}
                  </span>
                </option>
              );
            })}
          </select>
        </div>
        <div className="diaAtual">
          <h3>{diaSemana}</h3>
          <p>{dataFormatada}</p>
        </div>
        <div className="temperatura">
          <div className="temperaturaDia">
            <img src={temperatura} alt="" />
            <h3>{Math.round(climaAtual.temp)}°</h3>
          </div>
          <p>
            Mínima {Math.round(climaAtual.temp_min)}°, Máxima{" "}
            {Math.round(climaAtual.temp_max)}°
          </p>
        </div>
      </div>
      <div className="statusCeu">
        <div className="tipoCeu">
          <img src={imagemClima} alt="Imagem do Clima" />
          <div>
            <h3>
              {climaAtual.description.charAt(0).toUpperCase() +
                climaAtual.description.slice(1)}
            </h3>
            <p>Sensação Térmica de {Math.round(climaAtual.feels_like)}°c</p>
          </div>
        </div>
      </div>
    </ClimaAtualStyled>
  );
};
