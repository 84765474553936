import { useState, useEffect, useRef } from "react";
import ReactMarkdown from "react-markdown";
import rehypePrismPlus from "rehype-prism-plus";
import remarkGfm from "remark-gfm";
import Prism from "prismjs";
import { toast } from "react-toastify";
import { BiSolidSend } from "react-icons/bi";
import { FaArrowCircleDown, FaSquare } from "react-icons/fa";
import { AiChat } from "../../styles/IA/chatBot";
import aclsLogo from "../../assets/logos/novaLogoAcls.png";
import { apiConnect } from "../../services/api";

// Função para verificar se a linguagem é suportada
const isLanguageSupported = (language) => {
  return !!Prism.languages[language];
};

const CustomLinkRenderer = ({ href, children }) => {
  // Aqui você pode definir um nome amigável para o link
  const linkText = "link";

  let emailDisplay = "";
  if (href.startsWith("mailto:")) {
    // Extrai o email do href
    emailDisplay = href.replace("mailto:", "");
  }
  return (
    <a href={href} target="_blank" rel="noopener noreferrer">
      {emailDisplay && `${emailDisplay} `}
      {linkText} {/* Nome amigável para o link */}
    </a>
  );
};

export const ChatAiGM = () => {
  const [messages, setMessages] = useState([]);
  const [userInput, setUserInput] = useState("");
  const [currentText, setCurrentText] = useState("");
  const [isTyping, setIsTyping] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const lastElementRef = useRef(null);
  const containerRef = useRef(null);

  const handleMessagesChat = async () => {
    setMessages([]);
    setLoading(true);
    try {
      const token = sessionStorage.getItem("connectionsToken");

      const headerApi = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        timeout: 35000,
      };

      const response = await apiConnect.get(
        "chat/chatbot/ListHistory",
        headerApi
      );

      if (response.data === "Não existe conversa") {
        throw new Error("Não existe conversa");
      }
      // Formatar o response.data
      const formattedMessages = response.data.map((message) => {
        const [sender, text] = message.split(": ", 2); // Divide a string em duas partes

        return {
          sentBy: sender.trim() === "Usuário" ? "user" : "bot", // Define o remetente
          text: text.trim(), // Remove espaços em branco
        };
      });

      setMessages(formattedMessages);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleMessages();
    }
  };

  const handleInput = (event) => {
    const textarea = event.target;
    textarea.style.height = "auto";
    textarea.style.height = `${Math.min(textarea.scrollHeight, 4 * 24)}px`;
  };

  const handleMessages = async () => {
    if (!userInput.trim()) return;

    setLoading(true);

    setMessages([...messages, { text: userInput, sentBy: "user" }]);
    setUserInput("");

    const token = sessionStorage.getItem("connectionsToken");

    const headerApi = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      timeout: 35000,
    };

    try {
      const response = await apiConnect.post(
        "chat/chatbot",
        { message: userInput },
        headerApi
      );

      const botResponse = response.data.response;

      // Verificar se a resposta contém blocos de código
      if (botResponse.includes("```")) {
        // Verificar se a linguagem é suportada
        const isSupportedLanguage = (codeBlock) => {
          const languageMatch = codeBlock.match(/```(\w+)/);
          if (languageMatch) {
            const language = languageMatch[1];
            return Prism.languages[language] !== undefined; // Verifica se a linguagem está disponível no Prism
          }
          return false;
        };

        if (isSupportedLanguage(botResponse)) {
          // Se a linguagem é suportada, renderizar o código formatado
          setMessages((prevMessages) => [
            ...prevMessages,
            { text: botResponse, sentBy: "bot" },
          ]);
        } else {
          // Se a linguagem não é suportada, renderizar sem formatação
          setMessages((prevMessages) => [
            ...prevMessages,
            { text: botResponse, sentBy: "bot" },
          ]);
        }
      } else {
        // Inicia a animação de digitação
        setIsTyping(true);
        setCurrentText(""); // Reseta o texto atual

        setMessages((prevMessages) => [
          ...prevMessages,
          { text: "", sentBy: "bot" },
        ]);

        let indexMensagem = 0;

        setCurrentText(botResponse[0]);

        const timer = setInterval(() => {
          if (indexMensagem < botResponse.length - 2) {
            setCurrentText((prev) => {
              console.log(prev, botResponse[indexMensagem], indexMensagem);
              return prev + botResponse[indexMensagem];
            });
            indexMensagem++;
          } else {
            clearInterval(timer);
            setIsTyping(false); // Animação concluída
          }
        }, 0.5); // Velocidade da animação (ms por letra)
      }
    } catch (error) {
      toast.error("Erro ao enviar mensagem. Tente novamente mais tarde!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleMessagesChat();
  }, []);

  useEffect(() => {
    // console.log(currentText);
    if (!isTyping && currentText !== "") {
      setMessages((prevMessages) => {
        const updatedMessages = [...prevMessages];
        const lastIndex = updatedMessages.length - 1;
        if (lastIndex >= 0 && updatedMessages[lastIndex].sentBy === "bot") {
          updatedMessages[lastIndex].text = currentText;
        }
        return updatedMessages;
      });
    }
  }, [isTyping, currentText]);

  useEffect(() => {
    const handleScroll = () => {
      const container = containerRef.current;

      if (container) {
        const isAtBottom =
          container.scrollHeight - container.scrollTop ===
          container.clientHeight;
        setShowButton(!isAtBottom);
      }
    };

    const container = containerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }

    // Limpeza do event listener
    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  const scrollToBottom = () => {
    lastElementRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages, currentText]);

  return (
    <AiChat>
      <section className="chatMessage">
        {messages.length === 0 ? (
          <div className="InicialPage">
            <h2>Olá</h2>
            <h3>Em que posso ser útil?</h3>
          </div>
        ) : (
          <ul className="MensagensIa" ref={containerRef}>
            {messages.map((menssageIa, idx) => {
              const languageMatch = menssageIa.text.match(/```(\w+)/);
              const language = languageMatch ? languageMatch[1] : null;
              const rehypePlugins =
                language && isLanguageSupported(language)
                  ? [rehypePrismPlus]
                  : [];

              return (
                <li
                  key={idx}
                  className={menssageIa.sentBy === "bot" ? "bot" : "user"}
                >
                  <div className="cointeiner">
                    <div className="botHeader">
                      {menssageIa.sentBy === "bot" && (
                        <img src={aclsLogo} alt="Bot Logo" />
                      )}
                      {menssageIa.sentBy === "bot" && <span>Acls</span>}
                    </div>
                    <ReactMarkdown
                      className="conteudo"
                      children={
                        menssageIa.text ||
                        (isTyping && idx === messages.length - 1
                          ? currentText
                          : "")
                      }
                      remarkPlugins={[remarkGfm]}
                      rehypePlugins={rehypePlugins}
                      components={{
                        a: CustomLinkRenderer, // Aqui estamos sobrescrevendo a renderização de links
                      }}
                    />
                  </div>
                </li>
              );
            })}
            {loading && (
              <li className="bot headerLoading">
                <div className="cointeiner">
                  <div className="botHeader">
                    <img src={aclsLogo} alt="Bot Logo" />
                    <span>Acls</span>
                    <span className="loading"></span>
                  </div>
                </div>
              </li>
            )}
            <div ref={lastElementRef} />
          </ul>
        )}

        {showButton && (
          <button className="DownButton" onClick={scrollToBottom}>
            <FaArrowCircleDown className="icon" />
          </button>
        )}
      </section>
      <section className="InpultMenssage">
        <div className="styledInpult">
          <textarea
            rows={1}
            className="sendMessage"
            onChange={(e) => setUserInput(e.target.value)}
            value={userInput}
            onKeyDown={handleKeyDown}
            onInput={handleInput}
          />
          {isTyping ? (
            <FaSquare className="icon" />
          ) : (
            <BiSolidSend
              className="icon"
              onClick={() => {
                if (userInput.trim() !== "") {
                  handleMessages();
                }
              }}
            />
          )}{" "}
          {/* Troca o ícone com base no estado */}
        </div>
      </section>
    </AiChat>
  );
};
