import styled from "styled-components";

export const PrevisaoStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  max-height: 100%;
  max-width: 100%;
  color: var(--bgBranco);
  padding: 18px;
  max-width: 100%;
  .tituloPrevisao {
    font-size: 15px;
  }
  .diasSemana {
    display: flex;
    justify-content: space-between;
    text-align: center;
    align-items: center;
    list-style-type: none;
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    gap: 3%;
    .dia {
      display: flex;
      cursor: pointer;
      width: 100%;
      height: 85%;
      max-width: 100%;
      padding: 15px 0;
      border-radius: 2em;
      flex-direction: column;
      text-align: center;
      align-items: center;
      justify-content: space-between;
      gap: 15px;
      background-color: var(--background);
      border: 1px solid rgba(248, 248, 248, 0.2);
      transition: 0.3s;
      position: relative;
      padding-top: 28px;
      margin-top: 10px;
      img {
        position: absolute;
        width: 40%;
        top: -15px;
        right: -10px;
      }
      .diaSemana {
        position: absolute;
        max-width: 100%;
        top: -23px;
        font-weight: 600;
        text-decoration: 2px underline solid rgba(255, 255, 255, 1);
      }
      .tempPre {
        font-size: 1vw;
        font-weight: 600;
      }
      .Status {
        font-weight: 600;
        font-size: 0.9vw;
        text-transform: capitalize;
        max-width: 100%;
        text-align: center;
      }
    }
    .dia:hover {
      border: 1px solid var(--bgBranco);
    }
  }

  .previsaoDoDia {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    /* justify-content: center; */
    width: 100%;
    height: 55%;
    border-radius: 15px;
    background-color: var(--background);
    gap: 15px;
    padding: 10px;
    border: 1px solid rgba(248, 248, 248, 0.1);
    max-width: 100%;
    overflow: auto;
    list-style: none;
    scrollbar-width: thin; /* Define a largura da barra de rolagem como fina */
    scrollbar-color: rgba(161, 161, 161, 0.9) transparent;
    .nowPrev {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      width: 15%;
      /* justify-content: center; */
      /* background-color: aqua; */
      height: 100%;
      padding-bottom: 5px;
      .icon {
        font-size: 1.8vw;

        img {
          width: 85%;
        }
      }
      span {
        font-weight: 600;
      }
      border-bottom: 3px solid white;
    }

    .diaPrevs {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      height: 100%;
      padding-bottom: 5px;
      border-bottom: 3px solid transparent;
      span {
        font-weight: 600;
      }
      .icon {
        font-size: 1.8vw;

        img {
          width: 85%;
        }
      }
    }
    .hoje {
      span {
        font-size: 30px;
      }
      p {
        font-size: 10px;
      }
    }
    .botaoPrevisao {
      button {
        width: 200px;
        height: 40px;
        background-color: var(--bgBotao);
        color: var(--bgBranco);
        border-radius: 15px;
        cursor: pointer;
        border: none;
      }
      button:hover {
        border: 1px solid var(--bgBranco);
      }
    }

    .statusDia {
      display: flex;
      flex-direction: column;

      align-items: end;
      gap: 15px;
      img {
        width: 50px;
      }
    }
  }

  /* Personaliza a barra de rolagem para WebKit (Chrome, Safari, etc.) */
  .previsaoDoDia::-webkit-scrollbar {
    height: 8px; /* Altura da barra de rolagem no eixo X */
  }

  .previsaoDoDia::-webkit-scrollbar-track {
    background: transparent; /* Cor do fundo da faixa da barra de rolagem */
  }

  .previsaoDoDia::-webkit-scrollbar-thumb {
    background: rgba(
      161,
      161,
      161,
      0.9
    ); /* Cor e opacidade da barra de rolagem */
    border-radius: 4px; /* Arredondamento das bordas da barra de rolagem */
  }
`;

export const ModalPrevisaoClima = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  background-color: rgba(18, 18, 20, 0.6);
  backdrop-filter: blur(4px);
  z-index: 9;
  .conteiner {
    position: relative;
    display: flex;
    flex-direction: column;
    -webkit-animation: slide-in-fwd-center 0.4s
      cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: slide-in-fwd-center 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94)
      both;
    .closeModal {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      right: -5px;
      top: -5px;
      font-size: 30px;
      color: white;
      width: 30px;
      height: 30px;
      align-items: center;
      background-color: rgba(131, 131, 131, 0.66);
      border-radius: 50%;
      border: 2px solid rgba(112, 112, 112, 0.8);
      cursor: pointer;
    }
    .graficoPrevisao {
      width: 99%;
      height: 90%;
    }
    h2 {
      color: white;
      width: 100%;
      text-align: center;
      padding: 15px;
    }
    width: 90%;
    height: 90vh;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.9);
    border-radius: 10px;
  }
`;
