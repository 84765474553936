import { MapConfigPivos } from "../../styles/Pivo/map";
import imgControllerLeft from "../../assets/icons/elemento_left.png";
import imgControllerRight from "../../assets/icons/elemento_rigth2.png";
import buttonPowerOn from "../../assets/icons/ativado.png";
import buttonPowerOff from "../../assets/icons/desligado.png";
import imgRpm from "../../assets/icons/velocidade2.png";
import imgRpmOff from "../../assets/icons/velocidade2.png";
import imgAlertL from "../../assets/icons/alerta_bomba.png";
import imgAlertLOff from "../../assets/icons/alerta_bomba2.png";
import imgAlertR from "../../assets/icons/alerta.png";
import imgAlertROff from "../../assets/icons/alerta2.png";
import imgBombOn from "../../assets/icons/bomba_ativada2.png";
import imgBombOff from "../../assets/icons/bomba_dessativada1.png";
import imgPivoControlerOn from "../../assets/icons/pivo_ligado1.png";
import imgPivoControlerOff from "../../assets/icons/pivo_desligado1.png";
import imgPivoControler from "../../assets/icons/pivo_desligado1.png";
import imgVazao from "../../assets/icons/vazao3.png";
import imgWaterOn from "../../assets/icons/vazao.png";
import imgWaterOff from "../../assets/icons/vazao_ativado.png";
import imgControllerDirection from "../../assets/icons/Pinel_Controle4.png";
import imgControllerDirectioL from "../../assets/icons/controllerL.png";
import imgControllerDirectioD from "../../assets/icons/controllerR.png";
import imgPowerOff from "../../assets/icons/desligado2.png";
import imaBackgroundArrowLeft from "../../assets/icons/controler-direcao-fundoL.png";
import imaBackgroundArrowRight from "../../assets/icons/controler-direcao-fundoD.png";
import { useCallback, useContext, useState } from "react";
import { PivoContext } from "../../contexts/contexPivos";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { apiConnect } from "../../services/api";
import { useEffect } from "react";
import { IoMdCloseCircle } from "react-icons/io";
import { PiShieldWarningDuotone } from "react-icons/pi";
import { IoAlertCircleSharp } from "react-icons/io5";

export const MapController = () => {
  // Destruturação de objetos do contexto
  const {
    menuPivoSelect,
    listPivosControlles,
    setOpenModalConfimPivo,
    setInfContolersPivoFuntion,
    openModalConfimPivo,
    listBombsData,
    listPivosData,
    listMotoBombaData,
    headerApi,
    pivosList,
    moveMapOn,
    cameraState,
    locateMap,
    convert4a20,
    setOpenModalConfimPivoComando,
    setStatusPvButton,
  } = useContext(PivoContext);

  const selectedPivoId = menuPivoSelect[0].id;

  // Filtrando dados específicos do pivo selecionado
  const listControler = listPivosControlles.find(
    (e) => e.pivo === selectedPivoId
  );

  const listBombController = listBombsData.find(
    (e) => e.pivo === selectedPivoId
  );

  const listDadosPivo = listPivosData.find((e) => e.pivo === selectedPivoId);

  const motoBombaDado = listMotoBombaData.find(
    (e) => e.pivo === selectedPivoId
  );

  const pivoSelect = pivosList.find((e) => e.id === selectedPivoId);

  // Função para mudança de rotação do pivo
  const rotationPivo = (validation) => {
    if (
      (validation && listDadosPivo.sentido_de_rotacao) ||
      (!validation && !listDadosPivo.sentido_de_rotacao)
    ) {
      return;
    }

    if (listDadosPivo.liberacao === false) {
      return;
    }

    setOpenModalConfimPivo(!openModalConfimPivo);
    setInfContolersPivoFuntion([selectedPivoId, "sentido_de_rotacao"]);
  };

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
  );

  //Grafico de historico de cada Pivo

  const [saveHistoricPivo, setSaveHistoricPivo] = useState([]);

  const handleListHitoricPivo = useCallback(async () => {
    try {
      const response = await apiConnect.get(
        `pivo/historico/${selectedPivoId}/`,
        headerApi
      );
      setSaveHistoricPivo(response.data);
    } catch (error) {
      // Trate o erro, se necessário
    }
  }, [selectedPivoId, headerApi]); // Adicione as dependências necessárias

  useEffect(() => {
    handleListHitoricPivo();
  }, [handleListHitoricPivo]); // Agora, a função está no array de dependências

  const newHistoricDataPivo = saveHistoricPivo
    .filter((data) => data.pivo_id === selectedPivoId)
    .slice(-10);

  const dataPCentro = {
    labels: newHistoricDataPivo.map((data) => data.horario),
    datasets: [
      {
        label: "Pressão Centro do Pivô",
        data: newHistoricDataPivo.map((data) =>
          convert4a20(data.pressao_centro)
        ),
        fill: true,
        backgroundColor: "#a4a4a4a1",
        borderColor: "#6a796f",
        pointBackgroundColor: "#000000",
        color: "#ffffff",
        showLine: true,
      },
    ],
  };

  const dataPPonta = {
    labels: newHistoricDataPivo.map((data) => data.horario),
    datasets: [
      {
        label: "Pressão Ponta do Pivô",
        data: newHistoricDataPivo.map((data) =>
          convert4a20(data.pressao_ponta)
        ),
        fill: true,
        backgroundColor: "#a4a4a4a1",
        borderColor: "#6a796f",
        pointBackgroundColor: "#000000",
        color: "#ffffff",
        showLine: true,
      },
    ],
  };

  const options = {
    scales: {
      x: {
        ticks: {
          color: "#e7e0e0", // Cor do texto no eixo x
        },
      },
      y: {
        suggestedMin: 0,
        beginAtZero: true,
        ticks: {
          color: "#e7e0e0", // Cor do texto no eixo y
        },
      },
    },
    plugins: {
      legend: {
        display: false,
        labels: {
          color: "#e7e0e0", // Cor do texto do rótulo do conjunto de dados
          font: {
            size: 18, // Tamanho da fonte do texto do eixo y
          },
        },
      },
      datalabels: {
        display: false,

        formatter: (value) => `${value}%`,
        font: {
          size: 12,
          weight: "bold",
        },
        color: "#ffffff",
      },
    },
  };

  return (
    <MapConfigPivos>
      {listControler.pivo_seco ||
        (listControler.pivo_irrigando && <div className="loadingPower"></div>)}
      {moveMapOn && (
        <div
          className="closeConfig"
          onClick={() => {
            locateMap(
              cameraState.center.lat,
              cameraState.center.lng,
              0,
              14,
              "all"
            );
            setTimeout(() => {
              locateMap(
                cameraState.center.lat,
                cameraState.center.lng,
                0,
                14,
                "all"
              );
            }, 1000);
          }}
        >
          <IoMdCloseCircle />
        </div>
      )}
      <div className="pivoController">
        <div className="statusPivo">
          <div>
            <p>Pressão Centro do Pivô</p>
            <span>{convert4a20(listDadosPivo.pressao_centro)} Bar</span>
            <Line data={dataPCentro} options={options} />
          </div>
          <div>
            <p>Pressão Ponta do Pivô</p>
            <span>{convert4a20(listDadosPivo.pressao_ponta)} Bar</span>
            <Line data={dataPPonta} options={options} />
          </div>
        </div>
        {menuPivoSelect[0].status && !listDadosPivo.seguranca ? (
          <div className="contreolles">
            <div className="left">
              <img src={imgControllerLeft} alt="" />
              {pivoSelect.tipo_bomba ? (
                <div className="button1">
                  {listBombController.status_bomba1 ? (
                    <>
                      <img src={imgBombOn} alt="" />
                      <div className="status">
                        <span>ON</span>
                        <h4>Bomba</h4>
                      </div>
                    </>
                  ) : (
                    <>
                      <img src={imgBombOff} alt="" />
                      <div className="status">
                        <span>OFF</span>
                        <h4>Bomba</h4>
                      </div>
                    </>
                  )}
                </div>
              ) : (
                <div className="button1">
                  {motoBombaDado.status_bomba ? (
                    <>
                      <img src={imgBombOn} alt="" />
                      <div className="status">
                        <span>ON</span>
                        <h4>Bomba</h4>
                      </div>
                    </>
                  ) : (
                    <>
                      <img src={imgBombOff} alt="" />
                      <div className="status">
                        <span>OFF</span>
                        <h4>Bomba</h4>
                      </div>
                    </>
                  )}
                </div>
              )}

              {selectedPivoId.tipo_bomba ? (
                <div className="button2">
                  <img src={imgRpm} alt="" />
                  <div className="status">
                    <span>{listDadosPivo.rpm}</span>
                    <h4>RPM</h4>
                  </div>
                </div>
              ) : (
                <div className="button2">
                  <img src={imgRpm} alt="" />
                  <div className="status">
                    <span>{motoBombaDado.rpm}</span>
                    <h4>RPM</h4>
                  </div>
                </div>
              )}
              <div className="button3">
                <img src={imgAlertL} alt="" />
                <div className="status">
                  <span>0</span>
                  <h4>Alertas</h4>
                </div>
              </div>
            </div>
            <div className="directionRotation">
              <div className="controllerBackgroundL">
                <img src={imgControllerDirectioL} alt="" />
              </div>
              <div className="controllerBackgroundD">
                <img src={imgControllerDirectioD} alt="" />
              </div>
              <div className="circle">
                <img src={imgControllerDirection} alt="" />
              </div>
              <div className="arrowL">
                <IoIosArrowBack />
                <img
                  src={imaBackgroundArrowLeft}
                  className={`background ${
                    listDadosPivo.sentido_de_rotacao ? "" : "opacity50"
                  }`}
                  onClick={() => rotationPivo(false)}
                  alt=""
                />
              </div>
              <div className="arrowD">
                <IoIosArrowForward />
                <img
                  src={imaBackgroundArrowRight}
                  className={`background ${
                    listDadosPivo.sentido_de_rotacao ? "opacity50" : ""
                  }`}
                  alt=""
                  disabled={true}
                  onClick={() => rotationPivo(true)}
                />
              </div>
            </div>
            {listDadosPivo.liberacao ? (
              <div
                className="center pointer"
                onClick={() => {
                  setOpenModalConfimPivoComando(true);
                  setStatusPvButton(listDadosPivo.status_pivo);
                  setInfContolersPivoFuntion([listControler.pivo, "pivo_seco"]);
                }}
              >
                {listDadosPivo.status_pivo ? (
                  <img src={buttonPowerOn} alt="" />
                ) : (
                  <img src={buttonPowerOff} alt="" />
                )}
              </div>
            ) : (
              <div className="not-allowed center">
                <img src={imgPowerOff} alt="" />
              </div>
            )}
            <div className="right">
              <img src={imgControllerRight} alt="" />
              <div className="button1">
                {listDadosPivo.status_pivo ? (
                  <>
                    <img src={imgPivoControlerOn} alt="" />
                    <div className="status">
                      <span>ON</span>
                      <h4>Pivô</h4>
                    </div>
                  </>
                ) : (
                  <>
                    <img src={imgPivoControlerOff} alt="" />
                    <div className="status">
                      <span>OFF</span>
                      <h4>Pivô</h4>
                    </div>
                  </>
                )}
              </div>
              <div className="button2">
                {listDadosPivo.status_agua ? (
                  <>
                    <img src={imgWaterOn} alt="" />
                    <div className="status">
                      <span>ON</span>
                      <h4>Água</h4>
                    </div>
                  </>
                ) : (
                  <>
                    <img src={imgWaterOff} alt="" />
                    <div className="status">
                      <span>OFF</span>
                      <h4>Água</h4>
                    </div>
                  </>
                )}
              </div>
              <div className="button3">
                <img src={imgAlertR} alt="" />
                <div className="status">
                  <span>0</span>
                  <h4>Alertas</h4>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="contreolles">
            {listDadosPivo.seguranca.seguranca && (
              <div className="securityOn">
                <PiShieldWarningDuotone className="iconSecurity" />
              </div>
            )}
            <div className="left">
              <img src={imgControllerLeft} alt="" />
              <div className="button1">
                <img src={imgBombOn} alt="" />
              </div>
              <div className="button2">
                <img src={imgRpmOff} alt="" />
              </div>
              <div className="button3">
                <img src={imgAlertLOff} alt="" />
              </div>
            </div>
            <div className="directionRotation">
              <div className="controllerBackgroundL">
                <img src={imgControllerDirectioL} alt="" />
              </div>
              <div className="controllerBackgroundD">
                <img src={imgControllerDirectioD} alt="" />
              </div>
              <div className="circle">
                <img src={imgControllerDirection} alt="" />
              </div>
              <div className="arrowL">
                <IoIosArrowBack />
              </div>
              <div className="arrowD">
                <IoIosArrowForward />
              </div>
            </div>
            <div className="not-allowed center">
              {listDadosPivo.seguranca ? (
                <IoAlertCircleSharp className="iconSecurity" />
              ) : (
                <img src={imgPowerOff} alt="" />
              )}
            </div>
            <div className="right">
              <img src={imgControllerRight} alt="" />
              <div className="button1">
                <img src={imgPivoControler} alt="" />
              </div>
              <div className="button2">
                <img src={imgVazao} alt="" />
              </div>
              <div className="button3">
                <img src={imgAlertROff} alt="" />
              </div>
            </div>
          </div>
        )}
        <span className="linePower" />
      </div>
    </MapConfigPivos>
  );
};
