import React, { createContext, useState, useCallback } from "react";
import { apiConnect } from "../services/api"; // Importe a instância configurada do Axios
import { Outlet } from "react-router-dom";

import ensolarado from "../assets/estacao-meteorologica/SolP.svg";
import solCnuvem from "../assets/estacao-meteorologica/solCnuvem.svg";
import nublado from "../assets/estacao-meteorologica/nublado.svg";
import chuva from "../assets/estacao-meteorologica/chuva.svg";
import chuvaEraiosP from "../assets/estacao-meteorologica/chuvaEraiosP.svg";
import neve from "../assets/estacao-meteorologica/neve.svg";
import raios from "../assets/estacao-meteorologica/raios.svg";
import lua from "../assets/estacao-meteorologica/noite.svg";
import luaCnuvem from "../assets/estacao-meteorologica/noiteNublado.svg";
import { format } from "date-fns";

export const ClimaContext = createContext();

export const ClimaProvider = () => {
  const [climaIdSelect, setClimaIdSelect] = useState(null);
  const [listHistorico, setListHistorico] = useState([]);
  const [climaAtual, setClimaAtual] = useState(null);
  const [dateDay, setDateDay] = useState(new Date().getDate());
  const [calenderDate, setCalenderDate] = useState(new Date());
  const [modalCalender, setModalCalender] = useState(false);
  const [modalPrevisao, setModalPrevisao] = useState(false);
  const [listPrevisaoClima, setPrevisaoClima] = useState([]);
  const [diaPrevisto, setDiaPrevisto] = useState("");

  const listHistoricoClima = useCallback(async (idClimaAtual) => {
    if (!idClimaAtual) return; // Evita chamar se idClimaAtual for null

    const dataAtual = new Date();
    const dataFormatada = format(dataAtual, "dd-MM-yyyy");

    const headerApi = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("connectionsToken")}`,
      },
    };

    try {
      const response = await apiConnect.get(
        `meteorologia/historico/${idClimaAtual}/${dataFormatada}`,
        headerApi
      );
      setListHistorico(response.data);
    } catch (error) {
      console.log(error);
    }
  }, []); // Dependências vazias

  const imageMap = {
    "céu limpo": { dia: ensolarado, noite: lua },
    "poucas nuvens": { dia: solCnuvem, noite: luaCnuvem },
    "nuvens dispersas": { dia: solCnuvem, noite: luaCnuvem },
    "algumas nuvens": { dia: solCnuvem, noite: luaCnuvem },
    nublado: { dia: nublado, noite: nublado },
    "nuvens quebradas": { dia: nublado, noite: nublado },
    "nuvens encobertas": { dia: nublado, noite: nublado },
    "chuva leve": { dia: chuva, noite: chuva },
    "chuva moderada": { dia: chuva, noite: chuva },
    chuva: { dia: chuva, noite: chuva },
    tempestade: { dia: raios, noite: raios },
    neve: { dia: neve, noite: neve },
    névoa: { dia: solCnuvem, noite: luaCnuvem },
    "chuva com raios": { dia: chuvaEraiosP, noite: chuvaEraiosP },
    "chuva forte": { dia: chuvaEraiosP, noite: chuvaEraiosP },
  };

  const selecionarImagemClima = (descricao, horario) => {
    let isDia;
    
    if (!horario) {
      isDia = true; // Define como dia se não houver horário
    } else {
      const hora = new Date(horario).getHours(); // Converte a string de horário para objeto Date
      isDia = hora >= 6 && hora < 18; // Define o intervalo do dia
    }

    const clima = imageMap[descricao.toLowerCase()];
    if (!clima) return null; // Verifica se a descrição existe

    return isDia ? clima.dia : clima.noite; // Retorna a imagem correspondente
  };

  return (
    <ClimaContext.Provider
      value={{
        selecionarImagemClima,
        climaIdSelect,
        setClimaIdSelect,
        listHistoricoClima,
        listHistorico,
        setListHistorico,
        climaAtual,
        setClimaAtual,
        modalCalender,
        setModalCalender,
        calenderDate,
        setCalenderDate,
        dateDay,
        setDateDay,
        modalPrevisao,
        setModalPrevisao,
        listPrevisaoClima,
        setPrevisaoClima,
        diaPrevisto,
        setDiaPrevisto,
      }}
    >
      <Outlet />
    </ClimaContext.Provider>
  );
};
