import { useEffect, useState } from "react";
import { AdminMeteologiaStyled } from "../../styles/admin";
import { apiConnect } from "../../services/api";
import { IoAddCircle } from "react-icons/io5";
import { FaCloudSun } from "react-icons/fa";

export const AdminMeteologia = () => {
  const [ListMetologia, seListMeteologia] = useState(null);
  const [editedMeteologia, SetEditedMeteologia] = useState({});
  const [Unidades, setUnidades] = useState([]);

  const ListUnidades = async () => {
    try {
      const token = sessionStorage.getItem("connectionsToken");
      const idUnidade = sessionStorage.getItem("connectionsUnitId");

      const headerApi = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await apiConnect.get(`unidade/leituratodos`, headerApi);
      setUnidades(response.data);
    } catch (error) {
    } finally {
    }
  };

  const ListarMeteologia = async () => {
    const headerApi = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("connectionsToken")}`,
      },
    };
    try {
      const response = await apiConnect.get(
        `meteorologia/localidade/LeituraTodos`,
        headerApi
      );
      seListMeteologia(response.data);
    } catch (error) {}
  };

  useEffect(() => {
    ListarMeteologia();
    ListUnidades();
  }, []);

  const handleInputChange = (e, climaMt, type) => {
    SetEditedMeteologia((prevEditedTanque) => ({
      ...prevEditedTanque,
      [climaMt]: {
        ...prevEditedTanque[climaMt],
        [type]: e,
      },
    }));
  };

  useEffect(() => {
    const initialEditedMeteologia = {};
    if (ListMetologia != null) {
      ListMetologia.forEach((climaMt) => {
        initialEditedMeteologia[climaMt.id] = { ...climaMt };
      });
      SetEditedMeteologia(initialEditedMeteologia);
    }
  }, [ListMetologia]);

  return (
    <AdminMeteologiaStyled>
      <div className="Header">
        <h2>Meteologia</h2>
        <div className="addIcon">
          <div
            className="icons"
            // onClick={() => setModalCreatingPivo(!modalCreatingPivo)}
          >
            <FaCloudSun className="pivoIcon" />
            <IoAddCircle className="ADDIcon" />
          </div>
          <span>Adiconar Nova Estação</span>
        </div>
      </div>
      <ul>
        {ListMetologia &&
          ListMetologia.map((climaMet) => {
            const cancelChanges = () => {
              SetEditedMeteologia((prevEditedTanques) => ({
                ...prevEditedTanques,
                [climaMet.id]: climaMet,
              }));
            };
            const checkChangesTanque =
              JSON.stringify(climaMet) ===
              JSON.stringify(editedMeteologia[climaMet.id]);

            const nameUnidade = Unidades.find(
              (e) => e.id === climaMet.unidade.id
            );
            return (
              <li onClick={() => console.log(editedMeteologia)}>
                <h2>{climaMet.nome}</h2>
                <div className="infosClima">
                  <label htmlFor={`${climaMet.id}cidade`}>Cidade</label>
                  <input
                    type="string"
                    id={`${climaMet.id}cidade`}
                    value={editedMeteologia[climaMet.id]?.cidade}
                    onChange={(e) =>
                      handleInputChange(e.target.value, climaMet.id, "cidade")
                    }
                  />
                </div>
                <div className="infosClima">
                  <label htmlFor={`${climaMet.id}uf`}>Uf</label>
                  <input
                    type="string"
                    id={`${climaMet.id}uf`}
                    value={editedMeteologia[climaMet.id]?.uf}
                    onChange={(e) =>
                      handleInputChange(e.target.value, climaMet.id, "uf")
                    }
                  />
                </div>
                <div className="infosClima">
                  <label htmlFor={`${climaMet.id}latitude`}>Latitude</label>
                  <input
                    type="number"
                    id={`${climaMet.id}latitude`}
                    value={editedMeteologia[climaMet.id]?.latitude}
                    onChange={(e) =>
                      handleInputChange(e.target.value, climaMet.id, "latitude")
                    }
                  />
                </div>
                <div className="infosClima">
                  <label htmlFor={`${climaMet.id}longitude`}>Longitude</label>
                  <input
                    type="number"
                    id={`${climaMet.id}longitude`}
                    value={editedMeteologia[climaMet.id]?.longitude}
                    onChange={(e) =>
                      handleInputChange(
                        e.target.value,
                        climaMet.id,
                        "longitude"
                      )
                    }
                  />
                </div>
                <div className="infosClima">
                  <label htmlFor={`${climaMet.id}unidade`}>Unidade</label>
                  <input
                    type="text"
                    id={`${climaMet.id}unidade`}
                    disabled={true}
                    value={nameUnidade ? nameUnidade.name : ""}
                  />
                </div>

                <div className="buttons">
                  <button
                    className={checkChangesTanque ? "off" : ""}
                    disabled={checkChangesTanque}
                    // onClick={() => {
                    //   setModalConfimation(!modalConfimation);
                    //   setSalveTanqueInfo([tanque.id, editedTanques[tanque.id]]);
                    // }}
                  >
                    Salvar
                  </button>
                  <button
                    disabled={checkChangesTanque}
                    onClick={() => cancelChanges()}
                  >
                    Cancelar
                  </button>
                </div>
              </li>
            );
          })}
      </ul>
    </AdminMeteologiaStyled>
  );
};
