import { useCallback, useEffect } from "react";
import { useContext, useState } from "react";
import { PivoContext } from "../../contexts/contexPivos";
import {
  HorarioPicoModal,
  ModalAlertasStyled,
  ModalConfirmController,
  ModalDefesorAcriculas,
} from "../../styles/modals";
import { IoCloseCircle, IoCloseSharp } from "react-icons/io5";
import { RiImageAddFill } from "react-icons/ri";
import { apiConnect } from "../../services/api";
import { SquareLoading } from "../../components/loaders";
import {
  IoIosArrowDown,
  IoIosArrowUp,
  IoIosArrowBack,
  IoIosArrowForward,
} from "react-icons/io";
import constrution from "../../assets/icons/contrution.gif";
import "jspdf-autotable";

import { IoMdCheckboxOutline } from "react-icons/io";
import { MdCheckBoxOutlineBlank } from "react-icons/md";
import { toast } from "react-toastify";
import { ModalActionPivoStyled } from "../../styles/Pivo/pivoControler";
import { format, parseISO } from "date-fns";
import { PiWarningDuotone } from "react-icons/pi";

export const MOdalConfirmBombController = () => {
  const {
    lisBombstControlles,
    handleUpdateControllerBomb,
    openModalConfimBomba,
    setOpenModalConfimBomba,
    rpms,
    setRpms,
    infContolersbombFuntion,
    onPump,
    handleUpdateControllerMotoBomb,
    pivosList,
    lisMotoBombstControlles,
    rpmsMotoBomba,
    setRpmsMotoBomba,
    onMotoBomba,
  } = useContext(PivoContext);

  const handleButtonsBomsController = async (pivoId, field) => {
    const bombsController = lisBombstControlles.find((e) => pivoId === e.pivo);
    const motoBombaController = lisMotoBombstControlles.find(
      (e) => pivoId === e.pivo
    );
    const pivoById = pivosList.find((e) => pivoId === e.id);

    if (!bombsController) {
      console.error("Controlador das bombas não encontrado.");
      return;
    }

    let updatedData;

    if (pivoById.tipo_bomba) {
      if (field === "rpm") {
        // Tratar o campo 'percetimetro' como número
        const newValue = parseFloat(rpms[pivoId]) || 0;
        // Limitar o valor entre 0 e 100
        updatedData = {
          [field]: Math.min(1700, Math.max(1000, newValue)),
        };
        setRpms({ ...rpms, [pivoId]: updatedData[field] });
      } else {
        updatedData = {
          [field]: bombsController[field] + 1,
        };
        onPump();
      }
      await handleUpdateControllerBomb(bombsController.id, updatedData);
    } else {
      if (field === "rpm") {
        // Tratar o campo 'percetimetro' como número
        const newValue = parseFloat(rpmsMotoBomba[pivoId]) || 0;
        // Limitar o valor entre 0 e 100
        updatedData = {
          [field]: Math.min(1700, Math.max(1000, newValue)),
        };
        setRpmsMotoBomba({ ...rpmsMotoBomba, [pivoId]: updatedData[field] });
      } else {
        updatedData = {
          [field]: motoBombaController[field] + 1,
        };
        onMotoBomba();
      }
      await handleUpdateControllerMotoBomb(motoBombaController.id, updatedData);
    }
  };

  useEffect(() => {
    // Adiciona a classe ao abrir o modal
    if (openModalConfimBomba) {
      document.body.classList.add("modal-open");
    }

    // Remove a classe ao fechar o modal
    return () => {
      document.body.classList.remove("modal-open");
    };
  }, [openModalConfimBomba]);

  return (
    <ModalConfirmController>
      <div className="deleteContact">
        <h2>{`Tem certeza de que deseja fazer essa ação`}</h2>
        <div>
          <button
            className="buttonYes"
            onClick={() => {
              handleButtonsBomsController(
                infContolersbombFuntion[0],
                infContolersbombFuntion[1]
              );
            }}
          >
            Sim
          </button>
          <button
            onClick={() => setOpenModalConfimBomba(!openModalConfimBomba)}
          >
            Não
          </button>
        </div>
      </div>
    </ModalConfirmController>
  );
};

export const MOdalConfirmPivoController = () => {
  const {
    listPivosControlles,
    handleUpdateControllerPivo,
    percetimetros,
    setPercetimetros,
    infContolersPivoFuntion,
    openModalConfimPivo,
    setOpenModalConfimPivo,
    listPivosData,
  } = useContext(PivoContext);

  const handleButtonsPivosController = async (pivoId, field) => {
    const pivoController = listPivosControlles.find((e) => pivoId === e.pivo);
    const pivoDados = listPivosData.find((e) => pivoId === e.pivo);

    if (!pivoController) {
      console.error("Controlador do Pivô não encontrado.");
      return;
    }

    let updatedData;

    switch (field) {
      case "percetimetro":
        // Tratar o campo 'percetimetro' como número
        const newValue = parseFloat(percetimetros[pivoId]) || 0;
        // Limitar o valor entre 0 e 100
        updatedData = {
          [field]: Math.min(100, Math.max(0, newValue)),
        };
        break;

      case "pivo_seco":
      case "agua":
      case "sentido_de_rotacao":
        updatedData = {
          [field]: !pivoController[field],
        };
        break;

      default:
        updatedData = {
          [field]: !pivoController[field],
        };
        break;
    }

    const token = sessionStorage.getItem("connectionsToken");
    const headerApi = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let mensagensActin = "Comando Pivo";

    if (field === "agua") {
      const agora = new Date();
      const horaAtual = agora.getHours();
      if (horaAtual >= 18 && horaAtual < 21 && !pivoDados.status_agua) {
        toast.warn("Horário de Pico.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        return;
      }
      mensagensActin = `Comando para ${
        pivoDados.status_agua ? "Desligar" : "Ligar"
      } água  do Pivô`;
    }
    if (field === "sentido_de_rotacao") {
      mensagensActin = `Comando para mudar o sentido para ${
        pivoDados.sentido_de_rotacao ? "Horário" : "Anti-horário"
      } do Pivô`;
    }
    if (field === "percetimetro") {
      mensagensActin = `Comando para Alterar o Percentimetro para  ${parseFloat(
        percetimetros[pivoId]
      )} `;
    }

    const dataActionPivo = {
      actionType: mensagensActin,
      pivo_id: pivoId,
    };

    setPercetimetros({ ...percetimetros, [pivoId]: updatedData[field] });
    await handleUpdateControllerPivo(pivoController.id, updatedData);

    try {
      await apiConnect.post(`ActionsPivo`, dataActionPivo, headerApi);
    } catch (error) {}
  };

  useEffect(() => {
    // Adiciona a classe ao abrir o modal
    if (openModalConfimPivo) {
      document.body.classList.add("modal-open");
    }

    // Remove a classe ao fechar o modal
    return () => {
      document.body.classList.remove("modal-open");
    };
  }, [openModalConfimPivo]);

  return (
    <ModalConfirmController>
      <div className="deleteContact">
        <h2>{`Tem certeza de que deseja fazer essa ação`}</h2>
        <div>
          <button
            className="buttonYes"
            onClick={() =>
              handleButtonsPivosController(
                infContolersPivoFuntion[0],
                infContolersPivoFuntion[1]
              )
            }
          >
            Sim
          </button>
          <button onClick={() => setOpenModalConfimPivo(!openModalConfimPivo)}>
            Não
          </button>
        </div>
      </div>
    </ModalConfirmController>
  );
};

export const ModalConfirmationPivoController = ({ ststus }) => {
  const {
    listPivosControlles,
    handleUpdateControllerPivo,
    percetimetros,
    setPercetimetros,
    infContolersPivoFuntion,
    openModalConfimPivoComando,
    setOpenModalConfimPivoComando,
    listPivosData,
  } = useContext(PivoContext);

  const handleButtonsPivosController = async (pivoId, field) => {
    const pivoController = listPivosControlles.find((e) => pivoId === e.pivo);
    const pivoDados = listPivosData.find((e) => pivoId === e.pivo);

    if (pivoController.agua) {
      toast.warn("Falha Comando sendo executado", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      return;
    }

    if (!pivoController) {
      console.error("Controlador do Pivô não encontrado.");
      return;
    }

    let updatedData;

    switch (field) {
      case "percetimetro":
        // Tratar o campo 'percetimetro' como número
        const newValue = parseFloat(percetimetros[pivoId]) || 0;
        // Limitar o valor entre 0 e 100
        updatedData = {
          [field]: Math.min(100, Math.max(0, newValue)),
        };
        break;

      case "pivo_seco":
      case "pivo_irrigando":
      case "agua":
      case "sentido_de_rotacao":
        updatedData = {
          [field]: !pivoController[field],
        };
        break;

      default:
        updatedData = {
          [field]: !pivoController[field],
        };
        break;
    }

    const token = sessionStorage.getItem("connectionsToken");
    const headerApi = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let mensagensActin = "Comando Pivo";

    if (field === "pivo_irrigando") {
      mensagensActin = `Comando para ${
        pivoDados.status_pivo ? "Desligar" : "Ligar"
      } Pivô`;
    }
    if (field === "pivo_seco") {
      mensagensActin = `Comando para ${
        pivoDados.status_pivo ? "Desligar" : "Ligar"
      } Pivô a seco`;
    }
    if (field === "agua") {
      mensagensActin = `Comando para ${
        pivoDados.status_agua ? "Desligar" : "Ligar"
      } água  do Pivô`;
    }

    setPercetimetros({ ...percetimetros, [pivoId]: updatedData[field] });
    await handleUpdateControllerPivo(pivoController.id, updatedData);
    setOpenModalConfimPivoComando(false);

    const dataActionPivo = {
      actionType: mensagensActin,
      pivo_id: pivoId,
    };

    try {
      await apiConnect.post(`ActionsPivo`, dataActionPivo, headerApi);
    } catch (error) {}
  };

  useEffect(() => {
    // Adiciona a classe ao abrir o modal
    if (openModalConfimPivoComando) {
      document.body.classList.add("modal-open");
    }

    // Remove a classe ao fechar o modal
    return () => {
      document.body.classList.remove("modal-open");
    };
  }, [openModalConfimPivoComando]);

  return (
    <ModalConfirmController>
      <div className="deleteContact">
        <h2>{`Tem certeza de que deseja ${
          ststus ? "Desligar" : "Ligar"
        } o Pivô`}</h2>
        <div>
          <button
            className="buttonYes"
            onClick={() => {
              if (!ststus) {
                handleButtonsPivosController(
                  infContolersPivoFuntion[0],
                  "pivo_seco"
                );
              }
              if (ststus) {
                const pivoDados = listPivosData.find(
                  (e) => infContolersPivoFuntion[0] === e.pivo
                );
                if (!pivoDados.status_agua) {
                  handleButtonsPivosController(
                    infContolersPivoFuntion[0],
                    "pivo_seco"
                  );
                } else {
                  handleButtonsPivosController(
                    infContolersPivoFuntion[0],
                    "agua"
                  );
                  setTimeout(() => {
                    handleButtonsPivosController(
                      infContolersPivoFuntion[0],
                      "pivo_seco"
                    );
                  }, 30000);
                }
              }
            }}
          >
            {ststus ? "Deligar Pivo" : "Ligar a seco"}
          </button>
          {!ststus && (
            <button
              className="buttonWater"
              onClick={() =>
                handleButtonsPivosController(
                  infContolersPivoFuntion[0],
                  "pivo_irrigando"
                )
              }
            >
              {!ststus && "Ligar Irrigando"}
            </button>
          )}
          <button
            onClick={() =>
              setOpenModalConfimPivoComando(!openModalConfimPivoComando)
            }
          >
            {ststus ? "Cancelar" : "Não Ligar"}
          </button>
        </div>
      </div>
    </ModalConfirmController>
  );
};

export const ModalDefesivoAcricula = () => {
  const { modalDefesivoOn, setModalDefesivoOn } = useContext(PivoContext);
  return (
    <ModalDefesorAcriculas onClick={() => setModalDefesivoOn(!modalDefesivoOn)}>
      <div className="construction">
        <img src={constrution} alt="" />
      </div>
      <div
        className="conteiner"
        onClick={(e) => {
          e.stopPropagation(); // Impede a propagação do evento de clique para os elementos pai
        }}
      >
        <span
          className="close"
          onClick={() => setModalDefesivoOn(!modalDefesivoOn)}
        >
          <IoCloseCircle />
        </span>
        <h3>Defensivo Agrícola</h3>
        <div className="forms">
          <div className="icon">
            <RiImageAddFill />
            <input type="file" name="" id="" />
          </div>
        </div>
      </div>
    </ModalDefesorAcriculas>
  );
};

export const ModalAlertas = () => {
  const { modalAlertaOn, setModalAlertaOn, headerApi, alertsConts } =
    useContext(PivoContext);

  const [loading, setLoading] = useState(false);
  const [listAlertas, setListAlertas] = useState([]);
  const [confimarList, setConfimarList] = useState(false);
  const [counterPage, setCounterPage] = useState(1);
  const [loadingPage, setLoadingPage] = useState(false);

  const handleListAleta = useCallback(async () => {
    setLoading(true);
    try {
      const response = await apiConnect.get("pivo/alertas/", headerApi);
      setListAlertas(response.data);
    } catch (error) {
      toast.warn("Falha", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    } finally {
      setLoading(false);
    }
  }, [headerApi]);

  const confimeAlert = async (idAlerta) => {
    setLoading(true);
    const dataLido = { lido: true };
    try {
      await apiConnect.patch(`pivo/alertas/${idAlerta}/`, dataLido, headerApi);

      setListAlertas((prevAlertas) => {
        const updatedResults = prevAlertas.results.map((alerta) =>
          alerta.id === idAlerta ? { ...alerta, lido: true } : alerta
        );
        return { ...prevAlertas, results: updatedResults };
      });
    } catch (error) {
      // Handle error
      toast.warn("Falha", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    } finally {
      setLoading(false);
    }
  };

  const nextPageAlert = async (page) => {
    try {
      setLoadingPage(true);
      const response = await apiConnect(
        `pivo/alertas/?page=${page}`,
        headerApi
      );
      setListAlertas(response.data);
    } catch (error) {
      // Handle error
      toast.warn("Falha", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    } finally {
      setLoadingPage(false);
    }
  };

  useEffect(() => {
    handleListAleta();
  }, [handleListAleta]);

  useEffect(() => {
    if (modalAlertaOn) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
    return () => {
      document.body.classList.remove("modal-open");
    };
  }, [modalAlertaOn]);

  return (
    <ModalAlertasStyled onClick={() => setModalAlertaOn(!modalAlertaOn)}>
      {loading && <SquareLoading />}
      <div
        className="container"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="closeButton">
          <IoCloseSharp onClick={() => setModalAlertaOn(!modalAlertaOn)} />
        </div>
        <h3>Alertas</h3>
        <div className="headerAlertModal">
          <span>Alerta</span>
          <span>Unidade</span>
          <div className="alertasdateHeader">
            <span>Data</span>
            <span className="spanHorario">Hora</span>
          </div>
          <span
            className="click"
            onClick={() => {
              setConfimarList(!confimarList);
              if (!confimarList) {
                setListAlertas(alertsConts);
              } else {
                handleListAleta();
              }
            }}
          >
            Visualizado {confimarList ? <IoIosArrowDown /> : <IoIosArrowUp />}
          </span>
        </div>
        <ul>
          {Array.isArray(listAlertas.results) &&
            listAlertas.results.map((alerta) => (
              <li key={alerta.id}>
                <span>{alerta.alerta}</span>
                <span>{alerta.unidade}</span>
                <div className="alertasdate">
                  <span>{alerta.dia}</span>
                  <span>{alerta.hora ? alerta.hora.slice(0, 8) : ""}</span>
                </div>
                {!alerta.lido ? (
                  <button onClick={() => confimeAlert(alerta.id)}>
                    {/* <SiVerizon /> */}
                    <MdCheckBoxOutlineBlank />
                  </button>
                ) : (
                  // <span className="visualizado">Sim</span>
                  <span className="icon">
                    <IoMdCheckboxOutline />
                  </span>
                )}
              </li>
            ))}
        </ul>
        <div className="buttoes">
          {listAlertas.previous ? (
            <button
              disabled={loadingPage}
              onClick={() => {
                nextPageAlert(counterPage - 1);
                setCounterPage(counterPage - 1);
              }}
            >
              <IoIosArrowBack className="icon" />
            </button>
          ) : (
            <button>
              <IoIosArrowBack className="icon opacityOff" />
            </button>
          )}
          {loadingPage ? (
            <span className="loading"></span>
          ) : (
            <span>{counterPage}</span>
          )}
          {listAlertas.next ? (
            <button
              disabled={loadingPage}
              onClick={() => {
                nextPageAlert(counterPage + 1);
                setCounterPage(counterPage + 1);
              }}
            >
              <IoIosArrowForward className="icon" />
            </button>
          ) : (
            <button>
              <IoIosArrowForward className="icon opacityOff" />
            </button>
          )}
        </div>
      </div>
    </ModalAlertasStyled>
  );
};

export const ModalActionspivo = ({ actionPivoId }) => {
  const { headerApi } = useContext(PivoContext);
  const { ModalActions, setModalActions } = useContext(PivoContext);

  const [listActions, setListActions] = useState([]);

  const getActionsPivo = async () => {
    try {
      const response = await apiConnect.get(
        `ActionsPivo/leitura/${actionPivoId}/`,
        headerApi
      );
      setListActions(response.data);
    } catch (error) {}
  };

  useEffect(() => {
    getActionsPivo();
  }, []);

  useEffect(() => {
    // Adiciona a classe ao abrir o modal
    if (ModalActions) {
      document.body.classList.add("modal-open");
    }

    // Remove a classe ao fechar o modal
    return () => {
      document.body.classList.remove("modal-open");
    };
  }, [ModalActions]);

  return (
    <ModalActionPivoStyled>
      <div className="mainModal">
        <h2>Comandos realizados</h2>
        <span className="closeButton" onClick={() => setModalActions(false)}>
          X
        </span>
        <ul>
          {listActions.map((Actions) => {
            return (
              <li>
                <div className="newformat">
                  <span className="user">
                    {Actions.user.username.charAt(0).toUpperCase() +
                      Actions.user.username.slice(1)}
                  </span>
                  <span>{Actions.actionType}</span>
                  <span>
                    {format(parseISO(Actions.createdAt), "HH:mm dd/MM/yyyy")}
                  </span>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </ModalActionPivoStyled>
  );
};

export const HorarioPicoPivo = () => {
  const {
    listPivosControlles,
    handleUpdateControllerPivo,
    percetimetros,
    setPercetimetros,
    infContolersPivoFuntion,
    openModalConfimPivoComando,
    setOpenModalConfimPivoComando,
    listPivosData,
    ConfimeactionHorarioPico,
    setConfimeactionHorarioPico,
  } = useContext(PivoContext);

  const handleButtonsPivosController = async (pivoId, field) => {
    const pivoController = listPivosControlles.find((e) => pivoId === e.pivo);
    const pivoDados = listPivosData.find((e) => pivoId === e.pivo);

    if (pivoController.agua) {
      toast.warn("Falha Comando sendo executado", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      return;
    }

    if (!pivoController) {
      console.error("Controlador do Pivô não encontrado.");
      return;
    }

    let updatedData;

    switch (field) {
      case "percetimetro":
        // Tratar o campo 'percetimetro' como número
        const newValue = parseFloat(percetimetros[pivoId]) || 0;
        // Limitar o valor entre 0 e 100
        updatedData = {
          [field]: Math.min(100, Math.max(0, newValue)),
        };
        break;

      case "pivo_seco":
      case "pivo_irrigando":
      case "agua":
      case "sentido_de_rotacao":
        updatedData = {
          [field]: !pivoController[field],
        };
        break;

      default:
        updatedData = {
          [field]: !pivoController[field],
        };
        break;
    }

    const token = sessionStorage.getItem("connectionsToken");
    const headerApi = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let mensagensActin = "Comando Pivo";

    if (field === "pivo_irrigando") {
      mensagensActin = `Comando para ${
        pivoDados.status_pivo ? "Desligar" : "Ligar"
      } Pivô`;
    }
    if (field === "pivo_seco") {
      mensagensActin = `Comando para ${
        pivoDados.status_pivo ? "Desligar" : "Ligar"
      } Pivô a seco`;
    }
    if (field === "agua") {
      mensagensActin = `Comando para ${
        pivoDados.status_agua ? "Desligar" : "Ligar"
      } água  do Pivô`;
    }

    setPercetimetros({ ...percetimetros, [pivoId]: updatedData[field] });
    await handleUpdateControllerPivo(pivoController.id, updatedData);
    setOpenModalConfimPivoComando(false);

    const dataActionPivo = {
      actionType: mensagensActin,
      pivo_id: pivoId,
    };

    try {
      await apiConnect.post(`ActionsPivo`, dataActionPivo, headerApi);
    } catch (error) {}
  };

  useEffect(() => {
    // Adiciona a classe ao abrir o modal
    if (ConfimeactionHorarioPico) {
      document.body.classList.add("modal-open");
    }

    // Remove a classe ao fechar o modal
    return () => {
      document.body.classList.remove("modal-open");
    };
  }, [ConfimeactionHorarioPico]);

  return (
    <HorarioPicoModal>
      <div className="deleteContact">
        <h2>{`Tem certeza de que deseja fazer essa ação`}</h2>
        <div className="AvisoConteiner">
          <span>Horário</span>
          <PiWarningDuotone className="AvisoIcon" />
          <span>de Pico</span>
        </div>
        <div>
          <button
            className="buttonYes"
            onClick={() => {
              handleButtonsPivosController(
                infContolersPivoFuntion[0],
                "pivo_seco"
              );
            }}
          >
            Ligar a seco
          </button>
          <button
            className="buttonWater"
            onClick={() =>
              handleButtonsPivosController(
                infContolersPivoFuntion[0],
                "pivo_irrigando"
              )
            }
          >
            Ligar Irrigando
          </button>

          <button onClick={() => setConfimeactionHorarioPico(false)}>
            Não Ligar
          </button>
        </div>
      </div>
    </HorarioPicoModal>
  );
};
