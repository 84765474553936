import React, { useContext, useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LineElement,
  PointElement,
  CategoryScale,
  LinearScale,
  Tooltip,
} from "chart.js";
import Sol from "../../assets/estacao-meteorologica/SolP.svg";
import zoomPlugin from "chartjs-plugin-zoom";
import { StuledGraficImg } from "../../styles/meteorologia/containerComboGraficos";
import { format, parseISO } from "date-fns";
import { ClimaContext } from "../../contexts/contexClima";
import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(
  LineElement,
  PointElement,
  CategoryScale,
  Tooltip,
  ChartDataLabels,
  LinearScale,
  zoomPlugin
);

function useWindowWidth() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowWidth;
}

export const RandomLineChartWithImages = () => {
  const {
    climaIdSelect,
    listHistoricoClima,
    listHistorico,
    selecionarImagemClima,
  } = useContext(ClimaContext);

  const windowWidth = useWindowWidth();
  const zoomOptions = {
    pan: {
      enabled: windowWidth >= 650,
      mode: "x",
    },
    zoom: {
      wheel: {
        enabled: windowWidth >= 650,
      },
      pinch: {
        enabled: windowWidth >= 650,
      },
      mode: "x",
    },
  };

  const [customImages, setCustomImages] = useState([]);

  const historicoVento = listHistorico; // Pegando o estado correto
  const labelss = historicoVento.map((data) =>
    format(parseISO(data.changedAt), "HH:mm")
  );

  useEffect(() => {
    const imageSources = historicoVento.map((data) =>
      selecionarImagemClima(data.description, data.changedAt)
    );

    const images = [];
    imageSources.forEach((src, index) => {
      const img = new Image();
      img.src = src;
      img.onload = () => {
        images[index] = img;
        if (images.length === imageSources.length) {
          setCustomImages(images);
        }
      };
    });
  }, [historicoVento, selecionarImagemClima]);

  const data = {
    labels: labelss.filter((_, index) => index % 2 === 0), // Mostra apenas labels em índices pares
    datasets: [
      {
        label: "Clima",
        data: historicoVento
          .filter((_, index) => index % 2 === 0)
          .map((data) => data.temp.toFixed(1)), // Filtra os dados para mostrar apenas pares
        borderColor: "#4a4a4a",
        fill: false,
        pointStyle: customImages.filter((_, index) => index % 2 === 0), // Filtra as imagens também
        pointRadius: 10,
        pointHoverRadius: 15,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        ticks: {
          color: "#e7e0e0",
          font: {
            size: 11,
          },
        },
        grid: {
          color: "#61606097",
        },
        offset: true,
      },
      y: {
        min: 0,
        suggestedMax: 40,
        beginAtZero: true,
        ticks: {
          color: "#e7e0e0",
        },
        grid: {
          color: "#61606097",
        },
      },
    },
    elements: {
      line: {
        tension: 0.4,
      },
    },
    plugins: {
      legend: {
        position: "top",
        display: false,
        labels: {
          color: "#e7e0e0",
        },
      },
      tooltip: {
        enabled: true,
        callbacks: {
          label: function (context) {
            const value = context.raw;
            return `Temp: ${value}°C`;
          },
        },
      },
      datalabels: {
        display: true,
        align: "top",
        anchor: "end",
        formatter: (value) => `${value}°C`,
        font: {
          size: 12,
          weight: "bold",
        },
        color: "#fff",
      },
      zoom: zoomOptions,
    },
  };

  if (!customImages.length) {
    return <div>Loading...</div>; // Exibir um loading enquanto as imagens carregam
  }

  return (
    <StuledGraficImg>
      <Line data={data} options={options} />
    </StuledGraficImg>
  );
};
