import styled, { keyframes } from "styled-components";

export const rotateAnimation = keyframes`
from {
  transform: rotate(0deg);
}
to {
  transform: rotate(360deg);
}
`;

export const ModalConfirmController = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  max-height: 100%;
  background-color: rgba(18, 18, 20, 0.3);
  z-index: 99;
  .deleteContact {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 600px;
    max-width: 80%;
    background-color: rgba(18, 18, 20, 0.9);
    border: 2px solid #b2b2b2;
    padding: 20px;
    border-radius: 15px;
    gap: 15px;
    h2 {
      color: white;
      text-align: center;
    }
    .selectDW {
      background-color: rgba(0, 0, 0, 0.5);
      color: white;
      border: 2px solid white;
      border-radius: 5px;
      text-align: center;
    }
    div {
      display: flex;
      gap: 15px;
      width: 100%;
      button {
        width: 100%;
        height: 50px;
        font-size: 1rem;
        font-family: "Inter", sans-serif;
        color: #ffffff;
        border: none;
        background-color: rgba(195, 13, 26, 1);
        border-radius: 8px;
        cursor: pointer;
      }
      .buttonYes {
        background-color: transparent;
        color: white;
        border: 2px solid rgba(1, 255, 24, 1);
        transition: 1s;
      }
      .buttonWater {
        background-color: transparent;
        color: white;
        border: 2px solid rgba(9, 74, 199, 1);
        transition: 1s;
      }
      .buttonWater:hover {
        background-color: rgba(9, 74, 199, 1);
        color: white;
      }

      .buttonYes:hover {
        background-color: rgba(5, 164, 21, 1);
        color: white;
      }
    }
  }
`;

export const ModalCreatingPivo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  max-height: 100%;
  background-color: rgba(18, 18, 20, 0.5);
  z-index: 99;
  form {
    border: 2px solid rgba(125, 125, 125, 1);
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 400px;
    max-width: 95%;
    background-color: rgba(222, 222, 222, 0.9);
    box-shadow: 0 3px 20px 0 rgba(31, 38, 135, 0.2);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    border-radius: 8px;
    border: 1px solid rgba(255, 255, 255, 0.18);
    list-style: none;
    padding: 15px;
    position: relative;
    .closeModal {
      position: absolute;
      right: 10px;
      top: 10px;
      font-size: 30px;
      cursor: pointer;
      transition: 0.5s;
      color: rgba(118, 118, 118, 1);
    }
    .closeModal:hover {
      color: rgba(0, 0, 0, 1);
    }
    ul {
      display: flex;
      flex-direction: column;
      gap: 25px;
      list-style: none;
      li {
        display: flex;
        flex-direction: column;
        position: relative;
        label {
          font-weight: 600;
          color: rgba(62, 62, 62, 1);
          background: linear-gradient(
            to top,
            rgba(222, 222, 222, 1) 50%,
            transparent 50%
          );
          max-width: max-content;
          padding: 0px 4px;
          position: absolute;
          top: -10px;
          left: 15px;
          text-shadow: -1px -2px 8px rgba(255, 255, 255, 0.8);
        }
        input {
          padding: 15px 20px;
          border-radius: 15px;
          border: 1px solid black;
          background-color: rgba(222, 222, 222, 1);
          box-shadow: 0px -14px 55px -4px rgba(0, 0, 0, 0.3);
          -webkit-box-shadow: 0px -14px 55px -4px rgba(0, 0, 0, 0.3);
          -moz-box-shadow: 0px -14px 55px -4px rgba(0, 0, 0, 0.3);
        }
        select {
          padding: 15px 20px;
          border-radius: 15px;
          border: 1px solid black;
          background-color: rgba(222, 222, 222, 1);
          box-shadow: 0px -14px 55px -4px rgba(0, 0, 0, 0.3);
          -webkit-box-shadow: 0px -14px 55px -4px rgba(0, 0, 0, 0.3);
          -moz-box-shadow: 0px -14px 55px -4px rgba(0, 0, 0, 0.3);
        }
      }
    }
    button {
      height: 45px;
      border-radius: 10px;
      border: 2px solid rgba(76, 76, 76, 1);
      font-size: 18px;
      font-weight: 600;
      cursor: pointer;
      color: rgba(83, 83, 83, 1);
      background: rgba(198, 198, 198, 1);
      transition: 1s;
      box-shadow: -7px 28px 67px -20px rgba(0, 0, 0, 0.75);
      -webkit-box-shadow: -7px 28px 67px -20px rgba(0, 0, 0, 0.75);
      -moz-box-shadow: -7px 28px 67px -20px rgba(0, 0, 0, 0.75);
    }
    button:hover {
      background-color: rgba(222, 222, 222, 1);
    }
  }
`;

export const ModalCreatingUser = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  max-height: 100%;
  background-color: rgba(18, 18, 20, 0.5);
  z-index: 99;
  form {
    border: 2px solid rgba(125, 125, 125, 1);
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 400px;
    max-width: 95%;
    background: rgba(0, 0, 0, 0.4);
    box-shadow: 0 0px 30px 0 rgba(232, 232, 232, 0.1);
    backdrop-filter: blur(15px);
    -webkit-backdrop-filter: blur(15px);
    border-radius: 8px;
    border: 1px solid rgba(255, 255, 255, 0.18);
    list-style: none;
    padding: 15px;
    position: relative;
    color: rgba(208, 208, 208, 1);
    .closeModal {
      position: absolute;
      right: 10px;
      top: 10px;
      font-size: 30px;
      cursor: pointer;
      transition: 0.5s;
      color: rgba(208, 208, 208, 1);
    }
    .closeModal:hover {
      color: rgba(255, 255, 255, 1);
    }
    ul {
      display: flex;
      flex-direction: column;
      gap: 25px;
      list-style: none;
      li {
        display: flex;
        flex-direction: column;
        position: relative;
        label {
          font-weight: 600;
          color: rgba(255, 255, 255, 1);
          max-width: max-content;
          padding: 0px 4px;
          position: absolute;
          top: -10px;
          left: 15px;
          background: rgb(0, 0, 0);
          background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 0) 20%,
            rgba(44, 47, 48, 1) 50%,
            rgba(0, 0, 0, 0) 80%
          );
          backdrop-filter: blur(5px);
        }
        input {
          padding: 15px 20px;
          border-radius: 15px;
          border: 1px solid black;
          background-color: rgba(106, 106, 106, 0.4);
          box-shadow: 0px -14px 55px -4px rgba(0, 0, 0, 0.3);
          -webkit-box-shadow: 0px -14px 55px -4px rgba(0, 0, 0, 0.3);
          -moz-box-shadow: 0px -14px 55px -4px rgba(0, 0, 0, 0.3);
          color: rgba(255, 255, 255, 1);
        }
        .viewPassword {
          position: absolute;
          right: 10px;
          top: 15px;
          cursor: pointer;
        }
      }
      .redioInput {
        gap: 10px;
        label {
          position: relative;
        }
        .radio-container:checked {
          background-color: black;
          border-color: black;
        }
      }
    }
    button {
      height: 45px;
      border-radius: 10px;
      border: 2px solid rgba(76, 76, 76, 1);
      font-size: 18px;
      font-weight: 600;
      cursor: pointer;
      color: rgba(255, 255, 255, 1);
      background-color: rgba(106, 106, 106, 0.4);
      transition: 1s;
      box-shadow: -7px 28px 67px -20px rgba(0, 0, 0, 0.75);
      -webkit-box-shadow: -7px 28px 67px -20px rgba(0, 0, 0, 0.75);
      -moz-box-shadow: -7px 28px 67px -20px rgba(0, 0, 0, 0.75);
    }
    button:hover {
      background-color: rgba(27, 27, 27, 1);
    }
  }
`;

export const ModalGraphicgatewayStyeld = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 100vh;
  max-height: 100%;
  background-color: rgba(10, 10, 05, 0.5);
  backdrop-filter: blur(5px);
  z-index: 99;
  .container {
    width: 85%;
    height: 80%;
    max-height: 80vh;
    background-color: rgba(89, 89, 89, 0.92);
    border-radius: 10px;
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 15px;
    .ReniciarGTW {
      border: 1px solid rgba(208, 208, 208, 1);
      padding: 2px;
      font-size: 13px;
      border-radius: 3px;
      cursor: pointer;
    }
    .not404 {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 90%;
      bottom: 0;
      border-radius: 10px;
      background: rgb(0, 0, 0);
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 1) 0%,
        rgba(0, 0, 0, 0) 100%
      );
      color: rgba(247, 247, 247, 1);
      text-align: center;
    }
    .closeModal {
      position: absolute;
      top: 0px;
      right: 0px;
      font-size: 35px;
      color: rgba(210, 210, 210, 0.6);
      cursor: pointer;
      transition: 0.5s;
    }
    .closeModal:hover {
      color: rgba(210, 210, 210, 1);
    }
    h3 {
      font-size: 20px;
      color: rgba(227, 227, 227, 0.99);
    }
    .conteinerDate {
      display: flex;
      align-items: center;
      position: absolute;
      right: 6%;
    }
    .selectorHistorico {
      position: absolute;
      top: 10px;
      left: 35px;
      display: flex;
      gap: 10px;
      color: rgba(209, 208, 208, 1);
      border-bottom: 2px solid transparent;
      cursor: pointer;
      .select {
        color: rgba(255, 255, 255, 1);
        border-bottom: 2px solid rgba(255, 255, 255, 1);
      }
    }
    .conteinerIcon {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      color: rgba(207, 207, 207, 1);
      font-size: 45px;
      span {
        font-size: 18px;
        position: absolute;
        top: 17px;
        left: 12px;
      }
    }
    .ListDate {
      /* position: absolute;
      right: 11%;
      top: 15px; */
      select {
        background-color: rgba(0, 0, 0, 0.3);
        color: rgba(204, 204, 204, 1);
        width: 70px;
        height: 35px;
        border-radius: 5px;
        border: 2px solid rgba(121, 121, 121, 1);
        padding-left: 7px;
      }
    }
    .calendarDiv {
      display: flex;
      align-items: center;
      z-index: 9;
      padding-top: 50px;
      gap: 20px;
      flex-direction: column;
      width: 100%;
      height: 100%;
      position: fixed;
      background: rgb(0, 0, 0);
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.55) 70%,
        rgba(0, 0, 0, 0) 100%
      );
      backdrop-filter: blur(1px);
      .closeModal {
        position: absolute;
        right: 38%;
        font-size: 30px;
        top: 4%;
        cursor: pointer;
        color: rgba(220, 220, 220, 1);
        transition: 0.5s;
        display: none;
      }
      .closeModal:hover {
        color: white;
      }
      h3 {
        font-size: 25px;
        text-shadow: 0px 3px 5px #000000;
        color: white;
        text-align: center;
      }
      .calendar {
        border-radius: 20px;
        overflow: auto;
        /* background-color: rgba(35, 35, 35, 0.82); */
        .react-calendar__navigation button {
          background-color: rgba(0, 0, 0, 0.5); /* Cor de fundo do botão */
          color: white; /* Cor do texto */
          border: none; /* Removendo a borda padrão */
        }
      }
      .buutons {
        display: flex;
        justify-content: center;
        gap: 20px;
        width: 350px;
        max-width: 100%;
        button {
          padding: 10px 20px;
          cursor: pointer;
          border-radius: 8px;
          font-size: 16px;
        }
      }
    }
    .grafico {
      width: 100%;
      height: 97%;
    }
  }
  @media (max-width: 600px) {
    .container {
      width: 95%;
      h3 {
        margin-top: 25px;
      }
      .conteinerDate {
        top: 0;
        right: 9%;
      }
    }
  }
`;

export const ModalDefesorAcriculas = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  max-height: 100%;
  background-color: rgba(18, 18, 20, 0.2);
  z-index: 99;
  backdrop-filter: blur(2px);
  .construction {
    position: absolute;
    z-index: 2;
    opacity: 90%;
    border-radius: 20px;
    max-width: 100%;
    width: 500px;
    overflow: hidden;
    img {
      width: 100%;
    }
  }
  .conteiner {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 30px;
    width: 60%;
    height: 60vh;
    background-color: rgba(181, 181, 181, 0.95);
    border-radius: 10px;
    position: relative;
    .forms {
      display: flex;
      gap: 25px;
      align-items: center;
      .icon {
        width: 220px;
        display: flex;
        flex-direction: column;
        align-items: center;
        input::-webkit-file-upload-button {
          visibility: hidden;
        }
        font-size: 100px;
        cursor: pointer;
        color: rgba(87, 87, 87, 0.9);
        transition: 0.5s;
      }
      .icon:hover {
        color: rgba(0, 0, 0, 1);
      }
      /* Estilo para o input file */
      .icon input[type="file"]::-webkit-file-upload-button {
        visibility: hidden;
      }

      .icon input[type="file"]::before {
        content: "Selecione uma Imagem"; /* Texto personalizado */
        display: inline-block;
        background: rgba(23, 23, 23, 0.5); /* Cor de fundo */
        color: #fff; /* Cor do texto */
        padding: 8px 20px; /* Espaçamento interno */
        outline: none;
        border: none;
        border-radius: 5px;
        cursor: pointer;
      }
      .icon input[type="file"] {
      }
    }
    .close {
      position: absolute;
      top: 15px;
      right: 15px;
      font-size: 30px;
      cursor: pointer;
    }
  }
`;

export const ModalAlertasStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  max-height: 100%;
  background-color: rgba(0, 0, 0 0.3);
  backdrop-filter: blur(10px);
  position: fixed;
  z-index: 99;
  top: 0;
  box-shadow: 1px 2px 31px 0px rgba(255, 255, 255, 0.41);
  -webkit-box-shadow: 1px 2px 31px 0px rgba(255, 255, 255, 0.41);
  -moz-box-shadow: 1px 2px 31px 0px rgba(255, 255, 255, 0.41);
  .container {
    display: flex;
    flex-direction: column;
    width: 80%;
    height: 90%;
    padding: 25px;
    background-color: rgba(21, 21, 21, 0.92);
    border-radius: 20px;
    position: relative;
    transition: 1s;
    .closeButton {
      position: absolute;
      right: 15px;
      top: 15px;
      color: white;
      font-size: 32px;
      cursor: pointer;
      text-shadow: 0px 0px 14px #cecece;
    }
    h3 {
      display: flex;
      width: 100%;
      justify-content: center;
      font-size: 25px;
      color: rgba(221, 221, 221, 1);
    }
  }
  .headerAlertModal {
    display: flex;
    justify-content: space-around;
    width: 100%;
    color: rgba(190, 190, 190, 1);
    font-size: 18px;
    border-bottom: 3px solid rgba(210, 210, 210, 1);
    padding-bottom: 5px;
    span {
      text-align: center;
      width: 200px;
      max-width: 100%;
    }
    .alertasdateHeader {
      display: flex;
      gap: 20px;
      max-width: 100%;
      span {
        width: 120px;
      }
    }
    .click {
      width: 115px;
    }
  }
  ul {
    display: flex;
    flex-direction: column;
    gap: 15px;

    width: 100%;
    height: 100%;
    overflow-y: scroll;

    margin-top: 15px;
    list-style: none;
    scrollbar-width: auto;

    li {
      color: rgba(227, 227, 227, 1);
      display: flex;
      font-size: 20px;
      align-items: center;
      max-width: 100%;
      justify-content: space-around;
      border-bottom: 2px solid rgba(100, 100, 100, 0.5);
      padding-bottom: 5px;
      span {
        text-align: center;
        width: 200px;
        max-width: 100%;
      }
      .alertasdate {
        display: flex;
        gap: 15px;
        max-width: 100%;
        span {
          width: 120px;
        }
      }

      .icon {
        width: 115px;
        font-size: 25px;
      }
      button {
        border: none;
        background-color: transparent;
        font-size: 25px;
        color: rgba(227, 227, 227, 1);
        cursor: pointer;
        width: 115px;
      }
    }
  }
  /* Estilos da barra de rolagem */
  ul::-webkit-scrollbar {
    width: 10px; /* Largura da barra de rolagem */
  }

  ul::-webkit-scrollbar-track {
    background: rgba(57, 57, 57, 0.1); /* Cor do fundo da barra de rolagem */
    border-radius: 5px;
  }

  ul::-webkit-scrollbar-thumb {
    background: #888; /* Cor do botão de rolagem */
    border-radius: 5px; /* Raio da borda do botão de rolagem */
  }

  ul::-webkit-scrollbar-thumb:hover {
    background: #555; /* Cor do botão de rolagem quando o mouse passa por cima */
  }

  .buttoes {
    margin-top: 15px;
    background-color: rgba(0, 0, 0, 0);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 35px;
    color: white;
    width: 100%;
    button {
      background-color: transparent;
      border: none;
      cursor: pointer;
      font-size: 30px;
      height: 30px;
      .icon {
        color: rgba(198, 198, 198, 1);
        transition: 0.5s;
      }
      .icon:hover {
        color: rgba(255, 255, 255, 1);
      }
      .opacityOff {
        opacity: 10%;
      }
    }
    span {
      font-size: 25px;
    }
    .loading {
      border-radius: 50px;
      border: 5px dotted rgba(210, 210, 210, 1);
      border-top: 2ps solid transparent;
      width: 20px;
      height: 20px;
      animation: ${rotateAnimation} 2s infinite;
    }
  }
  .visualizado {
    display: flex;
    justify-content: center;
    width: 115px;
    min-width: 40px;
  }

  @media (max-width: 650px) {
    .container {
      width: 97%;
      padding: 8px;
      span {
        font-size: 14px;
      }

      ul {
        li {
          .alertasdate {
            flex-direction: column;
          }
        }
      }
    }
    .headerAlertModal {
      margin-top: 25px;
      .alertasdateHeader {
        .spanHorario {
          display: none;
        }
      }
    }
  }
`;

export const ModalDatePersonalizadoStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 15px;
  width: 100%;
  height: 100vh;
  max-height: 100%;
  background-color: rgba(0, 0, 0 0.3);
  backdrop-filter: blur(10px);
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  box-shadow: 1px 2px 31px 0px rgba(255, 255, 255, 0.41);
  -webkit-box-shadow: 1px 2px 31px 0px rgba(255, 255, 255, 0.41);
  -moz-box-shadow: 1px 2px 31px 0px rgba(255, 255, 255, 0.41);

  .container {
    padding: 25px;
    background-color: rgba(214, 214, 214, 0.8);
    border-radius: 20px;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    max-width: 80%;

    .inpults {
      display: flex;
      gap: 20px;
      max-width: 100%;
      flex-wrap: wrap;
      input[type="checkbox"] {
        position: relative;
        cursor: pointer;
      }

      input[type="checkbox"]:before {
        content: "";
        display: block;
        position: absolute;
        width: 25px;
        height: 25px;
        top: 0;
        left: 0;
        background-color: rgba(255, 255, 255, 1);
        border-radius: 5px;
        border: 1px solid black;
      }

      input[type="checkbox"]:checked:after {
        content: "";
        display: block;
        width: 5px;
        height: 10px;
        border: solid black;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
        position: absolute;
        top: 5px;
        left: 9px;
      }
      label {
        font-size: 20px;
      }
    }
  }
  .buttons {
    display: flex;
    gap: 10px;
    button {
      padding: 6px 15px;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      font-size: 18px;
      background-color: rgba(214, 214, 214, 0.8);
    }
  }
`;

export const ModalMedidor = styled.div`
  display: flex;
  justify-content: center;
  align-items: end;
  flex-direction: column;
  gap: 15px;
  width: 100%;
  height: 100vh;
  max-height: 100%;
  background-color: rgba(0, 0, 0 0.3);
  backdrop-filter: blur(10px);
  position: fixed;
  z-index: 9;
  top: 0;
  left: 0;
  box-shadow: 1px 2px 31px 0px rgba(255, 255, 255, 0.41);
  -webkit-box-shadow: 1px 2px 31px 0px rgba(255, 255, 255, 0.41);
  -moz-box-shadow: 1px 2px 31px 0px rgba(255, 255, 255, 0.41);
  padding-right: 50px;
  .confimation {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    max-height: 100%;
    background-color: rgba(18, 18, 20, 0.3);
    z-index: 50;
    .creatMedidor {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 600px;
      max-width: 80%;
      background-color: rgba(18, 18, 20, 0.9);
      border: 2px solid #b2b2b2;
      padding: 20px;
      border-radius: 15px;
      gap: 15px;
      h2 {
        color: white;
        text-align: center;
      }
      div {
        display: flex;
        gap: 15px;
        width: 100%;
        button {
          width: 100%;
          height: 50px;
          font-size: 1rem;
          font-family: "Inter", sans-serif;
          color: #ffffff;
          border: none;
          background-color: rgba(195, 13, 26, 1);
          border-radius: 8px;
          cursor: pointer;
        }
        .buttonYes {
          background-color: transparent;
          color: rgba(1, 255, 24, 1);
          border: 2px solid rgba(1, 255, 24, 1);
          transition: 1s;
        }
        .buttonYes:hover {
          background-color: rgba(5, 164, 21, 1);
          color: white;
        }
      }
    }
  }
  .conteiner {
    display: flex;
    flex-direction: column;
    width: 92%;
    height: 85%;
    background-color: rgba(159, 159, 159, 0.85);
    border-radius: 5px;
    position: relative;
    padding: 18px;
    gap: 25px;
    h3 {
      font-size: 25px;
      color: rgba(47, 47, 47, 1);
    }
    .creatNivel {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;
      .icon {
        font-size: 25px;
        cursor: pointer;
        color: rgba(46, 46, 46, 1);
        transition: 0.5s;
      }
      .icon:hover {
        color: rgba(0, 0, 0, 1);
      }
    }
    .closeModal {
      position: absolute;
      top: 5px;
      right: 5px;
      font-size: 35px;
      color: rgba(64, 64, 64, 1);
      cursor: pointer;
      transition: 0.5s;
    }
    .closeModal:hover {
      color: black;
    }
    ul {
      list-style: none;
      display: flex;
      gap: 25px;
      flex-wrap: wrap;
      li {
        width: 200px;
        display: flex;
        flex-direction: column;
        gap: 15px;
        width: 350px;
        min-height: 40px;
        max-width: 100%;
        background: rgba(0, 0, 0, 0.6);
        border-radius: 10px;
        box-shadow: 0 0px 30px 0 rgba(232, 232, 232, 0.2);
        padding: 15px;
        color: rgba(203, 203, 203, 1);
        padding-top: 50px;
        position: relative;
        .headerLi {
          position: absolute;
          top: 20px;
          left: 38%;
          font-size: 18px;
          font-weight: 600;
        }
        button {
          height: 40px;
          width: 100%;
          background: rgba(0, 0, 0, 0.2);
          border: 2px solid rgba(203, 203, 203, 0.3);
          color: rgba(203, 203, 203, 1);
          border-radius: 5px;
          cursor: pointer;
          transition: 0.5s;
        }
        button:hover {
          background: rgba(0, 0, 0, 0.4);
          color: rgba(194, 107, 107, 0.85);
          border: 2px solid rgba(194, 107, 107, 0.85);
        }
      }
    }
  }
`;

export const ModalBombaTanque = styled.div`
  display: flex;
  justify-content: center;
  align-items: end;
  flex-direction: column;
  gap: 15px;
  width: 100%;
  height: 100vh;
  max-height: 100%;
  background-color: rgba(0, 0, 0 0.3);
  backdrop-filter: blur(10px);
  position: fixed;
  z-index: 9;
  top: 0;
  left: 0;
  box-shadow: 1px 2px 31px 0px rgba(255, 255, 255, 0.41);
  -webkit-box-shadow: 1px 2px 31px 0px rgba(255, 255, 255, 0.41);
  -moz-box-shadow: 1px 2px 31px 0px rgba(255, 255, 255, 0.41);
  padding-right: 50px;
  .confimation {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    max-height: 100%;
    background-color: rgba(18, 18, 20, 0.3);
    z-index: 50;
    .ConfirmationChanges {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 600px;
      max-width: 80%;
      background-color: rgba(18, 18, 20, 0.9);
      border: 2px solid #b2b2b2;
      padding: 20px;
      border-radius: 15px;
      gap: 15px;
      h2 {
        color: white;
        text-align: center;
      }
      div {
        display: flex;
        gap: 15px;
        width: 100%;
        button {
          width: 100%;
          height: 50px;
          font-size: 1rem;
          font-family: "Inter", sans-serif;
          color: #ffffff;
          border: none;
          background-color: rgba(195, 13, 26, 1);
          border-radius: 8px;
          cursor: pointer;
        }
        .buttonYes {
          background-color: transparent;
          color: rgba(1, 255, 24, 1);
          border: 2px solid rgba(1, 255, 24, 1);
          transition: 1s;
        }
        .buttonYes:hover {
          background-color: rgba(5, 164, 21, 1);
          color: white;
        }
      }
    }
  }
  .conteiner {
    display: flex;
    flex-direction: column;
    width: 92%;
    height: 85%;
    background-color: rgba(159, 159, 159, 0.75);
    border-radius: 5px;
    position: relative;
    padding: 18px;
    gap: 25px;
    overflow: auto;
    h3 {
      font-size: 25px;
      color: rgba(47, 47, 47, 1);
    }
    .creatNivel {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;
      .icon {
        font-size: 25px;
        cursor: pointer;
        color: rgba(46, 46, 46, 1);
        transition: 0.5s;
      }
      .icon:hover {
        color: rgba(0, 0, 0, 1);
      }
    }
    .closeModal {
      position: absolute;
      top: 5px;
      right: 5px;
      font-size: 35px;
      color: rgba(64, 64, 64, 1);
      cursor: pointer;
      transition: 0.5s;
    }
    .closeModal:hover {
      color: black;
    }
    ul {
      list-style: none;
      display: flex;
      gap: 25px;
      flex-wrap: wrap;
      li {
        width: 200px;
        display: flex;
        flex-direction: column;
        gap: 15px;
        width: 350px;
        min-height: 40px;
        max-width: 100%;
        background: rgba(0, 0, 0, 0.6);
        border-radius: 10px;
        box-shadow: 0 0px 30px 0 rgba(232, 232, 232, 0.2);
        padding: 15px;
        color: rgba(203, 203, 203, 1);
        padding-top: 50px;
        position: relative;
        .deleteBomba {
          position: absolute;
          top: 5px;
          right: 15px;
          font-size: 29px;
          cursor: pointer;
          transition: 0.5s;
        }
        .deleteBomba:hover {
          color: rgba(216, 84, 84, 0.8);
        }
        .IdBomba {
          position: absolute;
          font-size: 17px;
          font-weight: 600;
          top: 10px;
          left: 35%;
          color: rgba(239, 239, 239, 1);
        }
        .headerLi {
          position: absolute;
          top: 20px;
          left: 38%;
          font-size: 18px;
          font-weight: 600;
        }

        .buttons {
          display: flex;
          gap: 5px;

          button {
            height: 40px;
            width: 100%;
            background: rgba(0, 0, 0, 0.2);
            border: 2px solid rgba(203, 203, 203, 0.3);
            color: rgba(203, 203, 203, 1);
            border-radius: 5px;
            cursor: pointer;
            transition: 0.8s;
          }
          .off {
            opacity: 50%;
            cursor: no-drop;
          }
          button:hover {
            background: rgba(0, 0, 0, 0.4);
            color: rgba(255, 255, 255, 1);
            border: 2px solid rgba(255, 255, 255, 1);
          }
        }
        .infosPivo {
          display: flex;
          flex-direction: column;
          gap: 20px;
          position: relative;
          height: 47px;
          p {
            font-weight: 600;
          }
          label {
            font-weight: 600;
            color: rgba(207, 207, 207, 1);
            background: linear-gradient(
              to top,
              rgba(29, 29, 29, 0.95) 50%,
              transparent 50%
            );
            max-width: max-content;
            padding: 0px 4px;
            position: absolute;
            top: -11px;
            left: 15px;
            /* text-shadow: -1px -2px 8px rgba(255, 255, 255, 0.8); */
          }
          input {
            padding: 15px 20px;
            border-radius: 15px;
            border: 1px solid black;
            background-color: rgba(29, 29, 29, 0.95);
            box-shadow: 0px -14px 55px -4px rgba(0, 0, 0, 0.3);
            -webkit-box-shadow: 0px -14px 55px -4px rgba(0, 0, 0, 0.3);
            -moz-box-shadow: 0px -14px 55px -4px rgba(0, 0, 0, 0.3);
            color: rgba(216, 216, 216, 1);
          }
        }
      }
    }
  }
`;

export const HorarioPicoModal = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  max-height: 100%;
  background-color: rgba(18, 18, 20, 0.3);
  z-index: 99;
  .deleteContact {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 600px;
    max-width: 80%;
    background-color: rgba(18, 18, 20, 0.9);
    border: 2px solid rgba(252, 255, 0, 0.8);
    padding: 20px;
    border-radius: 15px;
    gap: 15px;
    .AvisoConteiner {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;
      .AvisoIcon {
        font-size: 80px;
        color: rgba(252, 255, 0, 0.8);
      }
      span {
        color: white;
        font-size: 25px;
      }
    }
    span {
      color: white;
    }
    h2 {
      color: white;
      text-align: center;
    }
    .selectDW {
      background-color: rgba(0, 0, 0, 0.5);
      color: white;
      border: 2px solid white;
      border-radius: 5px;
      text-align: center;
    }
    div {
      display: flex;
      gap: 15px;
      width: 100%;
      button {
        width: 100%;
        height: 50px;
        font-size: 1rem;
        font-family: "Inter", sans-serif;
        color: #ffffff;
        border: none;
        background-color: rgba(195, 13, 26, 1);
        border-radius: 8px;
        cursor: pointer;
      }
      .buttonYes {
        background-color: transparent;
        color: white;
        border: 2px solid rgba(1, 255, 24, 1);
        transition: 1s;
      }
      .buttonWater {
        background-color: transparent;
        color: white;
        border: 2px solid rgba(9, 74, 199, 1);
        transition: 1s;
      }
      .buttonWater:hover {
        background-color: rgba(9, 74, 199, 1);
        color: white;
      }

      .buttonYes:hover {
        background-color: rgba(5, 164, 21, 1);
        color: white;
      }
    }
  }
`;

export const UnidadesUserStyled = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 100%;
  height: 100vh;
  background-color: rgba(18, 18, 20, 0.6);
  justify-content: center;
  align-items: center;
  z-index: 9;
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  .buttonClose {
    position: relative;
    .closeModal {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      border-radius: 10px;
      top: -10px;
      right: -130px;
      background-color: rgba(163, 163, 163, 1);
      width: 25px;
      height: 25px;
      cursor: pointer;
      z-index: 5;
    }
  }
  ul {
    display: flex;
    width: fit-content;
    flex-direction: column;
    gap: 15px;
    max-width: 100%;
    padding: 15px;
    border-radius: 15px;
    padding-bottom: 25px;
    background-color: rgb(0, 0, 0, 0.5);
    list-style: none;
    position: relative;
    border: 2px solid rgba(228, 223, 223, 1);
    max-height: 60vh;
    max-width: 100%;
    overflow-y: auto;
    li {
      display: flex;
      gap: 5px;
      align-items: center;

      .checkbox-wrapper-24 .checkbox {
        display: table-cell;
        width: 100%;
        height: 100%;
        vertical-align: middle;
        text-align: center;
      }

      .checkbox-wrapper-24 label {
        display: inline-block;
        color: rgba(240, 240, 240, 1);
        cursor: pointer;
        font-size: 25px;
        position: relative;
      }
      .checkbox-wrapper-24 label span {
        display: inline-block;
        position: relative;
        background-color: transparent;
        width: 26px;
        height: 26px;
        transform-origin: center;
        border: 2px solid rgba(204, 204, 204, 1);
        border-radius: 50%;
        vertical-align: middle;
        margin-right: 10px;
        transition: background-color 150ms 200ms,
          transform 350ms cubic-bezier(0.78, -1.22, 0.17, 1.89);
      }
      .checkbox-wrapper-24 label span:before {
        content: "";
        width: 0px;
        height: 2px;
        border-radius: 2px;
        background: #333;
        position: absolute;
        transform: rotate(45deg);
        top: 13px;
        left: 9px;
        transition: width 50ms ease 50ms;
        transform-origin: 0% 0%;
      }
      .checkbox-wrapper-24 label span:after {
        content: "";
        width: 0;
        height: 2px;
        border-radius: 2px;
        background: #333;
        position: absolute;
        transform: rotate(305deg);
        top: 16px;
        left: 10px;
        transition: width 50ms ease;
        transform-origin: 0% 0%;
      }
      .checkbox-wrapper-24 label:hover span:before {
        width: 5px;
        transition: width 100ms ease;
      }
      .checkbox-wrapper-24 label:hover span:after {
        width: 10px;
        transition: width 150ms ease 100ms;
      }

      .checkbox-wrapper-24 input[type="checkbox"] {
        display: none;
      }
      .checkbox-wrapper-24 input[type="checkbox"]:checked + label span {
        background-color: #333;
        transform: scale(1.15);
      }
      .checkbox-wrapper-24 input[type="checkbox"]:checked + label span:after {
        width: 10px;
        background: #fff;
        transition: width 150ms ease 100ms;
      }
      .checkbox-wrapper-24 input[type="checkbox"]:checked + label span:before {
        width: 5px;
        background: #fff;
        transition: width 150ms ease 100ms;
      }
      .checkbox-wrapper-24 input[type="checkbox"]:checked + label:hover span {
        background-color: #333;
        transform: scale(1.15);
      }
      .checkbox-wrapper-24
        input[type="checkbox"]:checked
        + label:hover
        span:after {
        width: 10px;
        background: #fff;
        transition: width 150ms ease 100ms;
      }
      .checkbox-wrapper-24
        input[type="checkbox"]:checked
        + label:hover
        span:before {
        width: 5px;
        background: #fff;
        transition: width 150ms ease 100ms;
      }
    }
    span {
      color: white;
      font-size: 25px;
    }
  }
  .buttonSalve {
    transform: translate(0px, -15px);
    button {
      padding: 8px;
      border: 2px solid rgba(228, 223, 223, 1);
      background-color: rgb(0, 0, 0, 1);
      border-radius: 5px;
      color: rgba(228, 223, 223, 1);
    }
  }
`;

export const ModalDesligamentoPivo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  max-height: 100%;
  background-color: rgba(18, 18, 20, 0.3);
  z-index: 99;
  .conteinerModal {
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: center;
    background-color: rgba(25, 25, 25, 0.9);
    border-radius: 15px;
    padding: 15px;
    .relogioDeligamento {
      .MuiFormControl-root {
        width: 100%;
      }
      button {
        color: #bfbfbf;
      }
      label {
        color: #bfbfbf;
      }
      div {
        input {
          color: #bfbfbf;
        }
      }
      fieldset {
        border-color: #bfbfbf;
        input {
          color: #bfbfbf;
        }
      }
    }
    .buttoes {
      display: flex;
      gap: 15px;
      width: 100%;
      /* background-color: #bfbfbf; */
      justify-content: center;
      button {
        padding: 8px;
        font-size: 18px;
        color: rgba(227, 227, 227, 1);
        background-color: transparent;
        border: 2px solid rgba(227, 227, 227, 1);
        border-radius: 5px;
        min-width: 150px;
        font-weight: 600;
        cursor: pointer;
      }
      .buttonAgend {
        border: 3px solid rgba(1, 32, 198, 0.8);
        transition: 1s;
        &:hover {
          background-color: rgba(1, 32, 198, 0.8);
        }
      }
      .buttonNo {
        border: 2px solid rgba(149, 0, 0, 0.8);
        background-color: rgba(149, 0, 0, 0.8);
        transition: 1s;
        &:hover {
          background-color: rgba(149, 0, 0, 1);
          border: 2px solid rgba(227, 227, 227, 1);
        }
      }
    }
  }
`;
