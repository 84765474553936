import React, { useContext, useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import zoomPlugin from "chartjs-plugin-zoom";
import Calendar from "react-calendar";
import { RiCloseCircleFill } from "react-icons/ri";
import { PiCalendarBlankThin } from "react-icons/pi";
import { format } from "date-fns";
import { GraficosTankes } from "../styles/tanque/graficos";
import { apiConnect } from "../services/api";
import { TanqueContextC } from "../contexts/contextTanque";

// Registrar os componentes necessários do Chart.js e plugins
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  zoomPlugin
);

export const GraficosTanquesC = ({ TanquesData }) => {
  // Extrair rótulos e níveis dos tanques dos dados fornecidos
  const tankLabels = TanquesData.map((tank) =>
    tank.name ? tank.name.replace("Tanque", "").trim() : tank.dia
  );
  const tankLevels = TanquesData.map((tank) =>
    tank.niveis.reduce((nivelAnterior, nivelAtual) => nivelAtual.nivel, 0)
  );

  // Opções de zoom para o gráfico
  const zoomOptions = {
    pan: {
      enabled: true,
      mode: "x",
    },
    zoom: {
      wheel: {
        enabled: true,
      },
      pinch: {
        enabled: true,
      },
      mode: "x",
    },
  };

  // Configurações gerais do gráfico
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
        display: false,
        labels: {
          color: "#e7e0e0", // Cor do texto do rótulo do conjunto de dados
          font: {
            size: 16, // Tamanho da fonte do texto do eixo y
          },
        },
      },
      title: {
        display: false,
      },
      datalabels: {
        display: true,

        formatter: (value) => `${value}%`,
        font: {
          size: 12,
          weight: "bold",
        },
        color: "#ffffff",
      },
      zoom: zoomOptions,
    },

    scales: {
      x: {
        ticks: {
          color: "#bababa",
        },
        grid: {
          color: "#e0e0e01a",
          borderColor: "#d3d3d319",
        },
      },
      y: {
        min: 0,
        max: 100,
        ticks: {
          color: "#979797",
        },
        grid: {
          color: "#e0e0e029",
          borderColor: "#d3d3d31f",
        },
      },
    },
  };

  // Estrutura de dados para o gráfico
  const data = {
    labels: tankLabels,
    datasets: [
      {
        label: "Nível do Tanque",
        data: tankLevels,
        backgroundColor: "rgba(157, 157, 157, 0.706)",
        borderColor: "rgba(93, 93, 93, 0.559)",
        borderWidth: 1,
      },
    ],
  };

  return (
    <GraficosTankes>
      <h2>Níveis dos Tanques</h2>
      <Bar options={options} data={data} />
    </GraficosTankes>
  );
};

export const GraficosTanque = ({ TanqueDataId }) => {
  const { tanquesList } = useContext(TanqueContextC);

  const [historicoLabel, setHistoricoLabel] = useState([]);
  const [historicoData, setHistoricoData] = useState([]);
  const [modalCalender, setModalCalender] = useState(false);
  const [calenderDate, setCalenderDate] = useState(new Date());
  const [dateDay, setDateDay] = useState(new Date().getDate());
  const [selectDate, setSelectDate] = useState("dia");
  const [niveisFilter, setNiveisFilter] = useState([]);
  const [idNivell, setIdNivell] = useState(null);

  // Função para buscar o histórico dos níveis
  const listHistorico = async (idNivel) => {
    const formatData = format(calenderDate, "dd-MM-yyyy");
    const token = sessionStorage.getItem("connectionsToken");
    const headerApi = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const findTanquesList = tanquesList.find((e) => e.id === TanqueDataId);
    setDateDay(calenderDate.getDate());
    setNiveisFilter(findTanquesList.niveis);

    // Caso o idNivel não seja especificado, utilizar o primeiro da lista
    if (!idNivel) {
      idNivel = findTanquesList.niveis[0].id;
    }

    try {
      const response = await apiConnect.get(
        `/tanques/nivel/historico/${idNivel}/${formatData}/${selectDate}`,
        headerApi
      );
      const historicoFiltrado = filtrarEFormatarData(response.data);
      setHistoricoData(historicoFiltrado);
    } catch (error) {
      if (
        error.response.data.message ===
        "Nenhum histórico encontrado para este nível no período especificado"
      ) {
        setHistoricoData([]);
        setHistoricoLabel([]);
      }
    } finally {
      setModalCalender(false);
    }
  };

  // Filtrar e formatar os dados do histórico
  const filtrarEFormatarData = (historicoArray) => {
    const datasUnicas = {};
    const historicoFiltradoFormatado = historicoArray.filter((item) => {
      const dataFormatada = new Date(item.dataHora).toLocaleString("pt-BR", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      });

      if (datasUnicas[dataFormatada]) {
        return false;
      } else {
        datasUnicas[dataFormatada] = true;
        return true;
      }
    });

    const newHistorico = historicoFiltradoFormatado.map((historico) => {
      const date = new Date(historico.dataHora);
      const localDate = new Date(
        date.toLocaleString("en-US", { timeZone: "UTC" })
      );

      const dia = localDate.getDate().toString().padStart(2, "0");
      const mes = (localDate.getMonth() + 1).toString().padStart(2, "0");
      const ano = localDate.getFullYear();
      const hora = localDate.getHours().toString().padStart(2, "0");
      const minuto = localDate.getMinutes().toString().padStart(2, "0");

      const dataFormatada = `${dia}/${mes}/${ano} ${hora}:${minuto}`;

      return dataFormatada;
    });

    setHistoricoLabel(newHistorico);

    return historicoFiltradoFormatado;
  };

  useEffect(() => {
    listHistorico(idNivell);
  }, [TanqueDataId, selectDate]);

  // Opções de zoom para o gráfico
  const zoomOptions = {
    pan: {
      enabled: true,
      mode: "x",
    },
    zoom: {
      wheel: {
        enabled: true,
      },
      pinch: {
        enabled: true,
      },
      mode: "x",
    },
  };

  // Configurações gerais do gráfico
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
        display: false,
        labels: {
          color: "#e7e0e0", // Cor do texto do rótulo do conjunto de dados
          font: {
            size: 16, // Tamanho da fonte do texto do eixo y
          },
        },
      },
      title: {
        display: false,
      },
      datalabels: {
        display: true,

        formatter: (value) => `${value}%`,
        font: {
          size: 12,
          weight: "bold",
        },
        color: "#ffffff",
      },
      zoom: zoomOptions,
    },

    scales: {
      x: {
        ticks: {
          color: "#bababa",
        },
        grid: {
          color: "#e0e0e01a",
          borderColor: "#d3d3d319",
        },
      },
      y: {
        min: 0,
        max: 100,
        ticks: {
          color: "#979797",
        },
        grid: {
          color: "#e0e0e029",
          borderColor: "#d3d3d31f",
        },
      },
    },
  };

  // Estrutura de dados para o gráfico
  const data = {
    labels: historicoLabel,
    datasets: [
      {
        label: "Nível do Tanque",
        data: historicoData.map((historico) => historico.nivelValor),
        backgroundColor: "rgba(157, 157, 157, 0.706)",
        borderColor: "rgba(93, 93, 93, 0.559)",
        borderWidth: 1,
      },
    ],
  };

  return (
    <GraficosTankes>
      <div className="headerGrafic">
        <h2>Níveis dos Tanques</h2>
        <div className="neveisButton">
          {niveisFilter.map((niveis, index) => (
            <div key={index}>
              <span>Nivel {index + 1} </span>
              <button
                onClick={() => {
                  listHistorico(niveis.id);
                  setIdNivell(niveis.id);
                }}
              >
                .
              </button>
            </div>
          ))}
        </div>
      </div>
      {modalCalender && (
        <div className="calendarDiv">
          <span className="closeModal" onClick={() => setModalCalender(false)}>
            <RiCloseCircleFill />
          </span>
          <h3>Selecione uma data de histórico.</h3>
          <Calendar
            className="calendar"
            onChange={setCalenderDate}
            value={calenderDate}
            maxDate={new Date()}
          />
          <div className="buutons">
            <button onClick={() => listHistorico(idNivell)}>Pesquisar</button>
            <button onClick={() => setModalCalender(false)}>Cancelar</button>
          </div>
        </div>
      )}
      <div className="conteinerIcon" onClick={() => setModalCalender(true)}>
        <PiCalendarBlankThin />
        <span>{dateDay}</span>
      </div>
      <div className="ListDate">
        <select
          name="selectdate"
          id="selectdate"
          onChange={(e) => setSelectDate(e.target.value)}
        >
          <option value="dia">Dia</option>
          <option value="mes">Mês</option>
          <option value="ano">Ano</option>
        </select>
      </div>
      {historicoData.length === 0 && (
        <div className="not404">
          <h2>Não existe histórico desse nível nessa data</h2>
        </div>
      )}
      <Bar options={options} data={data} />
    </GraficosTankes>
  );
};
