import { useState, useEffect, useContext, useCallback } from "react";
import { Line } from "react-chartjs-2";
import { SquareLoading } from "../../components/loaders";
import { PivoHistoric } from "../../styles/Pivo/pivo";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { PivoContext } from "../../contexts/contexPivos";
import { HomeContext } from "../../contexts/contexHome";
import { apiConnect } from "../../services/api";
import { PiCalendarBlankThin } from "react-icons/pi";
import { RiCloseCircleFill } from "react-icons/ri";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { format } from "date-fns";
import { FaFileDownload } from "react-icons/fa";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { ModalConfirmController } from "../../styles/modals";
import { IoIosArrowDropupCircle } from "react-icons/io";
import { SiPivotaltracker } from "react-icons/si";

export const PivoHistorico = ({ pivoComponente }) => {
  const { expandedPivos, setExpandedPivos } = useContext(PivoContext);
  const [selectedPivoId, setSelectedPivoId] = useState(null); // State to track selected pivo id

  // Function to toggle item expansion
  const toggleItemExpansion = (itemId) => {
    setExpandedPivos((prevExpandedItems) =>
      prevExpandedItems.includes(itemId)
        ? prevExpandedItems.filter((id) => id !== itemId)
        : [...prevExpandedItems, itemId]
    );
  };

  // Function to handle Pivo selection
  const handleSelect = (pivoId) => {
    setSelectedPivoId(pivoId === selectedPivoId ? null : pivoId);
  };

  return (
    <section id="pivosControllers">
      <ul className="ulConteiner">
        {pivoComponente.map((pivo) => {
          const isExpanded = expandedPivos.includes(pivo.id);
          const isSelected = pivo.id === selectedPivoId;

          return (
            <li
              key={pivo.id}
              className={`pivoConteiner ${isExpanded ? "expanded" : ""}`}
            >
              {isExpanded && (
                <IoIosArrowDropupCircle
                  className="buttonClose"
                  onClick={() => toggleItemExpansion(pivo.id)}
                />
              )}
              <div className="SelectPivo">
                <h3
                  className={`${isSelected ? "selected" : ""}`}
                  onClick={() => handleSelect(pivo.id)}
                >
                  Histórico
                </h3>
              </div>
              <div
                className="namePivo"
                onClick={() => toggleItemExpansion(pivo.id)}
              >
                <SiPivotaltracker className="iconePivo" />
                <h3>{pivo.name}</h3>
              </div>
              <section className="sectionHistoric">
                {isExpanded && <HistoricPivo selectedPivoId={pivo.id} />}
              </section>
            </li>
          );
        })}
      </ul>
    </section>
  );
};

// Componente para exibir o histórico de dados de um pivô
export const HistoricPivo = ({ selectedPivoId }) => {
  // Contexts para acessar estados globais
  const { headerApi, pivosList, convert4a20 } = useContext(PivoContext);
  const { attHistoricoPivo } = useContext(HomeContext);

  // Estados locais para gerenciar dados do histórico, data selecionada, etc.
  const [saveHistoricPivo, setSaveHistoricPivo] = useState([]);
  const [dateDay, setDateDay] = useState(new Date().getDate());
  const [calenderDate, setCalenderDate] = useState(new Date());
  const [modalCalender, setModalCalender] = useState(false);
  const [modalHistoricoComfimetion, setModalHistoricoComfimetion] =
    useState(false);

  // Encontra o nome do pivô selecionado na lista de pivôs
  const pivoName = pivosList.find((e) => e.id === selectedPivoId);

  // Função para gerar relatórios em PDF ou CSV
  const generateReport = (formatType) => {
    const tableColumn = [
      "Nome do Pivô",
      "Horário",
      "Data",
      "Pressão Centro",
      "Pressão Ponta",
      "Tensão R",
      "Tensão S",
      "Tensão T",
      "Corrente",
      "Status Água",
      "Status Pivo",
      "Sentido de Rotação",
    ];

    // Mapeia os dados do histórico para as linhas da tabela
    const tableRows = saveHistoricPivo.map((item) => [
      pivoName.name,
      item.horario,
      item.data,
      convert4a20(item.pressao_centro),
      convert4a20(item.pressao_ponta),
      item.tensao_r,
      item.tensao_s,
      item.tensao_t,
      item.corrente,
      item.status_agua ? "Sim" : "Não",
      item.status_pivo ? "Sim" : "Não",
      item.sentido_de_rotacao ? "Horário" : "Anti-Horário",
    ]);

    if (formatType === "pdf") {
      // Criar um documento PDF em orientação paisagem ('l' para landscape)
      const doc = new jsPDF("l");
      doc.autoTable({
        head: [tableColumn],
        body: tableRows,
        startY: 20,
        styles: {
          lineColor: [0, 0, 0], // Cor da linha, definida como preta
          lineWidth: 0.2, // Espessura da linha
        },
        columnStyles: {
          0: { cellWidth: "auto" }, // ajuste conforme necessário para outras colunas
        },
        theme: "grid", // Usar o tema 'grid' que inclui linhas verticais
      });
      doc.save(`Historico_Pivo_${format(new Date(), "yyyy-MM-dd")}.pdf`);
      setModalHistoricoComfimetion(false);
    } else if (formatType === "csv") {
      // Gerar o conteúdo CSV como string
      const headers = tableColumn.join(",") + "\n";
      const csvRows = tableRows.map((row) => row.join(",")).join("\n");
      const csvString = headers + csvRows;

      // Criar um blob para o CSV e iniciar o download
      const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.setAttribute("href", url);
      link.setAttribute(
        "download",
        `Historico_Pivo_${format(new Date(), "yyyy-MM-dd")}.csv`
      );
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setModalHistoricoComfimetion(false);
    }
  };

  // Função para buscar o histórico do pivô a partir da API
  const handleListHitoricPivo = useCallback(
    async (listdate) => {
      const formatData = format(calenderDate, "dd-MM-yyyy");
      try {
        const boolValue = JSON.parse(listdate);
        const url = boolValue
          ? `pivo/historicos/${selectedPivoId}/${formatData}`
          : `pivo/historicos/${selectedPivoId}`;

        const response = await apiConnect.get(url, headerApi);
        setSaveHistoricPivo(response.data);
      } catch (error) {
        // Suprimir mensagens de erro 404
        if (error.response && error.response.status === 404) {
          setSaveHistoricPivo([]);
        } else {
          console.error("Ocorreu um erro inesperado:", error);
        }
      }
    },
    [calenderDate, selectedPivoId, headerApi]
  ); // Adicione as dependências necessárias

  // Registro dos componentes necessários para o gráfico
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Filler,
    Title,
    Tooltip,
    Legend
  );

  // Configuração dos dados para o gráfico
  const dataPivo = {
    labels: saveHistoricPivo.map((data) => `${data.horario}`),
    datasets: [
      {
        label: "Pressão - Ponta do Pivô",
        data: saveHistoricPivo.map((data) => convert4a20(data.pressao_ponta)),
        backgroundColor: "#4bc0c00",
        borderColor: "#217d40",
        pointBackgroundColor: "#c6c6c6",
        color: "#ffffff",
        tension: 0.3,
      },
      {
        label: "Pressão - Centro do Pivô",
        data: saveHistoricPivo.map((data) => convert4a20(data.pressao_centro)),
        backgroundColor: "#ef07070",
        borderColor: "#3f8d93",
        pointBackgroundColor: "#c6c6c6",
        color: "#ffffff",
        tension: 0.3,
      },
    ],
  };

  // Configuração das opções do gráfico
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        ticks: {
          color: "#e7e0e0", // Cor do texto no eixo x
        },
      },
      y: {
        suggestedMin: 0,
        beginAtZero: true,
        ticks: {
          color: "#e7e0e0", // Cor do texto no eixo y
        },
      },
    },
    plugins: {
      legend: {
        position: "top",
        display: true,
        labels: {
          color: "#e7e0e0", // Cor do texto do rótulo do conjunto de dados
          font: {
            size: 18, // Tamanho da fonte do texto do eixo y
          },
        },
      },
    },
  };

  // Filtra o histórico do pivô para uma data específica

  const filterHistorico = async () => {
    const formatData = format(calenderDate, "dd-MM-yyyy");
    setDateDay(calenderDate.getDate());
    try {
      const response = await apiConnect(
        `pivo/historicos/${selectedPivoId}/${formatData}/`,
        headerApi
      );
      setSaveHistoricPivo(response.data);
    } catch (error) {
      if (
        error.response.data.message ===
        "Não foram encontrados históricos para o pivô e data especificados."
      ) {
        setSaveHistoricPivo([]);
      }
    } finally {
      setModalCalender(false);
      handleListHitoricPivo(true);
    }
  };

  // useEffect para buscar dados históricos quando o componente é montado
  useEffect(() => {
    handleListHitoricPivo(true);
  }, [handleListHitoricPivo]);

  // useEffect para atualizar os dados históricos sempre que attHistoricoPivo muda
  useEffect(() => {
    handleListHitoricPivo(true);
  }, [attHistoricoPivo, handleListHitoricPivo]);

  return (
    <PivoHistoric>
      {modalCalender && (
        <div className="calendarDiv">
          <span className="close" onClick={() => setModalCalender(false)}>
            <RiCloseCircleFill />
          </span>
          <h3>Seleciona uma data de histórico.</h3>
          <Calendar
            className="calendar"
            onChange={setCalenderDate}
            value={calenderDate}
            maxDate={new Date()}
          />
          <div className="buutons">
            <button onClick={() => filterHistorico()}>Pesquisar</button>
            <button onClick={() => setModalCalender(false)}>Cancelar</button>
          </div>
        </div>
      )}
      {modalHistoricoComfimetion && (
        <ModalConfirmController
          onClick={() => {
            handleListHitoricPivo(true);
            setModalHistoricoComfimetion(false);
          }}
        >
          <div
            className="deleteContact"
            onClick={(e) => {
              e.stopPropagation(); // Impede a propagação do evento de clique para os elementos pai
            }}
          >
            <h2>{`Qual formato deseja baixar o histórico do Pivô ?`}</h2>
            <div>
              <select
                name="selectDW"
                id="selectDW"
                className="selectDW"
                onChange={(e) => {
                  handleListHitoricPivo(e.target.value);
                }}
              >
                <option value={true}>Data Selecinada</option>
                <option value={false}>Historico Completo</option>
              </select>
              <button
                className="buttonYes"
                onClick={() => generateReport("pdf")}
              >
                Baixar em PDF
              </button>
              <button
                className="buttonYes"
                onClick={() => generateReport("csv")}
              >
                Baixar em CSV
              </button>
              <button
                onClick={() => {
                  handleListHitoricPivo(true);
                  setModalHistoricoComfimetion(false);
                }}
              >
                Cancelar
              </button>
            </div>
          </div>
        </ModalConfirmController>
      )}
      <div className="icon">
        <div className="conteinerIcon" onClick={() => setModalCalender(true)}>
          <PiCalendarBlankThin />
          <span>{dateDay}</span>
        </div>
        <FaFileDownload
          className="iconDw"
          onClick={() =>
            setModalHistoricoComfimetion(!modalHistoricoComfimetion)
          }
        />
      </div>
      {saveHistoricPivo.length === 0 && (
        <div className="not404">
          <span>Não existe histórico desse pivô nessa data.</span>
        </div>
      )}
      {saveHistoricPivo ? (
        <div className="graphContainer">
          <Line data={dataPivo} options={options} />
        </div>
      ) : (
        <SquareLoading />
      )}
    </PivoHistoric>
  );
};
