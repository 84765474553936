import React, { useContext, useEffect, useState } from "react";
import {
  ModalPrevisaoClima,
  PrevisaoStyled,
} from "../../styles/meteorologia/previsaoTempo";
import { ClimaContext } from "../../contexts/contexClima";
import { ClimaAtualStyled } from "../../styles/meteorologia/tempoAtual";
import { apiConnect } from "../../services/api";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LineElement,
  PointElement,
  CategoryScale,
  LinearScale,
  Tooltip,
} from "chart.js";
import zoomPlugin from "chartjs-plugin-zoom";
import { format, parseISO } from "date-fns";
import ChartDataLabels from "chartjs-plugin-datalabels";

const diasDaSemana = [
  { nome: "Seg.", chave: "previsaoSegunda" },
  { nome: "Ter.", chave: "previsaoTerca" },
  { nome: "Qua.", chave: "previsaoQuarta" },
  { nome: "Qui.", chave: "previsaoQuinta" },
  { nome: "Sex.", chave: "previsaoSexta" },
  { nome: "Sáb.", chave: "previsaoSabado" },
  { nome: "Dom.", chave: "previsaoDomingo" },
];

const obterIndiceDiaAtual = () => {
  const hoje = new Date().getDay(); // 0 (Dom) a 6 (Sáb)
  return hoje === 0 ? 6 : hoje - 1; // Ajusta para Segunda-feira como 0
};

export const PrevisaoDoTempo = () => {
  const {
    climaIdSelect,
    selecionarImagemClima,
    climaAtual,
    setModalPrevisao,
    listPrevisaoClima,
    setPrevisaoClima,
    diaPrevisto,
    setDiaPrevisto,
  } = useContext(ClimaContext);

  const [listPrevisoesClima, setListPrevisoesClima] = useState(null);
  const [tempPorDiaMin, setTempPorDiaMin] = useState({});
  const [tempPorDiaMax, setTempPorDiaMax] = useState({});
  const [descricaoMaisComumPorDia, setDescricaoMaisComumPorDia] = useState({});

  const listPrevisoes = async (idSelect) => {
    const token = sessionStorage.getItem("connectionsToken");

    const headerApi = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await apiConnect.get(
        `/meteorologia/previsao/Leitura/${idSelect}`,
        headerApi
      );

      setListPrevisoesClima(response.data);
    } catch (error) {
      console.log(error);
      setListPrevisoesClima([]);
    }
  };

  const calcularDescricaoMaisComumPorDia = (previsoes) => {
    const diasDaSemana = [
      "previsaoSegunda",
      "previsaoTerca",
      "previsaoQuarta",
      "previsaoQuinta",
      "previsaoSexta",
      "previsaoSabado",
      "previsaoDomingo",
    ];

    const descricaoMaisComum = {};

    diasDaSemana.forEach((dia) => {
      const previsoesDia = previsoes[dia] || [];
      const descricaoCount = {};

      previsoesDia.forEach((previsao) => {
        const descricao = previsao.description;
        descricaoCount[descricao] = (descricaoCount[descricao] || 0) + 1;
      });

      const descricaoMaisComumDia = Object.entries(descricaoCount).reduce(
        (acc, [descricao, frequencia]) =>
          frequencia > acc.frequencia ? { descricao, frequencia } : acc,
        { descricao: "", frequencia: 0 }
      ).descricao;

      descricaoMaisComum[dia] = descricaoMaisComumDia;
    });

    return descricaoMaisComum;
  };

  useEffect(() => {
    if (climaIdSelect) {
      listPrevisoes(climaIdSelect);
    }
  }, [climaIdSelect]);

  useEffect(() => {
    if (climaIdSelect) {
      listPrevisoes(climaIdSelect);
    }
  }, [climaIdSelect]);

  const obterDiasValidos = () => {
    const indiceDiaAtual = obterIndiceDiaAtual();
    const diasValidos = [
      ...diasDaSemana.slice(indiceDiaAtual),
      ...diasDaSemana.slice(0, indiceDiaAtual),
    ].slice(0, 5);

    return diasValidos;
  };

  useEffect(() => {
    if (listPrevisoesClima) {
      const tempPorDiaAtualizadoMin = {};
      const tempPorDiaMaxAtualizado = {};
      const descricaoPorDia =
        calcularDescricaoMaisComumPorDia(listPrevisoesClima);

      Object.keys(listPrevisoesClima).forEach((dia) => {
        const temperaturas =
          listPrevisoesClima[dia]?.map((previsao) => previsao.temp_min) || [];
        tempPorDiaAtualizadoMin[dia] = temperaturas;
      });

      Object.keys(listPrevisoesClima).forEach((dia) => {
        const temperaturasMax =
          listPrevisoesClima[dia]?.map((previsao) => previsao.temp_max) || [];
        tempPorDiaMaxAtualizado[dia] = temperaturasMax;
      });

      setTempPorDiaMin(tempPorDiaAtualizadoMin);
      setTempPorDiaMax(tempPorDiaMaxAtualizado);
      setDescricaoMaisComumPorDia(descricaoPorDia);
    }
  }, [listPrevisoesClima]);

  if (!climaAtual) {
    return (
      <ClimaAtualStyled>
        <div>Carregando previsão do tempo...</div>;
      </ClimaAtualStyled>
    );
  }
  const diaAtualChave = obterDiasValidos()[0]?.chave;

  const previsaoDoDia =
    listPrevisoesClima && diaAtualChave
      ? listPrevisoesClima[diaAtualChave]
      : [];

  return (
    <PrevisaoStyled>
      <div className="tituloPrevisao">
        <h2>Previsão do Tempo</h2>
      </div>
      <div className="diasSemanaConteiner">
        <ul className="diasSemana">
          {obterDiasValidos().map((dia) => {
            return (
              <li
                className="dia"
                key={dia.chave}
                onClick={() => {
                  setPrevisaoClima(listPrevisoesClima[dia.chave]);
                  setDiaPrevisto(dia.chave);
                  setModalPrevisao(true);
                }}
              >
                <div>
                  <span>Max. </span>
                  <span className="tempPre">
                    {tempPorDiaMax[dia.chave]?.length > 0 &&
                      Math.max(...tempPorDiaMax[dia.chave]).toFixed(0)}
                    °
                  </span>
                </div>
                <span className="Status">
                  {" "}
                  {descricaoMaisComumPorDia[dia.chave]}
                </span>
                <img
                  src={selecionarImagemClima(
                    descricaoMaisComumPorDia[dia.chave] || ""
                  )}
                  alt={dia.nome}
                />
                <div>
                  <span>Min. </span>
                  <span className="tempPre">
                    {tempPorDiaMin[dia.chave]?.length > 0 &&
                      Math.floor(Math.min(...tempPorDiaMin[dia.chave]))}
                    °
                  </span>
                </div>
                <span className="diaSemana">{dia.nome}</span>
              </li>
            );
          })}
        </ul>
      </div>
      <ul className="previsaoDoDia">
        {climaAtual && (
          <li className="nowPrev">
            <span>Agora</span>
            <span className="icon">
              <img
                src={selecionarImagemClima(
                  climaAtual.description || "",
                  new Date()
                )}
                alt={climaAtual.description}
              />
            </span>
            <span>{climaAtual.temp.toFixed(0)}°c</span>
          </li>
        )}
        {previsaoDoDia && previsaoDoDia.length > 0 ? (
          previsaoDoDia
            .sort((a, b) => {
              // Transformar forecast_data em números para comparação
              const [aHours, aMinutes] = a.forecast_data.split(":").map(Number);
              const [bHours, bMinutes] = b.forecast_data.split(":").map(Number);

              // Comparar as horas e minutos
              return aHours !== bHours ? aHours - bHours : aMinutes - bMinutes;
            })
            .map((tempoPrev) => (
              <li key={tempoPrev.id} className="diaPrevs">
                <span>{tempoPrev.forecast_data}</span>
                <span className="icon">
                  <img
                    src={selecionarImagemClima(
                      tempoPrev.description || "",
                      new Date().setHours(
                        ...tempoPrev.forecast_data.split(":").map(Number)
                      )
                    )}
                    alt={tempoPrev.description}
                  />
                </span>
                <span>{tempoPrev.temp.toFixed(0)}°</span>
              </li>
            ))
        ) : (
          <li>Nenhuma previsão disponível</li>
        )}
      </ul>
    </PrevisaoStyled>
  );
};

ChartJS.register(
  LineElement,
  PointElement,
  CategoryScale,
  Tooltip,
  ChartDataLabels,
  LinearScale,
  zoomPlugin
);

function useWindowWidth() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowWidth;
}

export const PrevisaoModal = () => {
  const {
    selecionarImagemClima,
    setModalPrevisao,
    listPrevisaoClima,
    setPrevisaoClima,
    diaPrevisto,
  } = useContext(ClimaContext);

  const windowWidth = useWindowWidth();

  const zoomOptions = {
    pan: {
      enabled: windowWidth >= 650,
      mode: "x",
    },
    zoom: {
      wheel: {
        enabled: windowWidth >= 650,
      },
      pinch: {
        enabled: windowWidth >= 650,
      },
      mode: "x",
    },
  };

  const [customImages, setCustomImages] = useState([]);

  useEffect(() => {
    const sortedList = [...listPrevisaoClima].sort((a, b) => {
      return a.forecast_data.localeCompare(b.forecast_data);
    });
    setPrevisaoClima(sortedList); // Atualiza o estado com a lista ordenada
  }, []);

  useEffect(() => {
    console.log(listPrevisaoClima);
    const imageSources = listPrevisaoClima.map((data) =>
      selecionarImagemClima(
        data.description,
        new Date().setHours(...data.forecast_data.split(":").map(Number))
      )
    );

    const images = [];

    imageSources.forEach((src, index) => {
      const img = new Image();
      img.src = src;
      img.onload = () => {
        images[index] = img;
        if (images.length === imageSources.length) {
          setCustomImages(images);
        }
      };
    });
  }, [listPrevisaoClima, selecionarImagemClima]);

  const data = {
    labels: listPrevisaoClima.map((horario) => horario.forecast_data),
    datasets: [
      {
        label: "Clima",
        data: listPrevisaoClima.map((climaData) => climaData.temp),
        borderColor: "#4a4a4a",
        fill: false,
        pointStyle: customImages, // Filtra as imagens também
        pointRadius: 10,
        pointHoverRadius: 15,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        ticks: {
          color: "#e7e0e0",
          font: {
            size: 11,
          },
        },
        grid: {
          color: "#61606097",
        },
        offset: true,
      },
      y: {
        min: 0,
        suggestedMax: 40,
        beginAtZero: true,
        ticks: {
          color: "#e7e0e0",
        },
        grid: {
          color: "#61606097",
        },
      },
    },
    elements: {
      line: {
        tension: 0.4,
      },
    },
    plugins: {
      legend: {
        position: "top",
        display: false,
        labels: {
          color: "#e7e0e0",
        },
      },
      tooltip: {
        enabled: true,
        callbacks: {
          label: function (context) {
            const value = context.raw;
            return `Temp: ${value}°C`;
          },
        },
      },
      datalabels: {
        display: true,
        align: "top",
        anchor: "end",
        formatter: (value) => `${value}°C`,
        font: {
          size: 12,
          weight: "bold",
        },
        color: "#fff",
      },
      zoom: zoomOptions,
    },
  };

  if (!customImages.length) {
    return <div>Loading...</div>; // Exibir um loading enquanto as imagens carregam
  }

  const diaSemanas = {
    previsaoSegunda: "Segunda-feira",
    previsaoTerca: "Terça-feira",
    previsaoQuarta: "Quarta-feira",
    previsaoQuinta: "Quinta-feira",
    previsaoSexta: "Sexta-feira",
    previsaoSabado: "Sábado",
    previsaoDomingo: "Domingo",
  };

  return (
    <ModalPrevisaoClima>
      <div className="conteiner">
        <span className="closeModal" onClick={() => setModalPrevisao(false)}>
          x
        </span>
        <h2>Previsão de {diaSemanas[diaPrevisto]}</h2>
        <div className="graficoPrevisao">
          <Line data={data} options={options} />
        </div>
      </div>
    </ModalPrevisaoClima>
  );
};
