import styled from "styled-components";
import imgBg from "../../assets/imgs-background/background1.png";
import { rotateAnimation } from "../Pivo/pivoControler";

export const TanqueStyled = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 25%;
  min-height: 100vh;
  min-height: 100vh;
  width: 100%;
  background-image: url(${imgBg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  .desativado {
    opacity: 40%;
    cursor: no-drop;
  }
  .sectionTanque {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    gap: 25%;
    width: 94%;
    height: fit-content;
    min-height: 95vh;
    margin-left: 60px;
    margin-top: 20px;

    background-color: rgba(0, 0, 0, 0.41);
    margin-bottom: 50px;
    border-radius: 5px;
    .avisos {
      position: absolute;
      top: 30px;
      right: 30px;
      font-size: 40px;
      color: rgba(126, 124, 125, 1);
      cursor: pointer;
      transition: 0.5s;
      z-index: 2;
    }
    .avisos:hover {
      color: rgba(180, 180, 180, 1);
    }
    .containerSuperior {
      display: flex;
      width: 100%;
      padding: 10px;
      height: 50vh;
      max-height: 50vh;
      position: relative;
      .close {
        position: absolute;
        top: 15px;
        font-size: 35px;
        left: 66%;
        color: rgba(143, 143, 143, 0.8);
        cursor: pointer;
        transition: 0.5s;
        z-index: 10;
      }
      .close:hover {
        color: rgba(193, 193, 193, 0.8);
      }
      .graficos {
        width: 70%;
        /* height: 100%; */
        border-bottom: 2px solid rgba(165, 165, 165, 0.8);
        border-right: 2px solid rgba(165, 165, 165, 0.8);
      }
      .containerAlertas {
        width: 30%;
        /* height: 100%; */
        max-height: 50vh;
        /* overflow: auto; */
        border-bottom: 2px solid rgba(165, 165, 165, 0.8);
        position: relative;
        .alertas {
          width: 100%;
          height: 50%;
          max-height: 60%;
          color: rgba(187, 187, 187, 0.8);
          h4 {
            display: flex;
            justify-content: center;
            width: 100%;
            align-items: center;
            margin-top: 10px;
            font-size: 22px;
            color: rgba(170, 170, 170, 1);
          }

          div {
            width: 100%;
            height: 100%;
            max-height: 100%;
            flex-grow: 1; /* Faz com que o div ocupe todo o espaço disponível na altura */
            display: flex;
            flex-direction: column;
            gap: 5px;
            padding: 15px;
            /* background: red; */
            overflow-y: auto; /* Adicionado para habilitar a rolagem vertical se o conteúdo for maior do que o espaço disponível */
            span {
              border-bottom: 2px solid rgba(109, 109, 109, 0.74);
            }
          }
        }
        .buttons {
          width: 100%;
          height: 45%;
          max-height: 50%;
          display: flex;
          flex-direction: column;
          justify-content: end;
          padding: 5px;
          gap: 10px;
          max-height: 100%;
          button {
            height: 60px;
            font-size: 20px;
            font-weight: 600;
            max-height: 100%;
            background-color: var(--bgBotao);
            color: var(--bgBranco);
            border-radius: 10px;
            border: 2px solid rgba(0, 0, 0, 0);
          }
          .btnStatus {
            cursor: pointer;
          }
          .btnStatus:hover {
            border: 2px solid var(--bgBranco);
          }
        }
      }
    }
    .containerInferior {
      width: 100%;
      padding: 15px;
      ul {
        width: 100%;
        display: flex;
        gap: 25px;
        list-style: none;
        flex-wrap: wrap;
        li {
          display: flex;
          gap: 15px;
          align-items: center;
          max-width: 100%;
          h3 {
            border: 2px solid rgba(78, 78, 78, 1);
            background-color: rgba(195, 195, 195, 0.8);
            color: rgba(58, 58, 58, 1);
            padding: 10px;
            border-radius: 5px;
            cursor: pointer;
          }
          display: flex;
          flex-direction: column;
          .containerInfos {
            display: flex;
            gap: 20px;
            /* width: 200px; */
            max-width: 100%;
            background: rgba(0, 0, 0, 0.5);
            background: -moz-linear-gradient(
              0deg,
              rgba(140, 140, 140, 0.6) 0%,
              rgba(237, 237, 237, 0.6) 100%
            );
            background: -webkit-linear-gradient(
              0deg,
              rgba(140, 140, 140, 0.6) 0%,
              rgba(237, 237, 237, 0.6) 100%
            );
            background: linear-gradient(
              0deg,
              rgba(140, 140, 140, 0.6) 0%,
              rgba(237, 237, 237, 0.6) 100%
            );

            padding: 15px;
            border: 2px solid rgba(57, 57, 57, 1);
            border-radius: 4px;
          }
          .infos {
            display: flex;
            flex-direction: column;
            gap: 10px;
            .info {
              width: 100%;
              display: flex;
              justify-content: space-between;
              gap: 10px;
              font-weight: 600;
              font-size: 18px;
              span {
                background: rgba(218, 218, 218, 1);
                padding: 2px 25px;
                border: 1px solid rgba(47, 47, 47, 1);
                border-radius: 5px;
              }
            }
          }
          .conteinerStatus {
            display: flex;
            flex-direction: column;

            /* background-color: red; */

            .status {
              display: flex;
              align-items: center;
              height: 100%;
              gap: 10px;
              font-weight: 600;
              .Bombas {
                display: flex;
                flex-direction: column;
                gap: 5px;

                span {
                  background: rgba(218, 218, 218, 1);
                  padding: 5px 25px;
                  border: 1px solid rgba(47, 47, 47, 1);
                  border-radius: 5px;
                  font-weight: 600;
                  max-width: 200px;
                }
                button {
                  display: flex;
                  align-items: end;
                  justify-content: center;
                  gap: 5px;
                  background-color: rgba(69, 71, 70, 0.1);
                  border: 1px solid rgba(47, 47, 47, 1);
                  border-radius: 10px;
                  padding: 5px 25px;
                  font-weight: 600;
                  color: rgba(36, 36, 36, 1);
                  cursor: pointer;
                  font-weight: 600;
                  .icon {
                    font-size: 16px;
                  }
                }
                .butonOff {
                  cursor: not-allowed;
                  opacity: 40%;
                  .icon {
                    animation: ${rotateAnimation} 2s linear infinite;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  @media (max-width: 650px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: end;
    padding: 10px;
    .sectionTanque {
      width: 100%;
      margin-left: 0px;
      margin-top: 5px;
      .containerSuperior {
        .graficos {
          width: 100%;
        }
        .containerAlertas {
          display: none;
        }
      }

      .containerInferior {
        ul {
          justify-content: center;
        }
      }
      .containerInfos {
        flex-wrap: wrap;
        .conteinerStatus {
          gap: 20px;
        }
      }
    }
  }
`;
