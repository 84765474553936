import { LoadingSquare } from "../styles/loading";
import logo from "../assets/logos/icon_aclsA.png";

export const SquareLoading = () => {
  return (
    <LoadingSquare className="notPri">
      <img src={logo} alt="" />
      <span className="loaderSquare">
        <span className="loaderSquare-inner"></span>
      </span>
      <h3>Loading...</h3>
    </LoadingSquare>
  );
};
