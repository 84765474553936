import axios from "axios";

export const apiConnect = axios.create({
  baseURL: "https://aclsconnect.com/67ZlfPVt/",
  timeout: 8000,
});

export const urlWebSockt = "https://aclsconnect.com";

// Api Local
// export const apiConnect = axios.create({
//   baseURL: "http://192.168.1.109:8000/67ZlfPVt/",
//   timeout: 8000,
// });

// local;
// export const urlWebSockt = "http://192.168.1.109:8000";
