import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IoIosArrowDropdown, IoIosArrowDropup } from "react-icons/io";
import { IoExit, IoHome } from "react-icons/io5";
import { RiAdminFill } from "react-icons/ri";
import { HomeContext } from "../contexts/contexHome";
import { HeaderMobileStyled } from "../styles/header";
import { FaCloudSun } from "react-icons/fa";
import { TbEngine } from "react-icons/tb";
import { FaCow } from "react-icons/fa6";
import imgPump from "../assets/icons/pumpWite.png";
import imgTanque from "../assets/icons/tanquesWhite.png";
import imgPivo from "../assets/icons/pivot2.png";
import imgAgua from "../assets/icons/aguaBranca.png";
import imgAguaSuja from "../assets/icons/aguaSujaIcon.png";
import { apiConnect } from "../services/api";
import iaAClS from "../assets/icons/ia.png";

export const HeaderMobile = () => {
  const { user } = useContext(HomeContext);
  const navigate = useNavigate();

  const [Unidades, setUnidades] = useState({});
  const [barControler, setBarControler] = useState(false);
  const [loading, setloading] = useState(false);

  const unityExists = sessionStorage.getItem("connectionsUnit");
  const ListUnidade = [
    {
      titulo: "Pivô",
      status: true,
      navigate: `/${unityExists}/Painelpivos/Map`,
      img: imgPivo,
      icon: false,
    },
    {
      titulo: "Estações Meteorológicas",
      status: true,
      navigate: `/${unityExists}/clima`,
      img: <FaCloudSun className="icon" />,
      icon: true,
    },
    {
      titulo: "Tanques",
      status: true,
      navigate: `/${unityExists}/tanques`,
      img: imgTanque,
      icon: false,
    },
    {
      titulo: "Motobombas",
      status: false,
      navigate: `/${unityExists}/`,
      img: imgPump,
      icon: false,
    },
    {
      titulo: "Eletrobombas",
      status: false,
      navigate: `/${unityExists}/`,
      icon: true,
      img: <TbEngine className="icon" />,
    },
    {
      titulo: "Reservatório de Água Limpa",
      status: false,
      navigate: `/${unityExists}/`,
      img: imgAgua,
      icon: false,
    },
    {
      titulo: "Reservatório de Água Suja",
      status: false,
      navigate: `/${unityExists}/`,
      img: imgAguaSuja,
      icon: false,
    },
    {
      titulo: "Biodigestor",
      status: false,
      navigate: `/${unityExists}/`,
      icon: true,
      img: <FaCow className="icon" />,
    },
    {
      titulo: "Acls IA",
      status: false,
      navigate: `/${unityExists}/AclsIA`,
      icon: false,
      img: iaAClS,
    },
  ];

  const ListUnidades = async () => {
    setloading(true);
    try {
      const token = sessionStorage.getItem("connectionsToken");
      const idUnidade = sessionStorage.getItem("connectionsUnitId");

      const headerApi = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await apiConnect.get(
        `unidade/leitura/${idUnidade}`,
        headerApi
      );
      setUnidades(response.data);
      let locUnidade = {
        lat: response.data.latitude,
        long: response.data.longitude,
      };
      sessionStorage.setItem(
        "connectionsUnitIdloc",
        JSON.stringify(locUnidade)
      );
    } catch (error) {
    } finally {
      setloading(false);
    }
  };
  useEffect(() => {
    ListUnidades();
  }, []);

  return (
    <HeaderMobileStyled>
      {barControler && (
        <section className="additionalSection">
          <div className="sectionBottom">
            {user.is_superuser ? (
              <div className="admin">
                <RiAdminFill
                  onClick={() => {
                    navigate("/admin");
                  }}
                />
                <span>Admin</span>
              </div>
            ) : null}
          </div>
        </section>
      )}
      <section className="fistSection">
        {/* <ul>


          <li
            onClick={() => {
              navigate("/");
            }}
          >
            <IoHome className="iconHome" />
          </li>
          <li
            className={`${pivoPage === "Map" ? "select" : ""}`}
            onClick={() => {
              navigate(`/${unityExists}/Painelpivos/Map`);
              setPivoPage("Map");
              setBarControler(false);
            }}
          >
            <BsPinMap />
          </li>
          <li
            className={`${pivoPage === "Controler" ? "select" : ""}`}
            onClick={() => {
              setBarControler(false);
              setPivoPage("Controler");
            }}
          >
            <AiTwotoneControl />
          </li>
          <li
            className={`${pivoPage === "Alertas" ? "select" : ""}`}
            onClick={() => {
              setBarControler(false);
              setModalAlertaOn(!modalAlertaOn);
            }}
          >
            {alertsConts && alertsConts.count > 0 && (
              <div className="alerts">{alertsConts.count}</div>
            )}
            <IoNotifications />
          </li>
        </ul> */}

        <ul>
          <li
            onClick={() => {
              navigate("/");
            }}
          >
            <IoHome className="iconHome" />
            <span>Home</span>
          </li>
          {ListUnidade.map((List, index) => {
            const aplicacaoContratada =
              Unidades &&
              Unidades.aplicacoes &&
              Unidades.aplicacoes[index] === "true";
            return (
              aplicacaoContratada && (
                <li
                  key={index}
                  onClick={() => {
                    navigate(List.navigate);
                  }}
                >
                  {/* <SiPivotaltracker /> */}
                  {List.icon ? List.img : <img src={List.img} alt="" />}
                  <span>{List.titulo}</span>
                </li>
              )
            );
          })}
        </ul>
        <div className="sectionBottom">
          {barControler ? (
            <IoIosArrowDropdown
              onClick={() => setBarControler(!barControler)}
            />
          ) : (
            <IoIosArrowDropup onClick={() => setBarControler(!barControler)} />
          )}
          <div className="exit">
            <IoExit
              onClick={() => {
                sessionStorage.removeItem("connectionsToken");
                sessionStorage.removeItem("connectionsUnit");
                navigate("/Login");
              }}
            />
            <span>Sair</span>
          </div>
        </div>
      </section>
    </HeaderMobileStyled>
  );
};
