import React, { createContext, useContext, useEffect, useState } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { apiConnect } from "../services/api";
import { HomeContext } from "./contexHome";

// Criação do contexto
export const PivoContext = createContext({});

// Provedor do contexto
export const PivosProvider = () => {
  const { location, socket } = useContext(HomeContext);
  // Hooks de estado e outras variáveis
  const token = sessionStorage.getItem("connectionsToken");
  const locateFavorite = localStorage.getItem("connectionLocateFav");
  let locateUnidade = sessionStorage.getItem("connectionsUnitIdloc");
  locateUnidade = JSON.parse(locateUnidade);
  const { section } = useParams();
  const navigate = useNavigate();

  const { unidade } = useParams();

  useEffect(() => {
    const unityExists = sessionStorage.getItem("connectionsUnit");
    if (unityExists !== unidade) {
      navigate(`/${unidade}`);
    }
  }, []);

  if (!locateUnidade) {
    navigate("/");
  }

  let longitudeFav = {
    longitude: locateUnidade.long,
    latitude: locateUnidade.lat,
  };

  if (locateFavorite !== null) {
    const idUnidade = sessionStorage.getItem("connectionsUnitId");
    let Salvee = JSON.parse(locateFavorite);
    if (Salvee.unidade === idUnidade) {
      longitudeFav.longitude = Salvee.longitude;
      longitudeFav.latitude = Salvee.latitude;
    }
  }

  const validSections = [
    "Map",
    "Historico",
    "Controler",
    "Agenda",
    "Configuracao",
  ];

  if (!validSections.includes(section)) {
    navigate("/cerro/Painelpivos/Map"); // Atualize a URL para refletir a seção padrão
    setTimeout(() => {
      window.location.reload();
    }, 500);
  }

  const [pivosList, setPivosList] = useState(null);
  const [pivosMarker, setPivoMarker] = useState([]);
  const [menuPivoSelect, setMenuPivoSelect] = useState([]);
  const [listPivosControlles, setPivosControllers] = useState([]);
  const [listPivosData, setPivosData] = useState([]);
  const [listBombsData, setBombsData] = useState([]);
  const [listMotoBombaData, setListMotoBombaData] = useState([]);
  const [lisBombstControlles, setBombsControllers] = useState([]);
  const [lisMotoBombstControlles, setMotoBombsControllers] = useState([]);
  const [openModalConfimBomba, setOpenModalConfimBomba] = useState(false);
  const [openModalConfimPivo, setOpenModalConfimPivo] = useState(false);
  const [openModalConfimPivoComando, setOpenModalConfimPivoComando] =
    useState(false);
  const [statusPvButton, setStatusPvButton] = useState(false);
  const [percetimetros, setPercetimetros] = useState({});
  const [rpms, setRpms] = useState({});
  const [rpmsMotoBomba, setRpmsMotoBomba] = useState({});
  const [infContolersPivoFuntion, setInfContolersPivoFuntion] = useState([]);
  const [infContolersbombFuntion, setInfContolersbombFuntion] = useState([]);
  const [menuMap, setMenuMap] = useState(false);
  const [mapConfigOn, setMapConfigOn] = useState(false);
  const [moveMapOn, setMoveMapOn] = useState(false);
  const [onPumpSaveFuntion, setonPumpSaveList] = useState([]);
  const [onMotoBombaSaveFuntion, setOnMotoBombaSaveFuntion] = useState([]);
  const [modalDefesivoOn, setModalDefesivoOn] = useState(false);
  const [mapStyle, setMapStyle] = useState("hybrid");
  const [scaleValue, setScaleValue] = useState(1);
  const [climatePivoSave, setClimatePivoSave] = useState({});
  const [pivoPage, setPivoPage] = useState(section);
  const [expandedPivos, setExpandedPivos] = useState([]);
  const [cameraState, setCameraState] = useState({
    center: {
      lat: longitudeFav.latitude,
      lng: longitudeFav.longitude,
    },
    zoom: 15,
    heading: 0,
    tilt: 0,
  });
  const [modalAlertaOn, setModalAlertaOn] = useState(false);
  const [alertsConts, setAlertsConts] = useState({});
  const [modalPersonalizadoOn, setModalPersonalizadoOn] = useState(false);
  const [selectedTime, setSelectedTime] = useState("Uma vez");
  const [diasPersonalizados, setDiasPersonalizados] = useState([]);
  const [modalDeleteAgedamento, setModalDeleteAgedamento] = useState(false);
  const [idAgendamento, setIdAgendamento] = useState(0);
  const [modalTaxaVariable, setModalTaxaVariable] = useState(false);
  const [map3dOn, setMap3dOn] = useState(false);
  const [mapSun, setMapSun] = useState(false);
  const [checkingPermission, setCheckingPermission] = useState(true);
  const [valueSun, setValueSun] = useState(720);
  const [ModalActions, setModalActions] = useState(false);
  const [ConfimeactionHorarioPico, setConfimeactionHorarioPico] =
    useState(false);
  const [ModalAgedarDeligamento, setModalDesligarAgedamento] = useState(false);

  // Contador de tentativas para chamadas assíncronas
  let retryCount = 0;
  const maxRetries = 60;

  // Cabeçalho com token para as chamadas à API
  const headerApi = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  // Função para lidar com a listagem de pivos

  const handleListPivos = async () => {
    try {
      const response = await apiConnect.get("pivos/", headerApi);
      const idUnidade = sessionStorage.getItem("connectionsUnitId");
      const filterPivo = response.data.filter(
        (e) => e.unidade.id === Number(idUnidade)
      );
      setPivosList(filterPivo);
      setPivoMarker(filterPivo);
    } catch (error) {
    } finally {
      // Any cleanup or final steps can go here
    }
  };

  // Função para lidar com a listagem de controladores de pivos
  const handleListPivosControllers = async () => {
    try {
      const response = await apiConnect.get(
        "controle/pivo/LeituraTodos/",
        headerApi
      );
      setPivosControllers(response.data);
    } catch (error) {
    } finally {
    }
  };

  // Função para lidar com a listagem de dados dos pivos
  const handleListPivosData = async () => {
    try {
      const response = await apiConnect.get(
        "dados/pivo/LeituraTodos/",
        headerApi
      );
      const newData = response.data;
      setPivosData(newData);
    } catch (error) {
    } finally {
    }
  };

  // Função para lidar com a listagem de controladores de bombas
  const handleListBombsControllers = async () => {
    try {
      const response = await apiConnect.get(
        "controle/eletrobomba/LeituraTodos/",
        headerApi
      );
      setBombsControllers(response.data);
    } catch (error) {
    } finally {
    }
  };

  const handleListMotoBombsControllers = async () => {
    try {
      const response = await apiConnect.get(
        "controle/motobomba/LeituraTodos/",
        headerApi
      );
      setMotoBombsControllers(response.data);
    } catch (error) {
    } finally {
    }
  };

  // Função para lidar com o clique no marcador do mapa

  const locateMap = (lat, lng, tilt, zoom, id) => {
    if (id === "all") {
      setPivoMarker(pivosList);
      setMenuPivoSelect([]);
      setMapConfigOn(false);
      setMoveMapOn(false);
      setCameraState({
        center: {
          lat: lat,
          lng: lng,
        },
        zoom: zoom,
        heading: 0,
        tilt: tilt,
      });
      setMenuMap(false);
    } else if (id === "user") {
      setMenuPivoSelect([]);
      setMapConfigOn(false);
      setMoveMapOn(false);
      const startLat = cameraState.center.lat;
      const startLng = cameraState.center.lng;
      const startZoom = cameraState.zoom;
      const intermediateZoom = 10; // Zoom intermediário para a animação
      const steps = 100; // Número de passos para a animação
      const interval = 10; // Intervalo em milissegundos entre cada passo

      // Função auxiliar para criar a animação
      const animate = (startValue, endValue, steps, onStep, onComplete) => {
        let currentStep = 0;
        const intervalId = setInterval(() => {
          if (currentStep < steps) {
            const progress = currentStep / steps;
            const interpolatedValue = {
              lat: startValue.lat + (endValue.lat - startValue.lat) * progress,
              lng: startValue.lng + (endValue.lng - startValue.lng) * progress,
              zoom:
                startValue.zoom + (endValue.zoom - startValue.zoom) * progress,
              tilt:
                startValue.tilt + (endValue.tilt - startValue.tilt) * progress,
            };
            onStep(interpolatedValue, progress);
            currentStep++;
          } else {
            clearInterval(intervalId);
            onComplete();
          }
        }, interval);
      };

      const startCameraState = {
        lat: startLat,
        lng: startLng,
        zoom: startZoom,
        tilt: 0,
      };

      const intermediateCameraState = {
        lat: startLat,
        lng: startLng,
        zoom: intermediateZoom,
        tilt: 0,
      };

      const finalCameraState = {
        lat: lat,
        lng: lng,
        zoom: zoom,
        tilt: tilt,
      };

      // Se o zoom inicial for maior que 10, fazer a animação de redução do zoom primeiro
      if (startZoom > 10) {
        animate(
          startCameraState,
          intermediateCameraState,
          steps,
          (interpolatedValue) => {
            setCameraState({
              center: {
                lat: interpolatedValue.lat,
                lng: interpolatedValue.lng,
              },
              zoom: interpolatedValue.zoom,
              heading: 0,
              tilt: interpolatedValue.tilt,
            });
          },
          () => {
            // Etapa 2: Mover para a nova localização
            animate(
              intermediateCameraState,
              { ...finalCameraState, zoom: intermediateZoom },
              steps,
              (interpolatedValue) => {
                setCameraState({
                  center: {
                    lat: interpolatedValue.lat,
                    lng: interpolatedValue.lng,
                  },
                  zoom: interpolatedValue.zoom,
                  heading: 0,
                  tilt: interpolatedValue.tilt,
                });
              },
              () => {
                // Etapa 3: Ajustar o zoom e tilt para os valores finais
                animate(
                  { ...finalCameraState, zoom: intermediateZoom },
                  finalCameraState,
                  steps,
                  (interpolatedValue) => {
                    setCameraState({
                      center: {
                        lat: interpolatedValue.lat,
                        lng: interpolatedValue.lng,
                      },
                      zoom: interpolatedValue.zoom,
                      heading: 0,
                      tilt: interpolatedValue.tilt,
                    });
                  },
                  () => {
                    // Animação completa
                    setCameraState({
                      center: {
                        lat: lat,
                        lng: lng,
                      },
                      zoom: zoom,
                      heading: 0,
                      tilt: tilt,
                    });
                  }
                );
              }
            );
          }
        );
      } else {
        // Se o zoom inicial for menor ou igual a 10, ir direto para a nova localização antes de ajustar o zoom
        animate(
          startCameraState,
          { ...finalCameraState, zoom: startZoom },
          steps,
          (interpolatedValue) => {
            setCameraState({
              center: {
                lat: interpolatedValue.lat,
                lng: interpolatedValue.lng,
              },
              zoom: interpolatedValue.zoom,
              heading: 0,
              tilt: interpolatedValue.tilt,
            });
          },
          () => {
            // Ajustar o zoom e tilt para os valores finais
            animate(
              { ...finalCameraState, zoom: startZoom },
              finalCameraState,
              steps,
              (interpolatedValue) => {
                setCameraState({
                  center: {
                    lat: interpolatedValue.lat,
                    lng: interpolatedValue.lng,
                  },
                  zoom: interpolatedValue.zoom,
                  heading: 0,
                  tilt: interpolatedValue.tilt,
                });
              },
              () => {
                // Animação completa
                setCameraState({
                  center: {
                    lat: lat,
                    lng: lng,
                  },
                  zoom: zoom,
                  heading: 0,
                  tilt: tilt,
                });
              }
            );
          }
        );
      }
    } else {
      if (!mapConfigOn) {
        const newMarker = pivosList.filter((e) => e.id === id);
        setPivoMarker(newMarker);
        setMenuPivoSelect(newMarker);
        setMapConfigOn(true);
        setMoveMapOn(true);

        let currentTilt = 0;
        const increment = 1; // Ajuste o incremento conforme necessário
        const interval = 30; // Ajuste o intervalo em milissegundos conforme necessário

        const tiltInterval = setInterval(() => {
          if (currentTilt < tilt) {
            currentTilt += increment;
            setCameraState({
              center: {
                lat: lat,
                lng: lng,
              },
              zoom: zoom,
              heading: 0,
              tilt: currentTilt,
            });
          } else {
            clearInterval(tiltInterval);
            setCameraState({
              center: {
                lat: lat,
                lng: lng,
              },
              zoom: zoom,
              heading: 0,
              tilt: tilt,
            });
          }
        }, interval);

        setMenuMap(false);
      }
    }
  };

  // Função para atualizar o controlador de pivo
  const handleUpdateControllerPivo = async (id, data) => {
    try {
      const response = await apiConnect.patch(
        `controle/pivo/${id}/`,
        data,
        headerApi
      );
      setPivosControllers((prevList) => {
        return prevList.map((item) =>
          item.id === id ? { ...item, ...response.data } : item
        );
      });
      toast.success("Comando Feito com sucesso", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    } catch (error) {
      toast.warn("Falha", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    } finally {
      setOpenModalConfimPivo(false);
    }
  };

  // Função para atualizar o controlador de bomba
  const handleUpdateControllerBomb = async (id, data) => {
    try {
      const response = await apiConnect.patch(
        `controle/eletrobomba/${id}/`,
        data,
        headerApi
      );
      setBombsControllers((prevList) => {
        return prevList.map((item) =>
          item.id === id ? { ...item, ...response.data } : item
        );
      });
      toast.success("Comando Feito com sucesso", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    } catch (error) {
      toast.warn("Falha", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    } finally {
      setOpenModalConfimBomba(false);
    }
  };

  const handleUpdateControllerMotoBomb = async (id, data) => {
    try {
      const response = await apiConnect.patch(
        `controle/motobomba/${id}/`,
        data,
        headerApi
      );
      setMotoBombsControllers((prevList) => {
        return prevList.map((item) =>
          item.id === id ? { ...item, ...response.data } : item
        );
      });
      toast.success("Comando Feito com sucesso", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    } catch (error) {
      toast.warn("Falha", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    } finally {
      setOpenModalConfimBomba(false);
    }
  };

  // Função para lidar com a listagem de dados das bombas
  const handleListBombsData = async () => {
    try {
      const response = await apiConnect.get(
        "dados/eletrobomba/LeituraTodos/",
        headerApi
      );
      setBombsData(response.data);
    } catch (error) {
    } finally {
    }
  };

  // Função para lidar com a listagem de dados das Motobombas
  const handleListotoBombsData = async () => {
    try {
      const response = await apiConnect.get(
        "dados/motobomba/LeituraTodos/",
        headerApi
      );
      setListMotoBombaData(response.data);
    } catch (error) {
    } finally {
    }
  };

  // Função para ligar/desligar a bomba
  const onPump = async () => {
    try {
      const response = await apiConnect.get(
        `dados/eletrobomba/${onPumpSaveFuntion[1]}/`,
        headerApi
      );
      if (response.data.status_bomba1 !== onPumpSaveFuntion[0]) {
        // Se o status for diferente, atualiza o estado
        const NewList = await apiConnect.get(
          "dados/eletrobomba/LeituraTodos/",
          headerApi
        );
        setBombsData(NewList.data);
        if (response.data.status_bomba1) {
          toast.success("Bomba Ligada com Sucesso", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        }
        if (!response.data.status_bomba1) {
          toast.success("Bomba Desligado com Sucesso", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        }
        retryCount(0); // Reseta a contagem de tentativas
      } else {
        // Se o status ainda é o mesmo, faz outra tentativa (se não atingir o limite)
        if (retryCount < maxRetries) {
          // Adiciona um atraso de 2 segundos antes de fazer outra tentativa
          if (location.pathname === "/unidade1/Painelpivos") {
            setTimeout(() => {
              retryCount = retryCount + 1;
              onPump(); // Chama a função novamente
            }, 2000);
          }
        } else {
          toast.error("Falha comando não executado com sucesso", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
          retryCount(0);
        }
      }
    } catch (error) {
      // Lida com o erro, se necessário
      toast.warn("Falha", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  const onMotoBomba = async () => {
    try {
      const response = await apiConnect.get(
        `dados/motobomba/Leitura/${onMotoBombaSaveFuntion[1]}/`,
        headerApi
      );
      if (response.data.status_bomba !== onMotoBombaSaveFuntion[0]) {
        // Se o status for diferente, atualiza o estado
        const NewList = await apiConnect.get(
          "dados/motobomba/LeituraTodos/",
          headerApi
        );
        setListMotoBombaData(NewList.data);
        if (response.data.status_bomba) {
          toast.success("Bomba Ligada com Sucesso", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        }
        if (!response.data.status_bomba) {
          toast.success("Bomba Desligado com Sucesso", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        }
        retryCount(0); // Reseta a contagem de tentativas
      } else {
        // Se o status ainda é o mesmo, faz outra tentativa (se não atingir o limite)
        if (retryCount < maxRetries) {
          // Adiciona um atraso de 2 segundos antes de fazer outra tentativa
          if (location.pathname === "/unidade1/Painelpivos") {
            setTimeout(() => {
              retryCount = retryCount + 1;
              onMotoBomba(); // Chama a função novamente
            }, 2000);
          }
        } else {
          toast.error("Falha comando não executado com sucesso", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
          retryCount(0);
        }
      }
    } catch (error) {
      // Lida com o erro, se necessário
    }
  };

  const handleClimatePivo = async () => {
    try {
      const response = await apiConnect.get(
        `meteorologia/atual/LeituraTodos`,
        headerApi
      );
      setClimatePivoSave(response.data[0]);
    } catch (error) {}
  };

  const handleListAletas = async () => {
    try {
      const response = await apiConnect.get(
        "pivo/alertas/?lido=false",
        headerApi
      );
      setAlertsConts(response.data);
    } catch (error) {
    } finally {
    }
  };

  const convert4a20 = (ma) => {
    // Poder ser convertido Para qualquer Um ATUAL: Bar
    const minMa = 3.8;
    const maxMa = 20.2;
    const minPsi = 0;
    const maxPsi = 10;

    // Normaliza o valor de mA para um intervalo de 0-1
    const normalized = (ma - minMa) / (maxMa - minMa);

    // Converte o valor normalizado para o intervalo de psi
    let psi = normalized * (maxPsi - minPsi) + minPsi;

    // Formata para uma casa decimal e converte para número
    if (psi < 0) {
      psi = 0;
    }
    return Number(psi.toFixed(1));
  };

  const getLocationPermission = () => {
    return new Promise((resolve, reject) => {
      if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            resolve({
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            });
          },
          (err) => {
            reject(err);
          }
        );
      } else {
        reject(new Error("Geolocation not supported"));
      }
    });
  };

  // Efeito para executar as chamadas iniciais
  useEffect(() => {
    handleListPivos();
    handleListPivosControllers();
    handleListBombsControllers();
    handleListMotoBombsControllers();
    handleListPivosData();
    handleListBombsData();
    handleListAletas();
    handleListotoBombsData();
    handleClimatePivo();

    const checkLocationPermission = async () => {
      try {
        const location = await getLocationPermission();
        setCheckingPermission(false); // Permissão concedida
      } catch (err) {
        console.log("Sem permissão LOC");
        setTimeout(checkLocationPermission, 5000); // Tenta novamente em 5 segundos
      }
    };

    checkLocationPermission();
  }, []);

  //Funçoes Websocket
  useEffect(() => {
    if (socket) {
      // Sockets Do PivoDados

      socket.on("pivo_dados_edit", (dadosPivos) => {
        handleListPivosData();
      });

      // Sockets Do PivoControler

      socket.on("pivo_controler_edit", (controlerPivos) => {
        if (
          controlerPivos.agua ||
          controlerPivos.pivo_irrigando ||
          controlerPivos.pivo_seco ||
          controlerPivos.sentido_de_rotacao ||
          controlerPivos.defensivo
        ) {
          handleListPivosControllers();
        } else {
          setTimeout(() => {
            handleListPivosControllers();
          }, 100);
        }
      });

      socket.on("pivo_controler_erro", (controlerPivos) => {
        if (controlerPivos === "pivo_seco") {
          const dataComando = {
            comando: false,
          };
          setPivosControllers((pivoControlerSave) =>
            pivoControlerSave.map((pivoControler) =>
              pivoControler.id === controlerPivos.id
                ? { ...pivoControler, ...dataComando }
                : pivoControler
            )
          );
          toast.error("Falha ao ligar Pivô", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        }
        if (controlerPivos === "agua") {
          const dataComando = {
            agua: false,
          };
          setPivosControllers((pivoControlerSave) =>
            pivoControlerSave.map((pivoControler) =>
              pivoControler.id === controlerPivos.id
                ? { ...pivoControler, ...dataComando }
                : pivoControler
            )
          );
          toast.error("Falha ao ligar a Irrigação", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        }
        if (controlerPivos === "sentido_de_rotacao") {
          const dataComando = {
            sentido_de_rotacao: false,
          };
          setPivosControllers((pivoControlerSave) =>
            pivoControlerSave.map((pivoControler) =>
              pivoControler.id === controlerPivos.id
                ? { ...pivoControler, ...dataComando }
                : pivoControler
            )
          );
          toast.error("Falha ao mudar o sentido de rotação", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        }
        if (controlerPivos === "defensivo") {
          const dataComando = {
            defensivo: false,
          };
          setPivosControllers((pivoControlerSave) =>
            pivoControlerSave.map((pivoControler) =>
              pivoControler.id === controlerPivos.id
                ? { ...pivoControler, ...dataComando }
                : pivoControler
            )
          );
        }
      });

      // Alertas Pivos

      socket.on("pivo_alertas_creat", (alertasPivossoket) => {
        handleListAletas();
      });

      socket.on("pivo_Alertas_edit", (alertasPivossoket) => {
        handleListAletas();
      });
    }
  }, [socket]);

  // Retorno do provedor do contexto com as funções, estados e valores necessários
  return (
    <PivoContext.Provider
      value={{
        pivosList,
        pivosMarker,
        listPivosControlles,
        handleUpdateControllerPivo,
        lisBombstControlles,
        setBombsControllers,
        handleUpdateControllerBomb,
        openModalConfimPivo,
        setOpenModalConfimPivo,
        openModalConfimBomba,
        setOpenModalConfimBomba,
        percetimetros,
        setPercetimetros,
        rpms,
        setRpms,
        infContolersbombFuntion,
        setInfContolersbombFuntion,
        infContolersPivoFuntion,
        setInfContolersPivoFuntion,
        listPivosData,
        setPivosData,
        listBombsData,
        menuMap,
        setMenuMap,
        mapConfigOn,
        moveMapOn,
        menuPivoSelect,
        setonPumpSaveList,
        headerApi,
        onPump,
        mapStyle,
        setMapStyle,
        scaleValue,
        setScaleValue,
        listMotoBombaData,
        lisMotoBombstControlles,
        handleUpdateControllerMotoBomb,
        rpmsMotoBomba,
        setOnMotoBombaSaveFuntion,
        setRpmsMotoBomba,
        onMotoBomba,
        handleClimatePivo,
        climatePivoSave,
        pivoPage,
        setPivoPage,
        expandedPivos,
        setExpandedPivos,
        modalDefesivoOn,
        setModalDefesivoOn,
        modalAlertaOn,
        setModalAlertaOn,
        alertsConts,
        handleListAletas,
        convert4a20,
        modalPersonalizadoOn,
        setModalPersonalizadoOn,
        selectedTime,
        setSelectedTime,
        diasPersonalizados,
        setDiasPersonalizados,
        modalDeleteAgedamento,
        setModalDeleteAgedamento,
        idAgendamento,
        setIdAgendamento,
        modalTaxaVariable,
        setModalTaxaVariable,
        cameraState,
        setCameraState,
        locateMap,
        map3dOn,
        setMap3dOn,
        openModalConfimPivoComando,
        setOpenModalConfimPivoComando,
        statusPvButton,
        setStatusPvButton,
        checkingPermission,
        setCheckingPermission,
        valueSun,
        setValueSun,
        mapSun,
        setMapSun,
        ModalActions,
        setModalActions,
        ConfimeactionHorarioPico,
        setConfimeactionHorarioPico,
        ModalAgedarDeligamento,
        setModalDesligarAgedamento,
      }}
    >
      <Outlet />
    </PivoContext.Provider>
  );
};
