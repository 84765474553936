import { useContext } from "react";
import { ModalAlert, ModlaStatusStyled } from "../../styles/tanque/modals";
import { IoCloseCircle } from "react-icons/io5";
import { TanqueContextC } from "../../contexts/contextTanque";
import { ModalConfirmController } from "../../styles/modals";
import { apiConnect } from "../../services/api";

export const ModalStatus = ({ statusTanque }) => {
  const { modalStatus, setModalStatus } = useContext(TanqueContextC);

  return (
    <ModlaStatusStyled onClick={() => setModalStatus(!modalStatus)}>
      <div
        className="conteiner"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <span
          className="fecharModal"
          onClick={() => setModalStatus(!modalStatus)}
        >
          x
        </span>
        <ul>
          {statusTanque.map((status) => {
            return (
              <li key={status.id}>
                <div>
                  <span> {status.nome}</span>
                  <span>{status.status ? "Ativado" : "Desativado"}</span>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </ModlaStatusStyled>
  );
};

export const ModalAlertas = () => {
  const { modalAlertas, setModalAlertas, alertaList } =
    useContext(TanqueContextC);

  return (
    <ModalAlert>
      <div className="conteiner">
        <IoCloseCircle
          className="closeBotao"
          onClick={() => setModalAlertas(!modalAlertas)}
        />
        <div className="headerModal">
          <span>Data</span>
          <span>Tanque</span>
          <span>Alerta</span>
          <span>Horário</span>
        </div>
        <ul>
          {alertaList &&
            alertaList.map((alerta) => {
              return (
                <li>
                  <span>{alerta.dia}</span>
                  <span>{alerta.unidade}</span>
                  <span>{alerta.alerta}</span>
                  <span>{alerta.hora.split(":").slice(0, 2).join(":")}</span>
                </li>
              );
            })}
        </ul>
      </div>
    </ModalAlert>
  );
};

export const ModalConfimacaoBombaTanque = ({ mensagem }) => {
  const { setModalConfimationBomba, idControlerBomba } =
    useContext(TanqueContextC);

  const lidarButtonBomba = async (id) => {
    const token = sessionStorage.getItem("connectionsToken");
    const headerApi = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const data = {
      comandoBomba: true,
    };

    try {
      await apiConnect.patch(`tanques/bomba/controle/${id}`, data, headerApi);
    } catch (error) {
    } finally {
      setModalConfimationBomba(false);
    }
  };

  return (
    <ModalConfirmController>
      <div className="deleteContact">
        <h2>{`Tem certeza de que deseja ${mensagem}`}</h2>
        <div>
          <button
            className="buttonYes"
            onClick={() => lidarButtonBomba(idControlerBomba)}
          >
            Sim
          </button>
          <button onClick={() => setModalConfimationBomba(false)}>Não</button>
        </div>
      </div>
    </ModalConfirmController>
  );
};
