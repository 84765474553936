import { useEffect, useState } from "react";
import { FooterStyled } from "../styles/footer";
import {
  FaFacebookF,
  FaLinkedin,
  FaInstagram,
  FaYoutube,
  FaWhatsapp,
} from "react-icons/fa";

export const Footer = ({ relative }) => {
  const [relativeBoolean, setRelativeBoolean] = useState(false);

  useEffect(() => {
    setRelativeBoolean(relative === "true");
  }, [relative]);

  return (
    <FooterStyled relative={relativeBoolean}>
      <span>Copyright © ACLS Tecnologia 2024</span>
      <ul>
        <li>
          <a
            href="https://api.whatsapp.com/send?phone=+55(62)%203577-1553&text=Ol%C3%A1%21%20Vim%20do%20Acls%20Connect%20e%20gostaria%20de%20saber%20mais%20sobre%20"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaWhatsapp />
          </a>
        </li>
        <li>
          <a
            href="https://www.facebook.com/aclstecnologia"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaFacebookF />
          </a>
        </li>
        <li>
          <a
            href="https://br.linkedin.com/company/aclsconnect"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaLinkedin />
          </a>
        </li>
        <li>
          <a
            href="https://www.instagram.com/aclsconnect/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaInstagram />
          </a>
        </li>
        <li>
          <a
            href="https://www.youtube.com/channel/UCxWo2TsHhQLewOVXDH6TrqQ"
            target="_blank"
          >
            <FaYoutube />
          </a>
        </li>
      </ul>
    </FooterStyled>
  );
};
