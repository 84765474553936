import { useContext, useEffect, useState } from "react";
import { Header } from "../../components/header";
import { HomeContext } from "../../contexts/contexHome";
import { ModalHeader } from "../../styles/header";
import { HomeMain } from "../../styles/home";
import { apiConnect } from "../../services/api";
import { SquareLoading } from "../../components/loaders";

export const Home = () => {
  const { navigate, isOpen, setIsOpen, user } = useContext(HomeContext);

  const loadinUser = Object.keys(user).length === 0;

  const unityExists = sessionStorage.getItem("connectionsUnit");

  const [Unidades, setUnidades] = useState([]);
  const [loading, setloading] = useState(false);

  useEffect(() => {
    if (unityExists) {
      navigate(`/${unityExists}`);
    }
    if (!loadinUser) {
      ListUnidades();
    }
  }, [navigate, unityExists, loadinUser]);

  const saveUnit = (unit, id) => {
    sessionStorage.setItem("connectionsUnit", unit);
    sessionStorage.setItem("connectionsUnitId", id);
    navigate(`/${unit}`);
  };

  const ListUnidades = async () => {
    setloading(true);
    try {
      const token = sessionStorage.getItem("connectionsToken");

      const headerApi = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await apiConnect.get("unidade/leituratodos", headerApi);
      const unidadesCompatíveis = response.data.filter((obj) =>
        user.unidades.includes(obj.id)
      );
      setUnidades(unidadesCompatíveis);
    } catch (error) {
    } finally {
      setTimeout(() => {
        setloading(false);
      }, 300);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("modal-open"); // Adiciona a classe overflow-hidden ao body quando o modal é aberto
    } else {
      document.body.classList.remove("modal-open"); // Remove a classe overflow-hidden do body quando o modal é fechado
    }

    // Limpa a classe quando o componente é desmontado para evitar vazamentos de memória
    return () => {
      document.body.classList.remove("modal-open");
    };
  }, [isOpen]);

  return (
    <HomeMain>
      {loading && <SquareLoading />}
      {loadinUser && <SquareLoading />}
      <Header relative={"true"} />
      {isOpen ? <ModalHeader onClick={() => setIsOpen(!isOpen)} /> : null}
      <div className="container">
        <h2>Sistema de Gestão</h2>
        <p>Selecione uma Unidade Abaixo</p>
        <div className="containerButtons">
          {Unidades.map((unidade) => {
            return (
              <div className="buttons" key={unidade.id}>
                <button onClick={() => saveUnit(unidade.url, unidade.id)}>
                  {unidade.name}
                </button>
              </div>
            );
          })}
        </div>
      </div>
    </HomeMain>
  );
};
