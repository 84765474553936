import React, { useCallback, useContext, useEffect, useState } from "react";
import { APIProvider, Map } from "@vis.gl/react-google-maps";
import {
  AdvancedMarkerStyled,
  AntenaAmnimation,
  CustomMarker,
  MapaGoogle,
  Ponteiro,
  RelogioContainer,
  UserLoc,
} from "../styles/Pivo/map";
import { PivoContext } from "../contexts/contexPivos";
import imgCompassRose from "../assets/icons/rosasdosventos.png";
import PieChart from "./radarPivo";
import { HomeContext } from "../contexts/contexHome";
import { apiConnect } from "../services/api";
import { toast } from "react-toastify";
import { LuShieldAlert } from "react-icons/lu";
import { PiShieldWarningDuotone } from "react-icons/pi";
import antenaGif from "../assets/icons/antenagif3.gif";
import {
  ModalConfirmChangesResetGTW,
  ModalGraphicGateway,
} from "../pages/admin/modalAdmin";

const API_KEY = "AIzaSyBsBGdo8TmbseKMmx5BhT-SsvTO_zuUROQ";

export const GoogleMaps = () => {
  const {
    cameraState,
    setCameraState,
    pivosMarker,
    listPivosData,
    scaleValue,
    setScaleValue,
    moveMapOn,
    handleClimatePivo,
    locateMap,
    headerApi,
    mapStyle,
  } = useContext(PivoContext);

  const { user, modalGtwHistorico, setModalGtwHistorico, modalResetGTW } =
    useContext(HomeContext);

  const [usersSave, setUsersSave] = useState([]);
  const [gatwaySave, setGatwaySave] = useState([]);
  const [ajusteMarker, setAjusteMarker] = useState(50);
  const [GTWId, setGTWId] = useState(null);

  const userLists = async () => {
    try {
      const api = await apiConnect.get("users/LeituraTodos", headerApi);
      setUsersSave(api.data);
    } catch (error) {
      toast.warn("🤐Algo de Erradoo", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const gatwayList = async () => {
    try {
      const api = await apiConnect.get("gateway/LeituraTodos", headerApi);
      setGatwaySave(api.data);
    } catch (error) {}
  };

  const handleCameraChange = useCallback((ev) => {
    setCameraState(ev.detail);

    calculateScale(ev.detail.zoom);
    calcAjuste(ev.detail.zoom);
  }, []);

  const calculateScale = (zoom) => {
    let finalScale = Math.pow(2, zoom - 15);

    setScaleValue(finalScale);
  };

  const calcAzimuth = (lat1, lon1, lat2, lon2) => {
    // Converter graus para radianos
    var rad = Math.PI / 180;
    lat1 *= rad;
    lat2 *= rad;
    lon1 *= rad;
    lon2 *= rad;

    // Diferença de longitude
    var dLon = lon2 - lon1;

    // Cálculo do azimute
    var y = Math.sin(dLon) * Math.cos(lat2);
    var x =
      Math.cos(lat1) * Math.sin(lat2) -
      Math.sin(lat1) * Math.cos(lat2) * Math.cos(dLon);
    var azimuth = Math.atan2(y, x);

    // Converter de radianos para graus
    azimuth = azimuth / rad;

    // Ajuste para valores entre 0 e 360 graus
    if (azimuth < 0) {
      azimuth += 360;
    }

    return azimuth;
  };

  const calcAjuste = (zoom) => {
    let finalScale = 25 * Math.pow(2, 16 - zoom);

    setAjusteMarker(finalScale);
  };

  const calculateRotation = (pitch) => `rotateX(${pitch}deg)`;
  const calculateBearing = (bearing) => `rotate(${-bearing}deg);`;

  useEffect(() => {
    if (gatwaySave.length == 0) {
      gatwayList();
    }
    if (user.is_superuser) {
      if (usersSave.length === 0) {
        userLists();
      }
    }
  }, [headerApi, user]);

  return (
    <MapaGoogle>
      {modalGtwHistorico && <ModalGraphicGateway gatewayId={GTWId} />}
      {modalResetGTW && <ModalConfirmChangesResetGTW gatewayId={GTWId} />}
      <APIProvider apiKey={API_KEY}>
        <div
          style={{
            height: "100%",
            display: "grid",
            gridTemplateColumns: "1fr",
          }}
        >
          <Map
            id="map-0"
            mapId="bf51a910020fa25a" // ID do mapa que você quer usar
            disableDefaultUI
            gestureHandling={moveMapOn ? "none" : "greedy"}
            onCameraChanged={handleCameraChange}
            mapTypeId={mapStyle} // Define o tipo de mapa como satélite
            {...cameraState}
            options={{
              minZoom: 5,
              maxZoom: 16,
              streetViewControl: true, // Habilita o controle do boneco (Street View)
              fullscreenControl: false, // Desabilita o controle de tela cheia
              mapTypeControl: false, // Desabilita o controle de tipo de mapa
            }}
          >
            {pivosMarker.map((pivo) => {
              const pivoDados = listPivosData.find((e) => pivo.id === e.pivo);

              let latitude_centro = pivoDados
                ? pivoDados.latitude_centro
                : null;
              let longitude_centro = pivoDados
                ? pivoDados.longitude_centro
                : null;
              let latitude_ponta = pivoDados ? pivoDados.latitude_ponta : null;
              let longitude_ponta = pivoDados
                ? pivoDados.longitude_ponta
                : null;

              let angulo = calcAzimuth(
                latitude_centro,
                longitude_centro,
                latitude_ponta,
                longitude_ponta
              );

              return (
                <AdvancedMarkerStyled
                  position={{
                    lat: pivo.latitude,
                    lng: pivo.longitude,
                  }}
                  scale={scaleValue}
                  ajuste={ajusteMarker}
                  onClick={() => {
                    locateMap(pivo.latitude, pivo.longitude, 60, 15, pivo.id);
                    // handleClimatePivo(pivo.latitude, pivo.longitude);
                  }}
                >
                  <CustomMarker
                    size={`${pivo.tamanho}px`}
                    ajuste={ajusteMarker}
                    pitch={calculateRotation(cameraState.tilt)}
                    bearing={calculateBearing(cameraState.heading)}
                  >
                    {pivoDados.seguranca && (
                      <div className="securityOn">
                        <span>Alerta de Segurança</span>
                        <PiShieldWarningDuotone className="iconSecurity" />
                      </div>
                    )}
                    <div className="compassRose">
                      <img src={imgCompassRose} alt="" />
                    </div>
                    <RelogioContainer>
                      {pivoDados && pivoDados.status_pivo && (
                        <PieChart
                          angulo={angulo}
                          statusAgua={pivoDados && pivoDados.status_agua}
                        />
                      )}

                      <Ponteiro angle={angulo} />
                      <span></span>
                    </RelogioContainer>
                  </CustomMarker>
                </AdvancedMarkerStyled>
              );
            })}
            {gatwaySave.length > 0 &&
              gatwaySave.map((markerGtw) => {
                return (
                  <AdvancedMarkerStyled
                    position={{
                      lat: markerGtw.latitude,
                      lng: markerGtw.longitude,
                    }}
                    scale={scaleValue}
                    ajuste={ajusteMarker}
                    onClick={() => {
                      setGTWId(markerGtw.id);
                      setModalGtwHistorico(true);
                    }}
                  >
                    <AntenaAmnimation>
                      <img src={antenaGif} alt="" />
                    </AntenaAmnimation>
                  </AdvancedMarkerStyled>
                );
              })}
            {user.is_superuser &&
              usersSave.map((userLocc) => {
                if (userLocc.longitude) {
                  if (userLocc.online) {
                    return (
                      <AdvancedMarkerStyled
                        position={{
                          lat: user.latitude,
                          lng: user.longitude,
                        }}
                      >
                        <UserLoc>
                          <span>{userLocc.username}</span>
                        </UserLoc>
                      </AdvancedMarkerStyled>
                    );
                  }
                }
              })}
            {!user.is_superuser && user.longitude && (
              <AdvancedMarkerStyled
                position={{
                  lat: user.latitude,
                  lng: user.longitude,
                }}
              >
                <UserLoc />
              </AdvancedMarkerStyled>
            )}
          </Map>
        </div>
      </APIProvider>
    </MapaGoogle>
  );
};
