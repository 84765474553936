import React, { useState, useEffect } from "react";
import {
  CarouselContainer,
  CarouselImage,
  Indicators,
  Indicator,
} from "../styles/CarouselStyled";

const Carousel = ({ images, interval }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, interval);

    return () => clearInterval(timer);
  }, [interval, images.length]);

  return (
    <CarouselContainer>
      <div className="Imgs">
        {images.map((src, index) => (
          <CarouselImage
            key={index}
            src={src}
            alt="Carrossel"
            style={{
              opacity: index === currentIndex ? 1 : 0,
              zIndex: index === currentIndex ? 1 : 0,
              position: "absolute",
            }}
            inView={index === currentIndex}
          />
        ))}
      </div>
      <Indicators>
        {images.map((_, index) => (
          <Indicator
            key={index}
            active={index === currentIndex}
            onClick={() => setCurrentIndex(index)}
          />
        ))}
      </Indicators>
    </CarouselContainer>
  );
};

export default Carousel;
