import styled from "styled-components";

export const GraficoComboStyled = styled.div`
  width: 100%;
  height: 76%;
  max-height: 100%;
  .recharts-cartesian-axis-tick-value {
    fill: #ffffff;
  }

  .recharts-legend-item-text {
    color: #ffffff;
  }

  .recharts-tooltip-label,
  .recharts-tooltip-item,
  .recharts-tooltip-item-list {
    color: #ffffff;
  }

  .recharts-label {
    fill: #ffffff;
  }

  .recharts-xaxis .recharts-cartesian-axis-tick,
  .recharts-yaxis .recharts-cartesian-axis-tick {
    fill: #ffffff;
  }
`;

export const StuledGraficImg = styled.div`
  width: 100%;
  height: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
