import styled from "styled-components";

export const IAPivoStyled = styled.main`
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  div {
    display: flex;
    flex-direction: column;
    /* background-color: aqua; */
    align-items: center;
    justify-content: center;
    padding-right: 5%;
    position: relative;
    h2 {
      position: absolute;
      color: white;
      font-size: 2.5em;
      bottom: 30%;
    }
    img {
      z-index: 2;
      width: 100%;
      height: 100vh;
    }
  }
`;
