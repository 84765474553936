import styled from "styled-components";
import imgBg from "../../assets/imgs-background/background1.png";

export const IaStyled = styled.main`
  display: flex;
  align-items: center;
  justify-content: end;
  min-height: 100vh;
  max-height: max-content;
  width: 100%;
  background-image: url(${imgBg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  .container {
    width: 96%;
    height: 100vh;
    overflow-y: auto;
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(0, 0, 0, 0.3397408621651786) 14%,
      rgba(0, 0, 0, 0.6) 100%
    );
    @media (max-width: 1500px) {
      width: 94%;
    }
    @media (max-width: 600px) {
      width: 99%;
      background: transparent;
    }
  }
`;
