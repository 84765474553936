import React, { useContext, useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import zoomPlugin from "chartjs-plugin-zoom";
import { GraficoUmidadeStyled } from "../../styles/meteorologia/containerUmidade";
import { format, parseISO } from "date-fns";
import { ClimaContext } from "../../contexts/contexClima";
import { useWindowWidth } from "./graficoVento";

export const GraficoUmidadeDoAr = () => {
  const { climaIdSelect, listHistoricoClima, listHistorico } =
    useContext(ClimaContext);

  const [historicoVento, setHistoricoVento] = useState(listHistorico);
  const windowWidth = useWindowWidth();
  // Registro dos componentes necessários para o gráfico
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Filler,
    Title,
    Tooltip,
    Legend,
    zoomPlugin
  );

  const zoomOptions = {
    pan: {
      enabled: windowWidth >= 650,
      mode: "x",
    },
    zoom: {
      wheel: {
        enabled: windowWidth >= 650,
      },
      pinch: {
        enabled: windowWidth >= 650,
      },
      mode: "x",
    },
  };

  // Configuração dos dados para o gráfico
  const data = {
    labels: historicoVento.map((data) =>
      format(parseISO(data.changedAt), "HH:mm")
    ),
    datasets: [
      {
        label: "Umidade do ar",
        data: historicoVento.map((data) => data.humidity),
        backgroundColor: "#2578fd5d",
        borderColor: "#2578fd",
        pointBackgroundColor: "#00eaff",
        color: "#ffffff",
        tension: 0.3,
        pointRadius: 2,
        fill: true,
      },
    ],
  };

  // Configuração das opções do gráfico
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        ticks: {
          color: "#e7e0e0", // Cor do texto no eixo x
          font: {
            size: 11, // Tamanho da fonte das labels no eixo x
          },
        },
        grid: {
          color: "#61606097", // Cor das linhas de grade do eixo X
        },
      },
      y: {
        suggestedMin: 0,
        max: 100,
        beginAtZero: true,
        ticks: {
          color: "#e7e0e0", // Cor do texto no eixo y
          // stepSize: 10,
        },
        grid: {
          color: "#61606097", // Cor das linhas de grade do eixo X
        },
      },
    },
    plugins: {
      legend: {
        position: "top",
        display: false,
        labels: {
          color: "#e7e0e0", // Cor do texto do rótulo do conjunto de dados
          font: {
            size: 8, // Tamanho da fonte do texto do eixo y
          },
        },
      },
      datalabels: {
        display: false,

        formatter: (value) => `${value}%`,
        font: {
          size: 12,
          weight: "bold",
        },
        color: "#ffffff",
      },
      zoom: zoomOptions,
    },
  };

  useEffect(() => {
    setHistoricoVento(listHistorico);
  }, [listHistorico]);

  useEffect(() => {
    if (climaIdSelect) {
      listHistoricoClima(climaIdSelect);
    }
  }, [climaIdSelect, listHistoricoClima]);

  return (
    <GraficoUmidadeStyled>
      <div className="graficoUmidade">
        <Line data={data} options={options} />
      </div>
    </GraficoUmidadeStyled>
  );
};
