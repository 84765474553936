import React, { useContext, useEffect, useState } from "react";
import {
  ComposedChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ReferenceLine,
} from "recharts";
import { GraficoComboStyled } from "../../styles/meteorologia/containerComboGraficos";
import { ClimaContext } from "../../contexts/contexClima";
import { parseISO, format } from "date-fns";
import { apiConnect } from "../../services/api";

const diasDaSemana = [
  { nome: "Seg.", chave: "previsaoSegunda" },
  { nome: "Ter.", chave: "previsaoTerca" },
  { nome: "Qua.", chave: "previsaoQuarta" },
  { nome: "Qui.", chave: "previsaoQuinta" },
  { nome: "Sex.", chave: "previsaoSexta" },
  { nome: "Sáb.", chave: "previsaoSabado" },
  { nome: "Dom.", chave: "previsaoDomingo" },
];

export const GraficoTemperatura = () => {
  const { climaIdSelect, listHistorico, calenderDate } =
    useContext(ClimaContext);

  const [listPrevisoesClima, setListPrevisoesClima] = useState(null);
  const [tempPorDiaMin, setTempPorDiaMin] = useState({});
  const [tempPorDiaMax, setTempPorDiaMax] = useState({});

  const obterIndiceDiaAtual = () => {
    const hoje = new Date(calenderDate).getDay(); // 0 (Dom) a 6 (Sáb)
    return hoje === 0 ? 6 : hoje - 1; // Ajusta para Segunda-feira como 0
  };

  const obterDiasValidos = () => {
    const indiceDiaAtual = obterIndiceDiaAtual();
    const diasValidos = [
      ...diasDaSemana.slice(indiceDiaAtual),
      ...diasDaSemana.slice(0, indiceDiaAtual),
    ].slice(0, 5);

    return diasValidos;
  };

  const listPrevisoes = async (idSelect) => {
    const token = sessionStorage.getItem("connectionsToken");

    const headerApi = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await apiConnect.get(
        `/meteorologia/previsao/Leitura/${idSelect}`,
        headerApi
      );
      setListPrevisoesClima(response.data);
    } catch (error) {
      console.log(error);
      setListPrevisoesClima([]);
    }
  };

  useEffect(() => {
    if (climaIdSelect != null) {
      listPrevisoes(climaIdSelect);
    }
  }, [climaIdSelect]);

  const diaAtualChave = obterDiasValidos()[0]?.chave;

  const previsaoDoDia =
    listPrevisoesClima && diaAtualChave
      ? listPrevisoesClima[diaAtualChave]
      : [];

  // Prepare os dados para o gráfico
  const data = previsaoDoDia.sort((a, b) => {
      // Transformar forecast_data em números para comparação
      const [aHours, aMinutes] = a.forecast_data.split(":").map(Number);
      const [bHours, bMinutes] = b.forecast_data.split(":").map(Number);

      // Comparar as horas e minutos
      return aHours !== bHours ? aHours - bHours : aMinutes - bMinutes;
    })
    .map((item) => {
      // const time = format(parseISO(item.changedAt), "HH:mm");
      const historicoPresao = listHistorico.find((e) => {
        const horarioHistorico = format(parseISO(e.changedAt), "HH:mm");
        const horarioItem = item.forecast_data;

        return horarioHistorico == horarioItem;
      });

      return {
        time: item.forecast_data,
        temp: historicoPresao?.temp,
        temp_max: item.temp_max,
        temp_min: item.temp_min,
      };
    });

  useEffect(() => {
    if (listPrevisoesClima) {
      const tempPorDiaAtualizadoMin = {};
      const tempPorDiaMaxAtualizado = {};

      Object.keys(listPrevisoesClima).forEach((dia) => {
        const temperaturas =
          listPrevisoesClima[dia]?.map((previsao) => previsao.temp_min) || [];
        tempPorDiaAtualizadoMin[dia] = temperaturas;
      });

      Object.keys(listPrevisoesClima).forEach((dia) => {
        const temperaturasMax =
          listPrevisoesClima[dia]?.map((previsao) => previsao.temp_max) || [];
        tempPorDiaMaxAtualizado[dia] = temperaturasMax;
      });

      setTempPorDiaMin(tempPorDiaAtualizadoMin);
      setTempPorDiaMax(tempPorDiaMaxAtualizado);
    }
  }, [listPrevisoesClima]);
  const fixedValue = 30;

  return (
    <GraficoComboStyled>
      <ResponsiveContainer>
        <ComposedChart
          data={data}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 20,
          }}
        >
          <CartesianGrid />
          <XAxis dataKey="time" tick={{ fontSize: 10 }} />
          <YAxis
            domain={["auto", "auto"]}
            tick={{ fontSize: 12 }}
            label={{
              value: "Temperatura (°C)",
              angle: -90,
              position: "insideBottomLeft",
            }}
          />
          <Tooltip />
          <Legend />
          <Line
            type="monotone"
            dataKey="temp_min"
            stroke="#025b9b85"
            strokeWidth={3}
            name="Temperatura Mínima"
            strokeDasharray="10 5"
            dot={false} // Removendo os pontos da linha
          />
          <Line
            type="monotone"
            dataKey="temp"
            stroke="#ffee00"
            strokeWidth={3}
            name="Temperatura Atual"
            dot={false} // Removendo os pontos da linha
          />
          <Line
            type="monotone"
            dataKey="temp_max"
            stroke="#75040466"
            strokeWidth={3}
            name="Temperatura Máxima"
            strokeDasharray="10 5"
            dot={false} // Removendo os pontos da linha
            className="temp-max-line"
          />
          <ReferenceLine
            y={
              tempPorDiaMax[diaAtualChave]?.length > 0 &&
              Math.max(...tempPorDiaMax[diaAtualChave]).toFixed(0)
            }
            stroke="#ff2020e2"
            strokeWidth={2}
            label={{
              value: `Máxima: ${
                tempPorDiaMax[diaAtualChave]?.length > 0 &&
                Math.max(...tempPorDiaMax[diaAtualChave]).toFixed(0)
              }`,
              position: "top",
              fill: "#ff202092",
            }}
          />
          <ReferenceLine
            y={
              tempPorDiaMin[diaAtualChave]?.length > 0 &&
              Math.min(...tempPorDiaMin[diaAtualChave]).toFixed(0)
            }
            stroke="#0f98fa9d"
            strokeWidth={2}
            label={{
              value: `Mínima: ${
                tempPorDiaMin[diaAtualChave]?.length > 0 &&
                Math.min(...tempPorDiaMin[diaAtualChave]).toFixed(0)
              }`,
              position: "bottom",
              fill: "#ff202092",
            }}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </GraficoComboStyled>
  );
};
