import styled from "styled-components";
import imgBg from "../../assets/imgs-background/background1.png";

export const PivosPageStyled = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  width: 100%;
  background-image: url(${imgBg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  .pivoInfos {
    display: flex;
    flex-direction: column;
    width: 95%;
    gap: 25px;
    border: 1px solid #b2b2b2;
    border-radius: 8px;
    background: rgba(0, 0, 0, 0.6);
    padding: 50px 10px;
    margin-top: 80px;
    margin-bottom: 100px;
    height: min-content;
    .pivoButton {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: center;
      button {
        width: 400px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 15px;
        padding: 25px;
        cursor: pointer;
        background: rgba(0, 0, 0, 0.5);
        border-radius: 8px;
        border: 1px solid transparent;
        color: white;
        font-size: 25px;
        transition: all 0.3s;
        img {
          width: 90px;
        }
      }
      button:hover {
        border: 1px solid white;
      }
    }
    ul {
      display: flex;
      gap: 25px;
      width: 100%;
      align-items: center;
      justify-content: center;
      list-style: none;
      li {
        background: rgba(0, 0, 0, 0.5);
        box-shadow: -1px 8px 91px -6px rgba(0, 0, 0, 0.27);
        -webkit-box-shadow: -1px 8px 91px -6px rgba(0, 0, 0, 0.27);
        -moz-box-shadow: -1px 8px 91px -6px rgba(0, 0, 0, 0.27);
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 400px;
        justify-content: center;
        gap: 10px;
        padding: 30px 20px;
        span {
          color: white;
          font-size: 30px;
          margin-bottom: 10px;
        }

        div {
          display: flex;
          align-items: center;
          gap: 10px;
          justify-content: center;
          color: white;
          font-size: 25px;
          .on {
            color: green;
          }
          .off {
            color: red;
          }
          .iconPivos {
            font-size: 33px;
          }
        }
      }
    }
    .Caledario {
      display: flex;
      flex-direction: column;
      gap: 20px;
      height: min-content;
      align-items: center;
      justify-content: center;
      width: 100%;
      h2 {
        color: white;
      }
    }
  }
  @media (max-width: 850px) {
    .pivoInfos ul {
      gap: 10px;
      li {
        width: 100%;
        flex-direction: column;
        padding: 20px 10px;
        div {
          font-size: 18px;
        }
      }
    }
  }
  @media (max-width: 500px) {
    .pivoInfos ul {
      flex-direction: column;
      width: 100%;
      align-items: center;
      justify-content: center;
      li {
        width: 85%;
        flex-direction: column;
        padding: 10px 5px;
        div {
          font-size: 16px;
        }
      }
    }
  }
`;

export const PivoPageStyled = styled.main`
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: center;
  min-height: 100vh;
  width: 100%;
  background-image: url(${imgBg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  scrollbar-width: none;
  .secMap {
    display: flex;
    /* justify-content: center; */
    align-items: center;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;
    gap: 5px;
    position: relative;
    .zomActivate {
      position: absolute;
      right: 18px;
      bottom: 105px;
      display: flex;
      gap: 20px;
      align-items: center;
      width: fit-content;
      max-width: 100%;
      p {
        color: rgba(187, 187, 187, 1);
        font-size: 18px;
      }
      div {
        display: flex;
        flex-direction: column;
        gap: 5px;
        span {
          color: rgba(187, 187, 187, 1);
          font-size: 20px;
        }
        button {
          width: 20px;
          min-width: 20px;
          height: 50px;
          border-radius: 50px;
          border: none;
          position: relative;
          cursor: pointer;
          background: rgba(0, 0, 0, 0.6);
        }
      }
    }
    .LocUser {
      position: absolute;
      bottom: 23px;
      right: 54px;
      background-color: rgba(55, 55, 55, 0.8);
      padding: 5px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 2px solid rgba(187, 187, 187, 0.8);
      cursor: pointer;
      .icon {
        font-size: 28px;
        color: rgba(187, 187, 187, 1);
      }
    }
    .solNivel {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 15px;
      position: absolute;
      top: 180px;
      right: 10px;
      width: 250px;
      max-width: 100%;
      height: 50px;

      .time-display {
        text-shadow: rgb(255, 255, 255) 1px 0px 0px,
          rgb(255, 255, 255) 0.540302px 0.841471px 0px,
          rgb(255, 255, 255) -0.416147px 0.909297px 0px,
          rgb(255, 255, 255) -0.989992px 0.14112px 0px,
          rgb(255, 255, 255) -0.653644px -0.756802px 0px,
          rgb(255, 255, 255) 0.283662px -0.958924px 0px,
          rgb(255, 255, 255) 0.96017px -0.279415px 0px;
      }
      input[type="range"] {
        -webkit-appearance: none;
        appearance: none;
        background: transparent;
        cursor: pointer;
        width: 250px;
        max-width: 100%;
      }

      /* Removes default focus */
      input[type="range"]:focus {
        outline: none;
      }

      /******** Chrome, Safari, Opera and Edge Chromium styles ********/
      /* slider track */
      input[type="range"]::-webkit-slider-runnable-track {
        background: linear-gradient(
          90deg,
          rgba(180, 162, 34, 1) 0%,
          rgba(255, 242, 147, 1) 29%,
          rgba(237, 252, 0, 1) 75%,
          rgba(188, 170, 46, 1) 100%
        );
        border-radius: 0.5rem;
        border: 1px solid #f7ff00;
        height: 20px;
      }

      /* slider thumb */
      input[type="range"]::-webkit-slider-thumb {
        -webkit-appearance: none; /* Override default look */
        appearance: none;
        margin-top: -9px; /* Centers thumb on the track */
        background: rgb(255, 244, 0);
        background: radial-gradient(
          circle,
          rgba(255, 252, 232, 1) 25%,
          rgba(237, 252, 0, 1) 100%
        );

        border-radius: 50%;
        border: 1px solid #ffb200;
        height: 40px;
        width: 40px;
      }

      input[type="range"]:focus::-webkit-slider-thumb {
        outline: 3px solid #ffec00;
        outline-offset: 0.125rem;
      }

      /*********** Firefox styles ***********/
      /* slider track */
      input[type="range"]::-moz-range-track {
        background-color: #e5e6ad;
        border-radius: 0.5rem;
        height: 20px;
      }

      /* slider thumb */
      input[type="range"]::-moz-range-thumb {
        background-color: #ffea00;
        border: none; /*Removes extra border that FF applies*/
        border-radius: 50%;
        height: 35px;
        width: 35px;
      }

      input[type="range"]:focus::-moz-range-thumb {
        outline: 3px solid #ffea00;
        outline-offset: 0.125rem;
      }
    }
    .controlsSatellite {
      display: flex;
      gap: 5px;
      position: absolute;
      top: 35px;
      right: 30px;
      button {
        display: flex;
        width: 50px;
        height: 100%;
        align-items: center;
        justify-content: center;
        padding: 5px;
        font-size: 30px;
        color: white;
        background: rgba(0, 0, 0, 0.5);
        border-radius: 20px;
        border: none;
        transition: 0.5s;
        cursor: pointer;
      }
      .dMap {
        opacity: 60%;
        font-size: 25px;
        padding: 8px;
        transition: 0.5s;
      }
      .dMap:hover {
        opacity: 100%;
      }

      .dMapOn {
        opacity: 100%;
        font-size: 25px;
        padding: 8px;
      }
      .controlsSatellite:hover {
        button {
          background: rgba(0, 0, 0, 1);
        }
      }
      .Map3d {
        position: relative;
        transition: 0.5s;
        .text3d {
          position: absolute;
          top: 0;
          right: 0;
          font-size: 16px;
          text-shadow: -3px 2px 8px #000000;
        }
        &:hover {
          background: rgba(0, 0, 0, 0.9);

          .selectMap3d {
            display: flex;
          }
        }
        .selectMap3d {
          display: none;
          position: absolute;
          bottom: -38px;
          font-size: 19px;
          right: 0;
          gap: 5px;
          padding: 8px;
          border-radius: 10px;
          background-color: rgba(0, 0, 0, 0.9);
          z-index: 99;
          transition: 0.5s;
          span {
            border-bottom: 2px solid transparent;
            transition: 0.3s;
          }
          span:hover {
            border-bottom: 2px solid white;
          }
          .disabled {
            opacity: 0.4;
            cursor: no-drop;
          }
        }
      }
    }

    .climatePivo {
      display: flex;
      top: 85px;
      right: 45px;
      position: absolute;
      flex-direction: column;
      align-items: center;
      width: 180px;
      max-width: 100%;
      .climaConteiner {
        display: flex;
        justify-content: space-between;
        margin-bottom: 5px;
        width: 100%;
        min-height: 50px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: rgba(216, 216, 216, 1);
        font-size: 60px;
        .icon {
          filter: drop-shadow(1px -1px 4px #3d3f00);
        }
      }
      span {
        font-size: 30px;
        text-shadow: rgb(0, 0, 0) 1px 0px 0px,
          rgb(0, 0, 0) 0.540302px 0.841471px 0px,
          rgb(0, 0, 0) -0.416147px 0.909297px 0px,
          rgb(0, 0, 0) -0.989992px 0.14112px 0px,
          rgb(0, 0, 0) -0.653644px -0.756802px 0px,
          rgb(0, 0, 0) 0.283662px -0.958924px 0px,
          rgb(0, 0, 0) 0.96017px -0.279415px 0px;
      }
      .verMaisClima {
        font-size: 19px;
        color: white;
        border-bottom: 1px solid white;
        cursor: pointer;
        width: 100%;
        text-align: center;
      }
    }
    .pivoConfg {
      display: flex;
      width: 100%;
      height: 80px;
      right: 0;
      position: absolute;
      background-color: rgba(3, 3, 3, 0.7);
      bottom: 0;
    }
    .mapPivo {
      width: 100%;
      /* border: 2px solid #b2b2b2; */
      /* border-radius: 5px; */
      border: none;
      height: 600px;
      min-height: 100vh;
      max-height: 100%;
      position: relative;
      @media (max-width: 600px) {
        min-height: calc(95vh - 10px);
      }
      .mapboxgl-map {
        .mapboxgl-canvas-container {
          .mapboxgl-marker {
            /* background-color: red; */
            z-index: 5;
          }
        }
      }
      .openMenuMap {
        position: absolute;
        top: 35px;
        left: 70px;
        font-size: 30px;
        color: white;
        font-weight: 800;
        cursor: pointer;
      }
      /* ul {
        list-style: none;
        display: flex;
        flex-direction: column;
        gap: 10px;
        top: 0;
        padding: 10px 0px;
        padding-left: 65px;
        left: 0;
        position: absolute;
        color: white;
        height: 100%;
        max-height: 100%;
        width: 20%;
        background-color: rgba(3, 3, 3, 0.7);
        overflow: auto;
        -webkit-animation: swing-in-left-fwd 0.5s
          cubic-bezier(0.175, 0.885, 0.32, 1.275) both;
        animation: swing-in-left-fwd 0.5s
          cubic-bezier(0.175, 0.885, 0.32, 1.275) both;
        overflow: hidden;
        .cloneMenu {
          display: flex;
          width: 100%;
          align-items: center;
          justify-content: space-between;
          padding: 10px;
          img {
            width: 40px;
          }
          p {
            font-size: 22px;
            color: white;
          }
          span {
            font-size: 25px;
            color: #b2b2b2;
            cursor: pointer;
            font-weight: 500;
          }
          span:hover {
            color: white;
          }
        }
        .allPivos {
          margin-top: 5px;
          border-bottom: 1px solid #b2b2b2;
          border-top: 1px solid #b2b2b2;
          cursor: pointer;
          border-left: 4px solid transparent;
          p {
            font-size: 22px;
            color: white;
            font-weight: 500;
            padding: 10px;
          }
        }
        .allPivos:hover {
          background-color: rgba(255, 255, 255, 0.2);
          border-left: 4px solid white;
        }
        .pivosList {
          display: flex;
          justify-content: space-between;
          align-items: center;
          cursor: pointer;
          border-left: 4px solid transparent;
          p {
            font-size: 22px;
            color: white;
            font-weight: 500;
            padding: 10px;
          }
          .iconConfigPivo {
            color: #b2b2b2;
            margin-right: 10px;
            font-size: 20px;
            cursor: pointer;
          }
          .iconConfigPivo:hover {
            color: white;
          }
        }
        .pivosList:hover {
          background-color: rgba(255, 255, 255, 0.2);
          border-left: 4px solid white;
        }
        .menuSelect {
          background-color: rgba(255, 255, 255, 0.2);
          border-left: 4px solid white;
        }
      } */
    }
    .downArrow {
      font-size: 45px;
      color: white;
      margin-bottom: 20px;
      cursor: pointer;
    }
  }
  #pivosControllers {
    display: flex;
    flex-direction: column;
    width: 96%;
    height: 100%;
    margin-top: 10px;
    margin-bottom: 50px;
    padding-right: 10px;
    .ulConteiner {
      display: flex;
      flex-direction: column;
      height: 100%;
      width: 100%;
      min-height: 100vh;
      align-items: center;
      gap: 25px;
      margin-top: 25px;
      section {
        padding-bottom: 80px;
        .icon {
          display: none;
        }
        .not404 {
          display: none;
        }
      }
      .pivoConteiner {
        .buttonClose {
          position: absolute;
          top: 20px;
          right: 10px;
          font-size: 30px;
          cursor: pointer;
        }
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 100%;
        border: 1px solid rgba(206, 206, 206, 0.2);
        position: relative;
        background-color: rgba(0, 0, 0, 0.41);
        border-radius: 20px;
        padding: 10px;
        padding-top: 80px;
        color: white;
        max-height: 80px; /* Ou qualquer valor desejado */
        overflow: hidden;
        transition: max-height 0.5s ease;
        .SelectPivo {
          display: flex;
          gap: 15px;
          color: #b2b2b2;
          cursor: pointer;
          padding: 15px 5px;
          h3 {
            font-size: 33px;
            transition: 0.5s;
            border-bottom: 3px solid transparent;
            border-radius: 3px;
          }
          h3:hover {
            color: white;
          }
          .selected {
            color: white;
            border-bottom: 3px solid white;
          }
        }
        .namePivo {
          display: flex;
          gap: 10px;
          align-items: center;
          justify-content: center;
          position: absolute;
          bottom: 15px;
          left: 25px;
          font-size: 25px;
          color: white;
          cursor: pointer;
          .iconePivo {
            font-size: 55px;
            color: white;
          }
        }
        .controllers {
          display: flex;
          width: 100%;
          gap: 15px;
          flex-direction: column;
          padding: 10px;
          padding-bottom: 25px;
          h3 {
            font-size: 25px;
          }
          .buttons {
            display: flex;
            flex-wrap: wrap;
            gap: 15px;
            div {
              display: flex;
              flex-direction: column;
              gap: 10px;
              span {
                font-size: 25px;
              }
              .controllerPercetimetro {
                display: flex;
                width: 100%;
                flex-direction: row;
                input {
                  font-size: 25px;
                  background-color: rgba(0, 0, 0, 0.3);
                  border-radius: 10px;
                  border: none;
                  width: 150px;
                  height: 50px;
                  color: white;
                  max-width: 100%;
                  padding-left: 10px;
                }
              }
              button {
                display: flex;
                gap: 5px;
                font-size: 20px;
                width: 200px;
                max-width: 100%;
                height: 50px;
                align-items: center;
                justify-content: center;
                border-radius: 10px;
                background-color: rgba(0, 0, 0, 0.3);
                border: 2px solid transparent;
                color: white;
                cursor: pointer;
                transition: 0.5s;
              }
              button:hover {
                border: 2px solid #b2b2b2;
              }
            }
          }
        }
        .sectionHistoric {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;
        }
      }
      .dataFields {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 10px;
        gap: 20px;
        h3 {
          font-size: 25px;
        }
        .dadosPivo {
          display: flex;
          flex-wrap: wrap;
          gap: 25px;
          width: 100%;
          ul {
            display: flex;
            flex-direction: row;
            list-style: none;
            flex-wrap: wrap;
            gap: 15px;
            margin-top: 20px;
            li {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              font-size: 20px;
              gap: 8px;
              span {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 10px 26px;
                background-color: rgba(0, 0, 0, 0.3);

                border-radius: 15px;
                min-width: 150px;
              }
            }
          }
        }
      }
      .expanded {
        height: 90vh;
        max-height: 100%;
        padding: 10px;
        .icon {
          display: flex;
        }
        .not404 {
          display: flex;
        }
      }
    }
  }
  .buttonTop {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 20px;
    right: 25px;
    width: 45px;
    height: 45px;
    border: 3px solid #e6e6e6;
    background: transparent;
    border-radius: 50%;
    font-size: 30px;
    color: #e6e6e6;
    background: transparent;
    cursor: pointer;
    z-index: 5;
  }
  @media (max-width: 1500px) {
    #pivosControllers {
      width: 94%;
    }
  }
  @media (max-width: 1000px) {
    #pivosControllers {
      width: 92%;
    }
  }
  @media (min-width: 800px) {
    .controllerPercetimetro {
      flex-direction: row;
    }
  }
  @media (max-width: 800px) {
    #pivosControllers {
      width: 90%;
    }
    .secMap {
      .climatePivo {
        display: none;
        width: 150px;
        min-height: 50px;
        position: absolute;
        top: 250px;
        right: 45px;
        /* display: flex; */
        gap: 2px;
        align-items: center;
        color: rgba(216, 216, 216, 1);
        font-size: 50px;
        span {
          font-size: 25px;
        }
      }
      .mapPivo {
        ul {
          /* width: 50%; */
        }
      }
    }
  }
  @media (max-width: 400px) {
    .controllerPercetimetro {
      display: flex;
      flex-direction: column !important; /* Use !important para garantir que a regra seja aplicada */
      align-items: flex-start; /* Alinhe os itens ao início do eixo transversal (vertical) */
      justify-content: flex-start; /* Justifique os itens ao início do eixo principal (vertical) */
      width: 100%; /* Certifique-se de que a div ocupe toda a largura disponível */
    }
    .secMap {
      .mapPivo {
        ul {
        }
      }
    }
  }
  @media (max-width: 430px) {
    #pivosControllers {
    }
    .ulConteiner {
    }
    .SelectPivo {
      max-width: 100%;
      flex-wrap: wrap;
    }
  }
  @media (max-width: 600px) {
    #pivosControllers {
      width: 98%;
    }
    .secMap {
      .mapPivo {
        .openMenuMap {
          left: 10px;
        }
      }
      .solNivel {
        top: 100px;
      }
    }
  }
`;

export const CustomMarker = styled.div`
  /* background-image: url('path/to/custom-marker-image.png'); */

  width: 50px;
  height: 50px;
  background-size: cover;
  cursor: pointer;
`;

export const PivoHistoric = styled.section`
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 15px;
  padding: 10px;
  height: 98%;
  max-height: 100%;
  .not404 {
    text-align: center;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 19%;
    width: 90%;
    height: 65%;
    background-color: rgba(0, 0, 0, 0);
    border-radius: 10px;
    font-size: 25px;
  }
  .calendarDiv {
    display: flex;
    align-items: center;
    z-index: 9;
    padding-top: 50px;
    gap: 20px;
    flex-direction: column;
    width: 100%;
    height: 100%;
    position: absolute;
    background: rgb(0, 0, 0);
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.88) 70%,
      rgba(0, 0, 0, 0) 100%
    );
    .close {
      position: absolute;
      right: 30%;
      font-size: 30px;
      cursor: pointer;
      color: rgba(199, 198, 198, 1);
      transition: 0.5s;
    }
    .close:hover {
      color: white;
    }
    h3 {
      font-size: 25px;
      text-shadow: 3px 1px 5px rgba(0, 0, 0, 0.64);
    }
    .calendar {
      border-radius: 20px;
      overflow: auto;
      /* background-color: rgba(35, 35, 35, 0.82); */
      .react-calendar__navigation button {
        background-color: rgba(0, 0, 0, 0.5); /* Cor de fundo do botão */
        color: white; /* Cor do texto */
        border: none; /* Removendo a borda padrão */
      }
    }
    .buutons {
      display: flex;
      justify-content: center;
      gap: 20px;
      width: 350px;
      max-width: 100%;
      button {
        padding: 10px 20px;
        cursor: pointer;
        border-radius: 8px;
        font-size: 16px;
      }
    }
  }
  .icon {
    display: flex;
    align-items: center;
    font-size: 45px;
    height: fit-content;
    max-height: 100%;
    position: relative;
    cursor: pointer;
    position: absolute;
    right: 10%;
    top: 9%;
    .conteinerIcon {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      span {
        font-size: 18px;
        position: absolute;
        top: 17px;
        left: 13px;
      }
    }
    .iconDw {
      font-size: 33px;
    }
  }
  .graphContainer {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 25px;
  }
  @media (max-width: 800px) {
    height: 70vh;
    max-height: 80vh;
  }
  @media (max-width: 600px) {
    height: 70vh;
    max-height: 80vh;
    .not404 {
      width: 90%;
      height: 50%;
    }
  }
`;

export const Agendastyled = styled.section`
  width: 96%;
  min-height: 100vh;
  max-height: 100%;
  h2 {
    margin: 20px 10px;
    font-size: 28px;
    color: rgba(204, 204, 204, 1);
  }
  .pivoUl {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    gap: 5px;
    width: 100%;
    list-style: none;
    padding-right: 10px;
    margin-top: 5px;
    .pivoConteiner {
      display: flex;
      justify-content: space-between;
      padding-top: 80px;
      width: 100%;
      height: 85vh;
      position: relative;
      background-color: rgba(0, 0, 0, 0.41);
      border-radius: 10px;
      margin-bottom: 20px;
      max-height: 80px;
      border: 1px solid rgba(206, 206, 206, 0.2);
      overflow: auto;
      gap: 20px;
      max-width: 100%;
      .namePivo {
        display: flex;
        gap: 10px;
        align-items: center;
        position: absolute;
        bottom: 15px;
        left: 25px;
        font-size: 25px;
        color: white;
        h3 {
          margin-right: 20px;
          font-size: 28px;
        }

        width: 100%;
        cursor: pointer;
        .iconePivo {
          font-size: 55px;
          color: white;
        }
        div {
          display: flex;
          align-items: center;
          gap: 15px;
          color: rgba(164, 164, 164, 1);
        }
      }
      .sections {
        display: flex;
        width: 100%;
        max-height: 90%;
        justify-content: space-between;
        overflow: auto;

        section {
          margin-top: 10px;
          max-height: 90%;
          .listAgendas {
            margin-top: 8px;
            display: flex;
            gap: 10px;
            width: 80%;
            .repeticao {
              max-width: 45%; /* Define a largura máxima do span */
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }
        }
        .left {
          width: 30%;
          display: flex;
          flex-direction: column;
          border-right: 2px solid #bfbfbf;
          padding-right: 30px;
          position: relative;
          .inpults {
            display: flex;
            gap: 15px;
            align-items: end;
            flex-direction: column;
            .relogioPivo {
              width: 100%;
              .MuiFormControl-root {
                width: 100%;
              }
              button {
                color: #bfbfbf;
              }
              label {
                color: #bfbfbf;
              }
              div {
                input {
                  color: #bfbfbf;
                }
              }
              fieldset {
                border-color: #bfbfbf;
                input {
                  color: #bfbfbf;
                }
              }
            }
            .repetition {
              display: flex;
              flex-direction: column;
              position: relative;
              width: 100%;
              label {
                margin-bottom: 8px;
                font-size: 13px;
                color: #bfbfbf;
                position: absolute;
                left: 10px;
                top: -7px;
                padding: 0 4px;
                background: rgb(0, 0, 0);
                background: linear-gradient(
                  0deg,
                  rgba(0, 0, 0, 0) 20%,
                  rgba(44, 47, 48, 1) 50%,
                  rgba(0, 0, 0, 0) 80%
                );
                backdrop-filter: blur(1px);
                max-width: 80%;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              }
              select {
                height: 55px;
                border: 1.5px solid #bfbfbf;
                border-radius: 3px;
                background-color: transparent;
                color: #bfbfbf;
                font-size: 16px;
                width: 100%;
                padding: 0 10px;
                &:focus {
                  border-color: rgba(255, 255, 255, 1);
                  outline: none;
                }
                .optionDesativ {
                  background-color: black;
                  opacity: 0;
                  display: none;
                }
              }
              input {
                height: 55px;
                border: 1.5px solid #bfbfbf;
                border-radius: 3px;
                background-color: transparent;
                color: #bfbfbf;
                font-size: 19px;
                width: 100%;
                padding: 0 10px;
                ::-webkit-inner-spin-button,
                ::-webkit-outer-spin-button {
                  -webkit-appearance: none;
                  margin: 0;
                }
                /* Remove setas no Firefox */
                -moz-appearance: textfield;

                &:focus {
                  border-color: rgba(255, 255, 255, 1);
                  outline: none;
                }
                &:focus {
                  border-color: rgba(255, 255, 255, 1);
                  outline: none;
                }
              }
              option {
                font-size: 16px;
                background-color: black;
              }
            }
            .calender {
              width: 100%;
              .MuiFormControl-root {
                width: 100%;
              }
              label {
                color: #bfbfbf;
              }
              fieldset {
                border-color: #bfbfbf;
                width: 100%;
              }
              button {
                color: rgba(145, 145, 145, 1);
              }
              div {
                color: #bfbfbf;
              }
            }
            .checkboxFinal {
            }
          }
          .lapCheck {
            position: absolute;
            bottom: 5px;
            right: 15px;

            span {
              color: rgba(167, 167, 167, 1);
              font-size: 18px;
            }
          }
          .lapCheck {
            position: absolute;
            bottom: 5px;
            right: 15px;

            span {
              color: rgba(167, 167, 167, 1);
              font-size: 18px;
            }
          }
        }
        .center {
          width: 60%;
          max-height: 100%;
          /* overflow: auto; */
          .pivo {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          }
          .buttoes {
            display: flex;
            justify-content: space-between;
            margin-top: 20px;
            list-style: none;
            li {
              display: flex;
              flex-direction: column;
              gap: 10px;
              align-items: center;
              button {
                width: 100px;
                height: 42px;
                /* border: none; */
                border-radius: 50px;
                /* background-color: rgba(83, 83, 83, 0.6); */
                cursor: pointer;
                position: relative;
              }
              p {
                display: flex;
                color: rgba(179, 176, 176, 1);
                min-width: 140px;
                justify-content: center;
              }
            }
          }
          .buttonAgendamento {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 20px;
            button {
              cursor: pointer;
              width: 150px;
              height: 45px;
              border-radius: 10px;
              font-size: 18px;
              border: none;
              font-weight: 600;
              color: #56666d;
              background-color: rgba(222, 222, 222, 1);
              transition: 1s;
            }
            button:hover {
              background-color: rgba(255, 255, 255, 1);
            }
          }
        }
        .right {
          border-left: 2px solid #bfbfbf;
          padding-left: 10px;
          height: 90%;
          overflow: auto;
          width: 30%;
          position: relative;

          h3 {
            color: white;
          }
          li {
            display: flex;
            justify-content: space-between;

            span {
              font-size: 18px;
              color: rgba(204, 204, 204, 1);
            }
            .icons {
              display: flex;
              align-items: center;

              span {
                font-size: 25px;
                cursor: pointer;
                margin-top: 5px;
                transition: 1s;
              }
              span:hover {
                color: rgba(187, 0, 0, 1);
              }
            }
          }
        }
      }
    }
    li.expanded {
      max-height: 100%;
      padding: 20px 5%;
    }
  }

  @media (max-width: 1600px) {
    width: 94%;
  }
  @media (min-width: 1200px) {
    ul {
      .pivoConteiner {
        padding-left: 13%;
        padding-right: 13%;
      }
    }
  }
  @media (max-width: 1100px) {
    width: 92%;
    ul {
      .pivoConteiner {
        padding-left: 0%;
        padding-right: 0%;
        .sections {
          padding-left: 0%;
          padding-right: 0%;
        }

        .namePivo {
          font-size: 20px;
          bottom: 20px;
          left: 15px;
          h3 {
            margin-right: 10px;
            font-size: 25px;
          }
          .iconePivo {
            font-size: 40px;
            color: white;
          }
        }
      }
    }
  }
  @media (max-width: 950px) {
    width: 90%;
    .pivoUl {
      .pivoConteiner {
        .sections {
          flex-direction: column;
          overflow: scroll;

          justify-content: start;
          align-items: center;
          max-height: 100%;
          margin-bottom: 80px;
          .left {
            width: 100%;
            border: none;
            padding-right: 0;
          }
          .center {
            width: 100%;
            margin-bottom: 50px;
          }
          .right {
            width: 100%;
            overflow: visible;
            margin-top: 80px;
            h3 {
              position: absolute;
              top: -20px;
              left: -2px;
            }
          }
        }
      }
      li.expanded {
        padding: 20px 15px;
      }
    }
  }
  @media (max-width: 480px) {
    width: 85%;
    ul {
      .pivoConteiner {
        .namePivo {
          font-size: 15px;
          gap: 5px;
          h3 {
            margin-right: 5px;
            font-size: 15px;
          }
          .iconePivo {
            font-size: 35px;
            color: white;
          }
          div {
            display: flex;
            align-items: center;
            gap: 0px;
            color: rgba(164, 164, 164, 1);
          }
        }
      }
    }
  }
  @media (max-width: 600px) {
    width: 98%;
    ul {
      .pivoConteiner {
        .namePivo {
          font-size: 25px;
          gap: 5px;
          h3 {
            margin-right: 5px;
            font-size: 25px;
          }
          .iconePivo {
            font-size: 35px;
            color: white;
          }
          div {
            display: flex;
            align-items: center;
            gap: 0px;
            color: rgba(164, 164, 164, 1);
            span {
              display: none;
            }
          }
        }
      }
    }
  }
`;

export const CustomInput = styled.input`
  height: 55px;
  border: 1.5px solid #bfbfbf;
  border-radius: 3px;
  background-color: transparent;
  color: #bfbfbf;
  font-size: 19px;
  width: 140px;
  padding: 0 10px;

  /* Remove setas no Chrome, Safari, Edge e Opera */
  &[type="number"]::-webkit-inner-spin-button,
  &[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Remove setas no Firefox */
  &[type="number"] {
    -moz-appearance: textfield;
  }

  &:focus {
    border-color: rgba(255, 255, 255, 1);
    outline: none;
  }
`;
