import { useContext, useState } from "react";
import { useEffect } from "react";
import { Footer } from "../../components/footer";
import { HomeContext } from "../../contexts/contexHome";
import { ModalHeader } from "../../styles/header";
import { UnitStyled } from "../../styles/home";
import { Header2 } from "../../components/header2";
import logoAcls from "../../assets/logos/logo3.png";
import imgPump from "../../assets/icons/pumpWite.png";
import imgTanque from "../../assets/icons/Novotanque.png";
import { SiPivotaltracker } from "react-icons/si";
import { TbEngine } from "react-icons/tb";
import imgAgua from "../../assets/icons/aguaBranca.png";
import imgAguaSuja from "../../assets/icons/aguaSujaIcon.png";
import { FaCow } from "react-icons/fa6";
import { useParams } from "react-router-dom";
import { apiConnect } from "../../services/api";
import { SquareLoading } from "../../components/loaders";
import { FaCloudSun } from "react-icons/fa";
import { HeaderMobile } from "../../components/mobileHeader";
import iaAClS from "../../assets/icons/ia.png";

export const Unidade1 = () => {
  const { navigate, isOpen, setIsOpen, modalAvisoOpen, setModalAvisoOpen } =
    useContext(HomeContext);

  const [loading, setloading] = useState(false);
  const [Unidades, setUnidades] = useState({});
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const unityExists = sessionStorage.getItem("connectionsUnit");

  const ListUnidade = [
    {
      titulo: "Pivô",
      status: true,
      navigate: `/${unityExists}/Painelpivos/Map`,
      img: <SiPivotaltracker className="icon" />,
      icon: true,
    },
    {
      titulo: "Estações Meteorológicas",
      status: true,
      navigate: `/${unityExists}/clima`,
      img: <FaCloudSun className="icon" />,
      icon: true,
    },
    {
      titulo: "Tanques",
      status: true,
      navigate: `/${unityExists}/tanques`,
      img: imgTanque,
      icon: false,
    },
    {
      titulo: "Motobombas",
      status: false,
      navigate: `/${unityExists}/`,
      img: imgPump,
      icon: false,
    },
    {
      titulo: "Eletrobombas",
      status: false,
      navigate: `/${unityExists}/`,
      icon: true,
      img: <TbEngine className="icon" />,
    },
    {
      titulo: "Reservatório de Água Limpa",
      status: false,
      navigate: `/${unityExists}/`,
      img: imgAgua,
      icon: false,
    },
    {
      titulo: "Reservatório de Água Suja",
      status: false,
      navigate: `/${unityExists}/`,
      img: imgAguaSuja,
      icon: false,
    },
    {
      titulo: "Biodigestor",
      status: false,
      navigate: `/${unityExists}/`,
      icon: true,
      img: <FaCow className="icon" />,
    },
    {
      titulo: "Acls IA",
      status: false,
      navigate: `/${unityExists}/AclsIA`,
      icon: false,
      img: iaAClS,
    },
  ];

  const { unidade } = useParams();

  useEffect(() => {
    const unityExists = sessionStorage.getItem("connectionsUnit");
    if (unityExists !== unidade) {
      navigate("/");
    }
    ListUnidades();
  }, [navigate, unidade]);

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("modal-open"); // Adiciona a classe overflow-hidden ao body quando o modal é aberto
    } else {
      document.body.classList.remove("modal-open"); // Remove a classe overflow-hidden do body quando o modal é fechado
    }

    // Limpa a classe quando o componente é desmontado para evitar vazamentos de memória
    return () => {
      document.body.classList.remove("modal-open");
    };
  }, [isOpen]);

  const ListUnidades = async () => {
    setloading(true);
    try {
      const token = sessionStorage.getItem("connectionsToken");
      const idUnidade = sessionStorage.getItem("connectionsUnitId");

      const headerApi = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await apiConnect.get(
        `unidade/leitura/${idUnidade}`,
        headerApi
      );
      setUnidades(response.data);
      let locUnidade = {
        lat: response.data.latitude,
        long: response.data.longitude,
      };
      sessionStorage.setItem(
        "connectionsUnitIdloc",
        JSON.stringify(locUnidade)
      );
    } catch (error) {
    } finally {
      setloading(false);
    }
  };

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const unidadesComStatus = ListUnidade.map((list, index) => {
    const aplicacaoContratada =
      Unidades && Unidades.aplicacoes && Unidades.aplicacoes[index] === "true";

    return {
      ...list,
      aplicacaoContratada, // Adiciona o status de habilitação
    };
  });

  // Ordena com habilitados primeiro
  const sortedList = unidadesComStatus.sort((a, b) => {
    return (b.aplicacaoContratada ? 1 : 0) - (a.aplicacaoContratada ? 1 : 0);
  });

  return (
    <UnitStyled>
      <Header2 />
      <HeaderMobile />
      {loading && <SquareLoading />}
      {isOpen ? <ModalHeader onClick={() => setIsOpen(!isOpen)} /> : null}
      <div className="HeaderHome">
        <div className="Logo">
          <img src={logoAcls} alt="" />
        </div>
        <h2>Cerro</h2>
        <div>
          <button
            onClick={() => {
              sessionStorage.removeItem("connectionsUnit");
              navigate("/");
            }}
          >
            Trocar Unidade
          </button>
        </div>
      </div>
      <div className="listUnidades">
        <ul>
          {sortedList.map((list, index) => (
            <li
              onClick={() => {
                if (list.aplicacaoContratada) {
                  navigate(list.navigate);
                } else {
                  setModalAvisoOpen(!modalAvisoOpen);
                }
              }}
              className={list.aplicacaoContratada ? "" : "listDesativat"}
              key={index}
            >
              <h3>{list.titulo}</h3>
              {list.icon ? list.img : <img src={list.img} alt="" />}
              <span>
                {list.aplicacaoContratada ? "Contratado" : "Não Contratado"}
              </span>
            </li>
          ))}
        </ul>
      </div>

      <Footer relative={windowWidth >= 650 ? "true" : "false"} />
    </UnitStyled>
  );
};
