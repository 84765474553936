import React, { useEffect, useState } from "react";
import { SquareLoading } from "./loaders";

const GoogleMap3D = ({ latitude, longitude }) => {
  const [mapSrc, setMapSrc] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

    if (!apiKey) {
      setError("A chave da API não foi carregada.");
      setLoading(false);
      return;
    }

    fetch("/map.html")
      .then((response) => response.text())
      .then((html) => {
        const updatedHtml = html
          .replace("{{API_KEY}}", apiKey)
          .replace("{{LATITUDE}}", latitude)
          .replace("{{LONGITUDE}}", longitude);
        const blob = new Blob([updatedHtml], { type: "text/html" });
        const url = URL.createObjectURL(blob);

        setMapSrc(url);
        setLoading(false);
      })
      .catch((err) => {
        setError("Ocorreu um erro ao carregar o mapa.");
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <SquareLoading />;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <iframe
      src={mapSrc}
      title="Google Map 3D"
      style={{ width: "100%", height: "100vh", border: "none" }}
    ></iframe>
  );
};

export default GoogleMap3D;
