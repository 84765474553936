import React, { useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import { RoutesMain } from "./routers";
import GlobalStyle from "./styles/global";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Helmet } from "react-helmet";

function App() {
  return (
    <BrowserRouter>
      <Helmet>
        <link
          href="https://fonts.googleapis.com/css2?family=Jacquarda+Bastarda+9&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <GlobalStyle />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <RoutesMain />
    </BrowserRouter>
  );
}

export default App;
