import { IoCloseCircleOutline, IoNotificationsCircle } from "react-icons/io5";
import { Header2 } from "../../components/header2";
import { TanqueStyled } from "../../styles/tanque/tanques";
import React, { useContext, useState } from "react";
import { TanqueContextC } from "../../contexts/contextTanque";
import {
  ModalAlertas,
  ModalConfimacaoBombaTanque,
  ModalStatus,
} from "./modals";
import {
  GraficosTanque,
  GraficosTanquesC,
} from "../../components/graficoTanques";
import { LuPower, LuPowerOff } from "react-icons/lu";
import { Footer } from "../../components/footer";
import { SquareLoading } from "../../components/loaders";
import { PiArrowsClockwiseBold } from "react-icons/pi";
import { HeaderMobile } from "../../components/mobileHeader";

export const Tanque = () => {
  const {
    setModalAlertas,
    modalAlertas,
    ListTanques,
    tanquesList,
    setModalStatus,
    modalStatus,
    bombasTanqueList,
    alertaList,
    bombaControlerList,
    modalConfimetionBomba,
    setModalConfimationBomba,
    setIdControlerBomba,
  } = useContext(TanqueContextC);

  const [graficoName, setGraficoName] = useState("Todos os Tanques");
  const [tanqueId, setTanqueId] = useState(null);
  const [mensagemModal, setmensagemModal] = useState(null);

  if (!tanquesList || !bombasTanqueList) {
    return (
      <TanqueStyled>
        <SquareLoading />
      </TanqueStyled>
    );
  }

  let statusTanques = tanquesList.map((tanque) => {
    return {
      nome: tanque.name,
      status: tanque.status,
    };
  });

  if (tanquesList.length > 0 && bombaControlerList.length === 0) {
    return (
      <TanqueStyled>
        <SquareLoading />
      </TanqueStyled>
    );
  }

  return (
    <TanqueStyled>
      <Header2 />
      <HeaderMobile />
      {modalAlertas && <ModalAlertas alertas={tanquesList} />}
      {modalStatus && <ModalStatus statusTanque={statusTanques} />}
      {modalConfimetionBomba && (
        <ModalConfimacaoBombaTanque mensagem={mensagemModal} />
      )}
      <section className="sectionTanque">
        <div className="avisos">
          <IoNotificationsCircle
            onClick={() => setModalAlertas(!modalAlertas)}
          />
        </div>
        <div className="containerSuperior">
          {graficoName !== "Todos os Tanques" && (
            <span
              onClick={() => {
                setGraficoName("Todos os Tanques");
                ListTanques();
              }}
              className="close"
            >
              <IoCloseCircleOutline />
            </span>
          )}

          <div className="graficos">
            {graficoName === "Todos os Tanques" ? (
              <GraficosTanquesC TanquesData={tanquesList} />
            ) : (
              <GraficosTanque TanqueDataId={tanqueId} />
            )}
          </div>
          <div className="containerAlertas">
            <div className="alertas">
              <h4>Últimos alertas dos tanques</h4>
              <div>
                {alertaList.slice(-10).map((alert) => {
                  return (
                    <span key={alert.id}>
                      {`${alert.dia} ${alert.hora
                        .split(":")
                        .slice(0, 2)
                        .join(":")}
                        ${alert.alerta}`}
                    </span>
                  );
                })}
              </div>
            </div>
            <div className="buttons">
              <button
                onClick={() => {
                  setGraficoName("Todos os Tanques");
                }}
                className="btnStatus"
              >
                {graficoName}
              </button>
              <button
                className="btnStatus"
                onClick={() => setModalStatus(!modalStatus)}
              >
                Status dos Tanques
              </button>
            </div>
          </div>
        </div>

        <div className="containerInferior">
          <ul>
            {tanquesList.map((tanques) => {
              let bombasFilterTanque = bombasTanqueList.filter(
                (e) => e.tanque.id === tanques.id
              );
              return (
                <li key={tanques.id}>
                  <h3
                    onClick={() => {
                      setGraficoName("Todos os Tanques");
                      setTanqueId(tanques.id);
                      setGraficoName(tanques.name);
                    }}
                  >
                    {tanques.name}
                  </h3>
                  <div className="containerInfos">
                    <div className="infos">
                      <div className="info">
                        <p>Tensão</p>
                        <span>
                          {bombasFilterTanque &&
                            bombasFilterTanque[0]?.dadosBomba?.tensaoR +
                              bombasFilterTanque[0]?.dadosBomba?.tensaoS +
                              bombasFilterTanque[0]?.dadosBomba?.tensaoT / 3}
                        </span>
                      </div>
                      {bombasFilterTanque.length > 0 &&
                        bombasFilterTanque.map((bomba, index) => {
                          return (
                            <React.Fragment key={bomba.id}>
                              <div className="info">
                                <p>Corrente B{index + 1}</p>
                                <span>{bomba.dadosBomba?.corrente}</span>
                              </div>
                              <div className="info">
                                <p>Horímetro B{index + 1}</p>
                                <span>
                                  {bomba.dadosBomba?.horimetro &&
                                    bomba.dadosBomba.horimetro.split(".")[0]}
                                </span>
                              </div>
                            </React.Fragment>
                          );
                        })}
                    </div>
                    <div className="conteinerStatus">
                      {bombasFilterTanque.map((bomba, index) => {
                        const controlerBomba = bombaControlerList.find(
                          (e) => e.bomba && e.bomba.id === bomba.id
                        );
                        return (
                          <div className="status" key={bomba.id}>
                            <p>B{index + 1}</p>
                            {bomba.dadosBomba && (
                              <div className="Bombas">
                                {!bomba.dadosBomba.remoto ? (
                                  <span>Manual</span>
                                ) : (
                                  bomba.dadosBomba && (
                                    <button
                                      disabled={controlerBomba.comandoBomba}
                                      className={
                                        controlerBomba.comandoBomba
                                          ? "butonOff"
                                          : ""
                                      }
                                      onClick={() => {
                                        setIdControlerBomba(controlerBomba.id);
                                        setModalConfimationBomba(true);
                                        setmensagemModal(
                                          bomba.dadosBomba.statusBomba
                                            ? "Deligar"
                                            : "Ligar"
                                        );
                                      }}
                                    >
                                      {controlerBomba.comandoBomba ? (
                                        <PiArrowsClockwiseBold className="icon" />
                                      ) : bomba.dadosBomba.statusBomba ? (
                                        <LuPower className="icon" />
                                      ) : (
                                        <LuPowerOff className="icon" />
                                      )}
                                      {bomba.dadosBomba.statusBomba
                                        ? "ligado"
                                        : "Desligado"}
                                    </button>
                                  )
                                )}
                              </div>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </section>
      <Footer />
    </TanqueStyled>
  );
};
