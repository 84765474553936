import { useContext, useState } from "react";
import { HomeContext } from "../../contexts/contexHome";
import {
  HeaderMobilePivo,
  HeaderStyled2,
  ModalAviso,
} from "../../styles/header";
import { useNavigate } from "react-router-dom";
import { RiAdminFill } from "react-icons/ri";
import {
  IoCloseCircleOutline,
  IoExit,
  IoHome,
  IoWarningOutline,
} from "react-icons/io5";
import imgLogoACLS from "../../assets/logos/novaLogoAcls.png";
import imgLogo from "../../assets/logos/novaLogoAcls.png";
import { MdOutlineMarkUnreadChatAlt } from "react-icons/md";
import { IAContext } from "../../contexts/contexIA";
import { TbPhotoFilled } from "react-icons/tb";
import { IoIosArrowDropdown, IoIosArrowDropup } from "react-icons/io";
import pivoIa from "../../assets/icons/pivoIa (2).png";
import { SiPivotaltracker } from "react-icons/si";

export const HeaderIA = () => {
  const { user, modalAvisoOpen, setModalAvisoOpen } = useContext(HomeContext);
  const navigate = useNavigate();
  const { iaPage, setIaPage } = useContext(IAContext);

  return (
    <HeaderStyled2>
      {modalAvisoOpen && (
        <ModalAviso onClick={() => setModalAvisoOpen(!modalAvisoOpen)}>
          <div
            className="conteiner"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <IoCloseCircleOutline
              className="fecharModal"
              onClick={() => setModalAvisoOpen(!modalAvisoOpen)}
            />
            <IoWarningOutline className="icon" />
            <h3>Este serviço não foi contratado para esta unidade.</h3>
            <p>
              Entre em contato com ACLS caso tenha interesse em{" "}
              <span>contratar</span> este módulo.
            </p>
            <a
              href="https://aclstecnologia.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={imgLogoACLS} alt="" />
            </a>
          </div>
        </ModalAviso>
      )}

      <ul>
        <li
          className="logoLi"
          onClick={() => {
            navigate("/");
          }}
        >
          <img src={imgLogo} alt="" />
        </li>
        <li
          onClick={() => {
            navigate("/");
          }}
        >
          <IoHome className="iconHome" />
          <span>Home</span>
        </li>
        <li
          className={`${iaPage === "Chat" ? "select" : ""}`}
          onClick={() => {
            setIaPage("Chat");
          }}
        >
          <MdOutlineMarkUnreadChatAlt className="icon" />
          <span>Chatacls</span>
        </li>
        <li
          className={`${iaPage === "Img" ? "select" : ""}`}
          onClick={() => {
            setIaPage("Img");
          }}
        >
          <TbPhotoFilled className="icon" />
          <span>Gerador De Imagens</span>
        </li>
        <li
          className={`${iaPage === "Pivo" ? "select" : ""}`}
          onClick={() => {
            setIaPage("Pivo");
          }}
        >
          <SiPivotaltracker className="icon" />
          {/* <img src={pivoIa} alt="" /> */}
          <span>Pivo Connect</span>
        </li>
      </ul>
      <div className="sectionBottom">
        {user.is_superuser ? (
          <div className="admin">
            <RiAdminFill
              onClick={() => {
                navigate("/admin");
                // window.location.reload();
              }}
            />
            <span>Admin</span>
          </div>
        ) : null}

        <div className="exit">
          <IoExit
            onClick={() => {
              sessionStorage.removeItem("connectionsToken");
              sessionStorage.removeItem("connectionsUnit");
              navigate("/Login");
            }}
          />
          <span>Sair</span>
        </div>
      </div>
    </HeaderStyled2>
  );
};

export const HeaderMobileIA = () => {
  const { user } = useContext(HomeContext);
  const navigate = useNavigate();
  const { iaPage, setIaPage } = useContext(IAContext);

  const [barControler, setBarControler] = useState(false);

  return (
    <HeaderMobilePivo>
      {barControler && (
        <section className="additionalSection">
          <ul></ul>
          <div className="sectionBottom">
            {user.is_superuser ? (
              <div className="admin">
                <RiAdminFill
                  onClick={() => {
                    navigate("/admin");
                  }}
                />
                <span>Admin</span>
              </div>
            ) : null}
          </div>
        </section>
      )}
      <section className="fistSection">
        <ul>
          <li
            onClick={() => {
              navigate("/");
            }}
          >
            <IoHome className="iconHome" />
          </li>
          <li
            className={`${iaPage === "Chat" ? "select" : ""}`}
            onClick={() => {
              // navigate(`/${unityExists}/Painelpivos/Map`);
              setIaPage("Chat");
            }}
          >
            <MdOutlineMarkUnreadChatAlt className="icon" />
            <span>Chatacls</span>
          </li>
          <li
            className={`${iaPage === "Img" ? "select" : ""}`}
            onClick={() => {
              // navigate(`/${unityExists}/Painelpivos/Map`);
              setIaPage("Img");
            }}
          >
            <TbPhotoFilled className="icon" />
          </li>
          <li
            className={`${iaPage === "Pivo" ? "select" : ""}`}
            onClick={() => {
              setIaPage("Pivo");
            }}
          >
            <SiPivotaltracker className="icon" />
            {/* <img src={pivoIa} alt="" /> */}
          </li>
        </ul>
        <div className="sectionBottom">
          {barControler ? (
            <IoIosArrowDropdown
              onClick={() => setBarControler(!barControler)}
            />
          ) : (
            <IoIosArrowDropup onClick={() => setBarControler(!barControler)} />
          )}
          <div className="exit">
            <IoExit
              onClick={() => {
                sessionStorage.removeItem("connectionsToken");
                sessionStorage.removeItem("connectionsUnit");
                navigate("/Login");
              }}
            />
            <span>Sair</span>
          </div>
        </div>
      </section>
    </HeaderMobilePivo>
  );
};
