import { useContext, useEffect, useState } from "react";
import { PivoContext } from "../../contexts/contexPivos";
import {
  PivoController,
  PivoInfAnimation,
  Dot,
  ButtonPivo,
} from "../../styles/Pivo/pivoControler";
import { SiPivotaltracker } from "react-icons/si";
import { LuPower, LuPowerOff } from "react-icons/lu";
import { GiAnticlockwiseRotation, GiClockwiseRotation } from "react-icons/gi";
import { TbPlantOff } from "react-icons/tb";
import { FaDroplet, FaDropletSlash } from "react-icons/fa6";
import ProgressBar from "../../components/barrProgress";
import { TfiArrowCircleUp } from "react-icons/tfi";
import { PiArrowsClockwiseBold } from "react-icons/pi";
import { ModalActionspivo, ModalDefesivoAcricula } from "./modals";
import { SquareLoading } from "../../components/loaders";
import { AiOutlineAlignLeft, AiTwotoneAlert } from "react-icons/ai";

export const PivoControlers = ({ pivoComponente }) => {
  const {
    expandedPivos,
    setExpandedPivos,
    listPivosData,
    setOpenModalConfimPivo,
    openModalConfimPivo,
    setInfContolersPivoFuntion,
    setOpenModalConfimBomba,
    setInfContolersbombFuntion,
    openModalConfimBomba,
    listBombsData,
    lisBombstControlles,
    listMotoBombaData,
    setRpmsMotoBomba,
    lisMotoBombstControlles,
    listPivosControlles,
    setRpms,
    setPercetimetros,
    rpms,
    modalDefesivoOn,
    setModalDefesivoOn,
    convert4a20,
    percetimetros,
    setOpenModalConfimPivoComando,
    openModalConfimPivoComando,
    setStatusPvButton,
    ModalActions,
    setModalActions,
    ConfimeactionHorarioPico,
    setConfimeactionHorarioPico,
  } = useContext(PivoContext);

  const [pivoIdAction, setPivoIdAction] = useState(0);

  const toggleItemExpansion = (itemId) => {
    setExpandedPivos((prevExpandedItems) =>
      prevExpandedItems.includes(itemId)
        ? prevExpandedItems.filter((id) => id !== itemId)
        : [...prevExpandedItems, itemId]
    );
  };

  useEffect(() => {
    const initialPercetimetros = {};
    listPivosControlles.forEach((pivoController) => {
      initialPercetimetros[pivoController.id] = pivoController.percetimetro;
    });

    const initialRpms = {};
    lisBombstControlles.forEach((bombController) => {
      initialRpms[bombController.pivo] = bombController.rpm;
    });

    const initialRpmsMotoBomba = {};
    lisMotoBombstControlles.forEach((motoBombControllerList) => {
      initialRpmsMotoBomba[motoBombControllerList.pivo] =
        motoBombControllerList.rpm;
    });

    setRpmsMotoBomba(initialRpmsMotoBomba);
    setRpms(initialRpms);
    setPercetimetros(initialPercetimetros);
  }, [
    listPivosControlles,
    lisBombstControlles,
    lisMotoBombstControlles,
    setPercetimetros,
    setRpms,
    setRpmsMotoBomba,
  ]);

  function calculateTensoes(V1, V2, V3) {
    let beta =
      (Math.pow(V1, 4) + Math.pow(V2, 4) + Math.pow(V3, 4)) /
      Math.pow(Math.pow(V1, 2) + Math.pow(V2, 2) + Math.pow(V3, 2), 2);
    let result =
      100 *
      Math.sqrt((1 - Math.sqrt(3 - 6 * beta)) / (1 + Math.sqrt(3 - 6 * beta)));

    return isNaN(result.toFixed(1)) ? "Erro" : result.toFixed(1);
  }
  if (listPivosData === null) {
    return (
      <PivoController>
        <SquareLoading />
      </PivoController>
    );
  }

  const calcAzimuth = (lat1, lon1, lat2, lon2) => {
    // Converter graus para radianos
    var rad = Math.PI / 180;
    lat1 *= rad;
    lat2 *= rad;
    lon1 *= rad;
    lon2 *= rad;

    // Diferença de longitude
    var dLon = lon2 - lon1;

    // Cálculo do azimute
    var y = Math.sin(dLon) * Math.cos(lat2);
    var x =
      Math.cos(lat1) * Math.sin(lat2) -
      Math.sin(lat1) * Math.cos(lat2) * Math.cos(dLon);
    var azimuth = Math.atan2(y, x);

    // Converter de radianos para graus
    azimuth = azimuth / rad;

    // Ajuste para valores entre 0 e 360 graus
    if (azimuth < 0) {
      azimuth += 360;
    }

    return azimuth;
  };

  if (pivoComponente.length > 0) {
    if (listPivosData.length === 0) {
      return (
        <PivoController>
          <SquareLoading />
        </PivoController>
      );
    }
  }

  return (
    <PivoController>
      <h2>Controles</h2>
      {modalDefesivoOn && <ModalDefesivoAcricula />}
      {ModalActions && <ModalActionspivo actionPivoId={pivoIdAction} />}

      <ul>
        {pivoComponente.map((pivo) => {
          const isExpanded = expandedPivos.includes(pivo.id);

          let pivoDados = listPivosData.find((e) => pivo.id === e.pivo);
          const pivoControlers = listPivosControlles.find(
            (e) => pivo.id === e.pivo
          );
          const bombsDados = listBombsData.find((e) => pivo.id === e.pivo);
          if (!bombsDados) {
            return (
              <div>
                <SquareLoading />
              </div>
            );
          }
          //BOMBA CONTROLER NAO ATIVO AINDA
          // const bombsController = lisBombstControlles.find(
          //   (e) => pivo.id === e.pivo
          // );

          const MotobombsDados = listMotoBombaData.find(
            (e) => pivo.id === e.pivo
          );
          // const handleRpmMotoBOmbaChange = (pivoId, newValue) => {
          //   const updatedRpms = {
          //     ...rpmsMotoBomba,
          //     [pivoId]: newValue,
          //   };
          //   setRpmsMotoBomba(updatedRpms);
          // };
          const handleRpmChange = (pivoId, newValue) => {
            const updatedRpms = {
              ...rpms,
              [pivoId]: newValue,
            };
            setRpms(updatedRpms);
          };

          const handlePercentimetroChange = (pivoId, newValue) => {
            const updatedPercentimetro = {
              ...percetimetros,
              [pivoId]: newValue,
            };
            setPercetimetros(updatedPercentimetro);
          };

          let latitude_centro = pivoDados ? pivoDados.latitude_centro : null;
          let longitude_centro = pivoDados ? pivoDados.longitude_centro : null;
          let latitude_ponta = pivoDados ? pivoDados.latitude_ponta : null;
          let longitude_ponta = pivoDados ? pivoDados.longitude_ponta : null;

          let angulo = calcAzimuth(
            latitude_centro,
            longitude_centro,
            latitude_ponta,
            longitude_ponta
          );

          if (pivo.maquete === true) {
            if (pivoDados && pivoDados.rotacao_maquete !== undefined) {
              angulo = pivoDados.rotacao_maquete;
            } else {
              angulo = 0; // ou algum valor padrão
            }
          }

          return (
            <li
              key={pivo.id}
              className={`pivoConteiner ${isExpanded ? "expanded" : ""}`}
            >
              {pivoDados.seguranca && (
                <div className="securityOn">
                  <h3>
                    <AiTwotoneAlert className="iconSecurity" /> Sistema de
                    segurança ativado
                  </h3>
                </div>
              )}
              <div
                className="namePivo"
                onClick={() => toggleItemExpansion(pivo.id)}
              >
                <SiPivotaltracker className="iconePivo" />
                <h3>{pivo.name}</h3>
                {!isExpanded && (
                  <div>
                    <span>
                      | {pivoDados.status_pivo ? "Ligado" : "Desligado"}
                    </span>
                    {pivoDados.status_pivo ? <LuPower /> : <LuPowerOff />}
                  </div>
                )}
                {!isExpanded && (
                  <div>
                    <span>
                      | {pivoDados.status_agua ? "Irrigando" : "Seco"}
                    </span>
                    {pivoDados.status_agua ? <FaDroplet /> : <FaDropletSlash />}
                  </div>
                )}
                {!isExpanded && (
                  <div>
                    <span>
                      |{" "}
                      {!pivoDados.sentido_de_rotacao
                        ? "Horário"
                        : "Anti-horário"}
                    </span>
                    {!pivoDados.sentido_de_rotacao ? (
                      <GiClockwiseRotation />
                    ) : (
                      <GiAnticlockwiseRotation />
                    )}
                  </div>
                )}
                {isExpanded && <TfiArrowCircleUp />}
                {isExpanded && (
                  <div className="AttPivo">
                    <span>
                      Ultima Atualização {pivoDados.horario} / {pivoDados.data}
                    </span>
                  </div>
                )}
              </div>
              <div className="conteinerSection">
                <section className="left">
                  {pivo.tipo_bomba ? (
                    <div className="bombData">
                      <h3>Dados Eletrobomba</h3>
                      <ul>
                        <li>
                          <p>Tensão</p>
                          <span>
                            {(
                              (bombsDados.tensao_r +
                                bombsDados.tensao_s +
                                bombsDados.tensao_t) /
                              3
                            ).toFixed(1) < 0
                              ? "--"
                              : `${(
                                  (bombsDados.tensao_r +
                                    bombsDados.tensao_s +
                                    bombsDados.tensao_t) /
                                  3
                                ).toFixed(1)} V`}
                          </span>
                        </li>
                        <li>
                          <p>Des. Tensão</p>
                          <span>
                            {bombsDados.tensao_r +
                              bombsDados.tensao_s +
                              bombsDados.tensao_t <
                            0
                              ? "--"
                              : `${calculateTensoes(
                                  bombsDados.tensao_r,
                                  bombsDados.tensao_s,
                                  bombsDados.tensao_t
                                )} %`}
                          </span>
                        </li>
                        <li>
                          <p>Corrente</p>
                          <span>
                            {bombsDados.corrente < 0
                              ? "--"
                              : `${bombsDados.corrente} A`}
                          </span>
                        </li>
                        <li>
                          <p>Pressão</p>
                          <span>
                            {bombsDados.pressao < 0
                              ? "--"
                              : `${convert4a20(bombsDados.pressao)} Bar`}
                          </span>
                        </li>
                      </ul>
                    </div>
                  ) : (
                    <div className="bombData">
                      <h3>Dados Motobomba</h3>
                      <ul>
                        <li>
                          <p>Temperatura</p>
                          <span>
                            {MotobombsDados && MotobombsDados.Temperatura_Motor}
                          </span>
                        </li>
                        <li>
                          <p>Pressão Diesel</p>
                          <span>
                            {MotobombsDados && MotobombsDados.Pressao_Diesel}
                          </span>
                        </li>
                        <li>
                          <p>Nível Diesel</p>
                          <span>
                            {MotobombsDados && MotobombsDados.Nivel_Diesel}
                          </span>
                        </li>
                        <li>
                          <p>Pressão</p>
                          <span>
                            {MotobombsDados && MotobombsDados.Pressao_Agua}
                          </span>
                        </li>
                      </ul>
                    </div>
                  )}
                  <div className="rpmBomb">
                    <h3>RPM Bomba</h3>
                    <div className="Variables">
                      <input
                        type="number"
                        value={rpms[pivo.id]}
                        onChange={(e) =>
                          handleRpmChange(pivo.id, e.target.value)
                        }
                        min="1000"
                        max="1900"
                      />
                      <button
                        onClick={() => {
                          setOpenModalConfimBomba(!openModalConfimBomba);
                          setInfContolersbombFuntion([pivo.id, "rpm"]);
                        }}
                      >
                        Alterar RPM
                      </button>
                    </div>
                    <div>
                      <ProgressBar
                        min={0}
                        max={2000}
                        value={bombsDados.rpm}
                        tipo={"RPM"}
                      />
                    </div>
                  </div>
                  {pivoDados.limite_de_voltas && (
                    <div className="lapCheck">
                      <span>
                        {pivoDados.limite_voltas - pivoDados.voltas} Voltas
                        restantes do agendamento
                      </span>
                    </div>
                  )}
                </section>
                <section className="center">
                  <div
                    className="ActionsPivo"
                    onClick={() => {
                      setPivoIdAction(pivo.id);
                      setModalActions(true);
                    }}
                  >
                    <AiOutlineAlignLeft className="icon" />
                    <span>Comandos realizados</span>
                  </div>
                  <div className="pivo">
                    <PivoInfAnimation
                      statusPivo={pivoDados.status_pivo}
                      aguaStatus={pivoDados.status_agua}
                      rotationPivo={!pivoDados.sentido_de_rotacao}
                      angle={angulo}
                      angleVolta={pivoDados.volta_inicial}
                    >
                      <span className="borderPivo"></span>
                      <div className="pivoAnimation">
                        <span className="ponteiro"></span>
                        {pivoDados.limite_de_voltas && (
                          <span className="voltaInicial"></span>
                        )}
                        <span className="bolinha"></span>
                      </div>
                    </PivoInfAnimation>
                  </div>
                  <div className="pivoControle">
                    <ul>
                      {pivoDados.liberacao ? (
                        <li className="off">
                          <p>{pivoDados.status_pivo ? "Ativo" : "Inativo"}</p>
                          <ButtonPivo
                            status={pivoDados.status_pivo}
                            cor={"rgba(62, 90, 66, 0.8)"}
                            disabled={
                              pivoControlers.pivo_seco ||
                              pivoControlers.pivo_irrigando ||
                              pivoDados.seguranca
                            }
                            onClick={() => {
                              const agora = new Date();
                              const horaAtual = agora.getHours();
                              if (
                                horaAtual >= 18 &&
                                horaAtual < 21 &&
                                !pivoDados.status_pivo
                              ) {
                                setConfimeactionHorarioPico(true);
                                setInfContolersPivoFuntion([
                                  pivo.id,
                                  "pivo_seco",
                                ]);
                              } else {
                                setOpenModalConfimPivoComando(
                                  !openModalConfimPivoComando
                                );
                                setStatusPvButton(pivoDados.status_pivo);
                                setInfContolersPivoFuntion([
                                  pivo.id,
                                  "pivo_seco",
                                ]);
                              }
                            }}
                          >
                            <Dot isLeft={pivoDados.status_pivo}>
                              {pivoControlers.pivo_seco ||
                              pivoControlers.pivo_irrigando ? (
                                <PiArrowsClockwiseBold className="iconloading" />
                              ) : pivoDados.status_pivo ? (
                                <LuPower className="icon" />
                              ) : (
                                <LuPowerOff className="icon" />
                              )}
                            </Dot>
                          </ButtonPivo>
                        </li>
                      ) : (
                        <li>
                          <p>{pivoDados.status_pivo ? "Ativo" : "Inativo"}</p>
                          <ButtonPivo
                            status={pivoDados.status_pivo}
                            cor={"rgba(62, 90, 66, 0.8)"}
                            disabled={true}
                            onClick={() => {
                              setOpenModalConfimPivo(!openModalConfimPivo);
                              setStatusPvButton(pivoDados.status_pivo);
                              setInfContolersPivoFuntion([
                                pivo.id,
                                "pivo_seco",
                              ]);
                            }}
                          >
                            <Dot isLeft={pivoDados.status_pivo}>
                              {pivoControlers.pivo_seco ||
                              pivoControlers.pivo_irrigando ? (
                                <PiArrowsClockwiseBold className="iconloading" />
                              ) : pivoDados.status_pivo ? (
                                <LuPower className="icon" />
                              ) : (
                                <LuPowerOff className="icon" />
                              )}
                            </Dot>
                          </ButtonPivo>
                        </li>
                      )}
                      {pivoDados.liberacao ? (
                        <li>
                          <p>{pivoDados.status_agua ? "Irrigando" : "Seco"}</p>
                          <ButtonPivo
                            status={pivoDados.status_agua}
                            cor={"rgba(35, 104, 174, 0.3)"}
                            disabled={
                              pivoControlers.agua ||
                              pivoControlers.pivo_irrigando ||
                              pivoDados.seguranca
                            }
                            onClick={() => {
                              setOpenModalConfimPivo(!openModalConfimPivo);
                              setInfContolersPivoFuntion([pivo.id, "agua"]);
                            }}
                          >
                            <Dot isLeft={pivoDados.status_agua}>
                              {pivoControlers.agua ||
                              pivoControlers.pivo_irrigando ? (
                                <PiArrowsClockwiseBold className="iconloading" />
                              ) : pivoDados.status_agua ? (
                                <FaDroplet className="icon" />
                              ) : (
                                <FaDropletSlash className="icon" />
                              )}
                            </Dot>
                          </ButtonPivo>
                        </li>
                      ) : (
                        <li>
                          <p>{pivoDados.status_agua ? "Irrigando" : "Seco"}</p>
                          <ButtonPivo
                            status={pivoDados.status_agua}
                            cor={"rgba(35, 104, 174, 0.3)"}
                            disabled={true}
                            onClick={() => {
                              setOpenModalConfimPivo(!openModalConfimPivo);
                              setInfContolersPivoFuntion([pivo.id, "agua"]);
                            }}
                          >
                            <Dot isLeft={pivoDados.status_agua}>
                              {pivoControlers.agua ||
                              pivoControlers.pivo_irrigando ? (
                                <PiArrowsClockwiseBold className="iconloading" />
                              ) : pivoDados.status_agua ? (
                                <FaDroplet className="icon" />
                              ) : (
                                <FaDropletSlash className="icon" />
                              )}
                            </Dot>
                          </ButtonPivo>
                        </li>
                      )}
                    </ul>
                    <ul>
                      {pivoDados.liberacao ? (
                        <li>
                          <p>
                            {!pivoDados.sentido_de_rotacao
                              ? "Horário"
                              : "Anti-horário"}
                          </p>
                          <ButtonPivo
                            disabled={
                              pivoControlers.sentido_de_rotacao ||
                              pivoDados.seguranca
                            }
                            onClick={() => {
                              setOpenModalConfimPivo(!openModalConfimPivo);
                              setInfContolersPivoFuntion([
                                pivo.id,
                                "sentido_de_rotacao",
                              ]);
                            }}
                          >
                            <Dot isLeft={!pivoDados.sentido_de_rotacao}>
                              {pivoControlers.sentido_de_rotacao ? (
                                <PiArrowsClockwiseBold className="iconloading" />
                              ) : !pivoDados.sentido_de_rotacao ? (
                                <GiClockwiseRotation className="icon" />
                              ) : (
                                <GiAnticlockwiseRotation className="icon" />
                              )}
                            </Dot>
                          </ButtonPivo>
                        </li>
                      ) : (
                        <li>
                          <p>
                            {!pivoDados.sentido_de_rotacao
                              ? "Horário"
                              : "Anti-horário"}
                          </p>
                          <ButtonPivo
                            disabled={true}
                            onClick={() => {
                              setOpenModalConfimPivo(!openModalConfimPivo);
                              setInfContolersPivoFuntion([
                                pivo.id,
                                "sentido_de_rotacao",
                              ]);
                            }}
                          >
                            <Dot isLeft={!pivoDados.sentido_de_rotacao}>
                              {pivoControlers.sentido_de_rotacao ? (
                                <PiArrowsClockwiseBold className="iconloading" />
                              ) : !pivoDados.sentido_de_rotacao ? (
                                <GiClockwiseRotation className="icon" />
                              ) : (
                                <GiAnticlockwiseRotation className="icon" />
                              )}
                            </Dot>
                          </ButtonPivo>
                        </li>
                      )}
                      {pivoDados.liberacao ? (
                        <li>
                          <p>Def. Agrícola OFF</p>
                          <ButtonPivo
                            onClick={() => setModalDefesivoOn(!modalDefesivoOn)}
                            disabled={pivoDados.seguranca}
                          >
                            <Dot>
                              <TbPlantOff className="icon" />
                            </Dot>
                          </ButtonPivo>
                        </li>
                      ) : (
                        <li>
                          <p>Def. Agrícola OFF</p>
                          <ButtonPivo
                            onClick={() => setModalDefesivoOn(!modalDefesivoOn)}
                            disabled={true}
                          >
                            <Dot>
                              <TbPlantOff className="icon" />
                            </Dot>
                          </ButtonPivo>
                        </li>
                      )}
                    </ul>
                    {!pivoDados.liberacao && (
                      <span className="liberacao">Controles Desligados</span>
                    )}
                  </div>
                  <div className="Percentimetro">
                    <p>Percentimetro</p>
                    <div className="Variables">
                      <input
                        type="number"
                        value={percetimetros[pivoControlers.id]}
                        onChange={(e) =>
                          handlePercentimetroChange(pivo.id, e.target.value)
                        }
                        min="0"
                        max="100"
                      />
                      <button
                        onClick={() => {
                          setOpenModalConfimPivo(!openModalConfimPivo);
                          setInfContolersPivoFuntion([pivo.id, "percetimetro"]);
                        }}
                        disabled={pivoDados.seguranca}
                      >
                        Alterar
                      </button>
                    </div>
                  </div>
                </section>
                <section className="right">
                  <h3>Dados Geral</h3>
                  <ul>
                    <li>
                      <p>Temp. Amb</p>
                      <span>--</span>
                    </li>
                    <li>
                      <p>Umidade</p>
                      <span>--</span>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <p>Temp. Circ</p>

                      <span>--</span>
                    </li>
                    <li>
                      <p>Vazão</p>
                      <span>--</span>
                    </li>
                  </ul>
                  <div className="generalData">
                    <h3>Dados Pivô</h3>
                    <ul>
                      <li>
                        <p>Tensão</p>
                        <span>
                          {(
                            (pivoDados.tensao_r +
                              pivoDados.tensao_s +
                              pivoDados.tensao_t) /
                            3
                          ).toFixed(1) < 0
                            ? "--"
                            : `${(
                                (pivoDados.tensao_r +
                                  pivoDados.tensao_s +
                                  pivoDados.tensao_t) /
                                3
                              ).toFixed(1)} v`}
                        </span>
                      </li>
                      <li>
                        <p>Des. Tensão</p>
                        <span>
                          {pivoDados.tensao_r +
                            pivoDados.tensao_s +
                            pivoDados.tensao_t <
                          0
                            ? "--"
                            : `${calculateTensoes(
                                pivoDados.tensao_r,
                                pivoDados.tensao_s,
                                pivoDados.tensao_t
                              )}%`}
                        </span>
                      </li>
                    </ul>
                    <ul>
                      <li>
                        <p>Corrente</p>
                        <span>
                          {pivoDados.corrente < 0
                            ? "--"
                            : `${pivoDados.corrente} A`}
                        </span>
                      </li>
                      <li>
                        <p>Percentimetro</p>
                        <span>
                          {pivoDados.percetimetro < 0
                            ? "--"
                            : `${pivoDados.percetimetro} %`}
                        </span>
                      </li>
                    </ul>
                    <ul>
                      <li>
                        <p>Pressão C.</p>
                        <span>
                          {pivoDados.pressao_centro < 0
                            ? "--"
                            : `${convert4a20(pivoDados.pressao_centro)} Bar`}
                        </span>
                      </li>
                      <li>
                        <p>Pressão P.</p>
                        <span>
                          {pivoDados.pressao_ponta < 0
                            ? "--"
                            : `${convert4a20(pivoDados.pressao_ponta)} Bar`}
                        </span>
                      </li>
                    </ul>
                  </div>
                </section>
              </div>
            </li>
          );
        })}
      </ul>
    </PivoController>
  );
};
