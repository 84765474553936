import { useContext, useEffect } from "react";
import { apiConnect } from "../../services/api";
import { AdminTanque, Dot } from "../../styles/admin";
import { IoAddCircle } from "react-icons/io5";
import imgTanque from "../../assets/icons/Novotanque.png";
import { useState } from "react";
import { CiEdit } from "react-icons/ci";
import { ImBin } from "react-icons/im";
import { AdminContext } from "../../contexts/contexAdmin";

export const TanqueAdmin = () => {
  const {
    modalConfimation,
    setModalConfimation,
    setSalveTanqueInfo,
    setModalMedidores,
    setMedidoreslist,
    ListTanques,
    tanquesList,
    setTanquesList,
    modalBombaTanque,
    setModalBombaTaque,
    modalCreatTanque,
    setModalCreatTanque,
  } = useContext(AdminContext);

  const [editedTanques, SetEditedTanques] = useState({});
  const [Unidades, setUnidades] = useState([]);

  const ListUnidades = async () => {
    try {
      const token = sessionStorage.getItem("connectionsToken");
      const idUnidade = sessionStorage.getItem("connectionsUnitId");

      const headerApi = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await apiConnect.get(`unidade/leituratodos`, headerApi);
      setUnidades(response.data);
    } catch (error) {
    } finally {
    }
  };

  useEffect(() => {
    ListTanques();
    ListUnidades();
  }, []);

  const handleInputChange = (e, tanqueId, type) => {
    SetEditedTanques((prevEditedTanque) => ({
      ...prevEditedTanque,
      [tanqueId]: {
        ...prevEditedTanque[tanqueId],
        [type]: e,
      },
    }));
  };

  useEffect(() => {
    const initialEditedPivos = {};
    tanquesList.forEach((tanque) => {
      initialEditedPivos[tanque.id] = { ...tanque };
    });
    SetEditedTanques(initialEditedPivos);
  }, [tanquesList]);
  return (
    <AdminTanque>
      <div className="Header">
        <h2>Tanques</h2>
        <div className="addIcon">
          <div className="icons" onClick={() => setModalCreatTanque(true)}>
            <img src={imgTanque} alt="" />
            <IoAddCircle className="ADDIcon" />
          </div>
          <span>Criar Um Novo Tanque</span>
        </div>
      </div>
      <ul>
        {tanquesList.map((tanque) => {
          const cancelChanges = () => {
            SetEditedTanques((prevEditedTanques) => ({
              ...prevEditedTanques,
              [tanque.id]: {
                ...prevEditedTanques[tanque.id],
                name: tanque.name,
                longitude: tanque.longitude,
                latitude: tanque.latitude,
                altura_util: tanque.altura_util,
                altura: tanque.altura,
                status: tanque.status,
              },
            }));
          };
          const checkChangesTanque =
            JSON.stringify(tanque) === JSON.stringify(editedTanques[tanque.id]);

          const nameUnidade = Unidades.find((e) => e.id === tanque.unidade.id);

          return (
            <li>
              <div className="headerTanqueInfo">
                <input
                  type="text"
                  id={`${tanque.id}name`}
                  value={tanque.name || ""}
                  // onChange={(e) =>
                  //   handleInputChange(e.target.value, pivo.id, "name")
                  // }
                />
                <label htmlFor={`${tanque.id}name`}>
                  <CiEdit />
                </label>
                <ImBin
                  className="iconBin"
                  // onClick={() => {
                  //   setModalPivoDeletConfirmation(!modalPivoDeletConfirmation);
                  //   setSavePivoId(pivo.id);
                  // }}
                />
              </div>
              <div className="infosPivo">
                <label htmlFor={`${tanque.id}altura`}>altura</label>
                <input
                  type="number"
                  id={`${tanque.id}altura`}
                  value={editedTanques[tanque.id]?.altura}
                  onChange={(e) =>
                    handleInputChange(e.target.value, tanque.id, "altura")
                  }
                />
              </div>
              <div className="infosPivo">
                <label htmlFor={`${tanque.id}altura_util`}>altura util</label>
                <input
                  type="number"
                  id={`${tanque.id}altura_util`}
                  value={editedTanques[tanque.id]?.altura_util}
                  onChange={(e) =>
                    handleInputChange(e.target.value, tanque.id, "altura_util")
                  }
                />
              </div>
              <div className="infosPivo">
                <label htmlFor={`${tanque.id}latitude`}>Latitude</label>
                <input
                  type="number"
                  id={`${tanque.id}latitude`}
                  value={editedTanques[tanque.id]?.latitude}
                  onChange={(e) =>
                    handleInputChange(e.target.value, tanque.id, "latitude")
                  }
                />
              </div>
              <div className="infosPivo">
                <label htmlFor={`${tanque.id}longitude`}>Longitude</label>
                <input
                  type="number"
                  id={`${tanque.id}longitude`}
                  value={editedTanques[tanque.id]?.longitude}
                  onChange={(e) =>
                    handleInputChange(e.target.value, tanque.id, "longitude")
                  }
                />
              </div>
              <div className="infosPivo">
                <label htmlFor={`${tanque.id}unidade`}>Unidade</label>
                <input
                  type="text"
                  id={`${tanque.id}unidade`}
                  disabled={true}
                  value={nameUnidade ? nameUnidade.name : ""}
                />
              </div>
              <div className="infosPivo">
                <p>Status do Tanque</p>
                <div className="selectBomb">
                  <span className="select">Ativado</span>
                  <button
                    onClick={() =>
                      handleInputChange(
                        !editedTanques[tanque.id].status,
                        tanque.id,
                        "status"
                      )
                    }
                  >
                    <Dot isLeft={editedTanques[tanque.id]?.status} />
                  </button>
                  <span className="select">Desativado</span>
                </div>
              </div>
              <div className="buttons ">
                <button
                  className="bombsSelect"
                  onClick={() => {
                    setMedidoreslist(tanque);
                    setModalMedidores(true);
                  }}
                >
                  Medidores
                </button>
                <button
                  className="bombsSelect"
                  onClick={() => {
                    setMedidoreslist(tanque);
                    setModalBombaTaque(true);
                  }}
                >
                  Bombas
                </button>
              </div>
              <div className="buttons">
                <button
                  className={checkChangesTanque ? "off" : ""}
                  disabled={checkChangesTanque}
                  onClick={() => {
                    setModalConfimation(!modalConfimation);
                    setSalveTanqueInfo([tanque.id, editedTanques[tanque.id]]);
                  }}
                >
                  Salvar
                </button>
                <button
                  disabled={checkChangesTanque}
                  onClick={() => cancelChanges()}
                >
                  Cancelar
                </button>
              </div>
            </li>
          );
        })}
      </ul>
    </AdminTanque>
  );
};
