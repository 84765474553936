import styled from "styled-components";
import { css } from "styled-components";

export const HeaderStyled = styled.header.withConfig({
  shouldForwardProp: (prop) => !["relative"].includes(prop),
})`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 60px;
  max-width: 100%;
  max-height: 100%;
  /* background-color: rgba(3, 3, 3, 0.6); */
  z-index: 5;
  ${({ relative }) =>
    relative
      ? css`
          position: absolute;
          top: 0;
        `
      : css`
          position: relative;
        `}
  .headerLeft {
    display: flex;
    align-items: center;
    height: 100vh;
    max-height: 100%;
    max-width: 100%;
    .hambuguerMenu {
      display: flex;
      flex-direction: column;
      width: 350px;
      max-width: 80%;
      height: 100vh;
      overflow-y: auto;
      top: 0;
      position: absolute;
      background-color: rgba(0, 0, 0, 0.8);
      .logoMenu {
        display: flex;
        height: 60px;
        align-items: center;
        border-bottom: 1px solid #d6d5d5;
        p {
          color: white;
          font-size: 18px;
          font-weight: 500;
          padding-right: 20px;
        }
        .imgLogo {
          display: flex;
          width: 100px;
          justify-content: center;
          align-items: center;
          height: 60px;
          img {
            width: 50px;
            height: 50px;
            max-height: 100%;
          }
        }
      }
      .mainMenu {
        display: flex;
        width: 100%;
        gap: 10px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 15px;
        border-bottom: 1px solid #d6d5d5;

        span {
          color: #d6d5d5;
          font-size: 18px;
        }

        div {
          display: flex;
          justify-content: center;
          width: 100%;
          gap: 5px;
          border-left: 4px solid transparent;
          padding: 10px 0;
          margin-bottom: 15px;
          transition: 0.3s;

          span {
            font-size: 18px;
          }
        }
        div:hover {
          background-color: rgba(255, 255, 255, 0.21);
          cursor: pointer;
          border-left: 4px solid white;
        }
        .iconHome {
          color: #d6d5d5;
          font-size: 22px;
        }
      }
      .listApllications {
        width: 100%;
        margin-top: 10px;
        height: 80%;
        max-height: 100%;

        ul {
          display: flex;
          flex-direction: column;
          gap: 20px;
          max-height: 100%;
          .isActive {
            opacity: 100%;
          }
          li {
            display: flex;
            align-items: center;
            padding-left: 20px;
            border-left: 4px solid transparent;
            gap: 15px;
            opacity: 30%;
            img {
              width: 60px;
              height: 60px;
            }
            p {
              color: #d6d5d5;
            }
          }
          li:hover {
            background-color: rgba(255, 255, 255, 0.21);
            cursor: pointer;
            border-left: 4px solid white;
          }
        }
      }
    }
    .hambugerConteiner {
      padding-left: 30px;
      .true {
        padding-left: 330px;

        transition: 0.5s;
      }
    }
  }
  .headerRight {
    display: flex;
    align-items: center;
    gap: 10px;
    padding-right: 40px;
    max-width: 100%;
    span {
      color: white;
      font-size: 18px;
    }
    .iconHeader {
      color: #d6d5d5;
      font-size: 35px;
    }
    .iconExit {
      color: #d6d5d5;
      font-size: 30px;
      cursor: pointer;
    }
    .iconExit:hover {
      color: white;
    }
  }
  @media (max-width: 800px) {
    .headerRight {
      padding-right: 10px;
    }
  }

  @media (max-width: 520px) {
    .headerLeft {
      .hambugerConteiner {
        padding-left: 20px;
        max-width: 100%;
        .true {
          padding-left: 0px;
          position: absolute;
          left: 72%;
          top: 16px;
        }
      }
    }
  }
`;

export const HamburgerMenuContainer = styled.div`
  width: 20px;
  height: 18px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;

  &.open span:first-child {
    transform: translateY(10px) rotate(-45deg);
  }

  &.open span:nth-child(2) {
    opacity: 0;
  }

  &.open span:last-child {
    transform: translateY(-5px) rotate(45deg);
  }
`;

export const Bar = styled.span`
  width: 25px;
  height: 3px;
  background-color: white;
  transition: transform 0.5s ease;
  border-radius: 20px;
`;

export const ModalHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  max-height: 100%;
  background-color: rgba(18, 18, 20, 0.5);
  z-index: 4;
  cursor: pointer;
`;

export const HeaderStyled2 = styled.header`
  position: fixed;
  width: 60px;
  background-color: rgba(30, 30, 30, 0.8);
  height: 99vh;
  z-index: 10;
  top: 0.5%;
  left: 0;
  border-radius: 0px 30px 30px 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 30px 0;
  color: white;
  font-size: 30px;
  transition: max-height 1s ease;

  ${({ open }) =>
    open
      ? css`
          max-height: 95px;
          overflow: hidden;
          li {
            opacity: 0;
          }
          .logoLi {
            opacity: 1;
          }
        `
      : css`
          max-height: 100%;
          li {
            opacity: 1;
          }
        `}
  ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    .iconImgs {
      img {
        max-width: 60%;
      }
    }
    .logoLi {
      width: 65%;
    }
    .disabled {
      opacity: 40%;
    }
    li {
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;
      border-bottom: 3px solid transparent;
      width: 80%;
      padding-bottom: 5px;
      transition: 1s;
      .alerts {
        width: 16px;
        height: 16px;
        display: flex;

        justify-content: center;
        align-items: center;
        position: absolute;
        right: 15px;
        background-color: rgba(246, 142, 42, 1);
        font-size: 12px;
        border-radius: 50%;
        animation: puff-in-center 0.4s cubic-bezier(0.47, 0, 0.745, 0.715) both;
      }
      img {
        max-width: 75%;
        margin-bottom: 10px;
      }
      .iconHome {
        margin-bottom: 5px;
      }
      span {
        display: none;
        position: absolute;
        left: 62px;
        font-size: 18px;
        background-color: rgba(30, 30, 30, 0.8);
        padding: 10px;
        border-radius: 10px;
        transition: 1s;
      }
      .icon {
        font-size: 26px;
      }
      &:hover {
        span {
          display: inline;
        }
      }
    }
  }

  li:hover {
    border-bottom: 3px solid white;
  }
  .select {
    border-bottom: 3px solid white;
  }
  .sectionBottom {
    cursor: pointer;
    .admin {
      font-size: 25px;
      margin-bottom: 10px;
      span {
        display: none;
        position: absolute;
        left: 50px;
        font-size: 18px;
        background-color: rgba(30, 30, 30, 0.8);
        padding: 10px;
        border-radius: 10px;
        transition: 1s;
      }
      &:hover {
        span {
          display: inline;
        }
      }
    }
    .exit {
      position: relative;
      span {
        display: none;
        position: absolute;
        left: 50px;
        font-size: 18px;
        background-color: rgba(30, 30, 30, 0.8);
        padding: 10px;
        border-radius: 10px;
        transition: 1s;
      }
      &:hover {
        span {
          display: inline;
        }
      }
    }
  }
  .logoLi {
    border: none;
  }
  .logoLi:hover {
    border: none;
  }
  @media (max-width: 650px) {
    display: none;
  }
`;

export const HeaderMobilePivo = styled.header`
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 10;
  scrollbar-width: none;
  .additionalSection {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 55px;
    padding-right: 5px;
    padding-top: 5px;
    background-color: rgba(29, 29, 29, 1);
    border-top: 2px solid rgba(192, 192, 192, 0.5);
    animation: slide-in-blurred-bottom 0.6s cubic-bezier(0.23, 1, 0.32, 1) both;
    z-index: 2;
    ul {
      display: flex;
      gap: 10px;
      padding-left: 5px;
      li {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border-bottom: 5px solid transparent;
        position: relative;
        width: 60px;
        padding-bottom: 5px;
        transition: 1s;
        font-size: 35px;
        color: rgba(228, 228, 228, 1);
        img {
          max-width: 75%;
          margin-bottom: 10px;
        }
        .iconHome {
          margin-bottom: 5px;
        }
        span {
          display: none;
          position: absolute;
          left: 62px;
          font-size: 18px;
          background-color: rgba(30, 30, 30, 0.8);
          padding: 10px;
          border-radius: 10px;
          transition: 1s;
        }
        &:hover {
          span {
            display: inline;
          }
        }
        .Alertas {
          position: relative;
        }
        .alerts {
          width: 18px;
          height: 18px;
          display: flex;

          justify-content: center;
          align-items: center;
          position: absolute;
          top: 8px;
          right: 10px;
          background-color: rgba(246, 142, 42, 1);
          font-size: 12px;
          border-radius: 50%;
          animation: puff-in-center 0.4s cubic-bezier(0.47, 0, 0.745, 0.715)
            both;
        }
      }
      .select {
        border-bottom: 7px solid white;
      }
    }
    .sectionBottom {
      cursor: pointer;
      display: flex;
      gap: 10px;
      font-size: 35px;
      color: rgba(228, 228, 228, 1);
      .admin {
        margin-bottom: 10px;
        span {
          display: none;
          position: absolute;
          left: 50px;
          font-size: 18px;
          background-color: rgba(30, 30, 30, 0.8);
          padding: 10px;
          border-radius: 10px;
          transition: 1s;
        }
        &:hover {
          span {
            display: inline;
          }
        }
      }
      .exit {
        position: relative;
        span {
          display: none;
          position: absolute;
          left: 50px;
          font-size: 18px;
          background-color: rgba(30, 30, 30, 0.8);
          padding: 10px;
          border-radius: 10px;
          transition: 1s;
        }
        &:hover {
          span {
            display: inline;
          }
        }
      }
    }
  }

  .fistSection {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 100%;
    overflow: auto;
    height: 55px;
    padding-right: 5px;
    padding-top: 5px;
    background-color: rgba(29, 29, 29, 1);
    color: rgba(228, 228, 228, 1);
    z-index: 99;
    ul {
      display: flex;
      gap: 5px;
      padding-left: 5px;
      li {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border-bottom: 5px solid transparent;
        position: relative;
        width: 60px;
        padding-bottom: 5px;
        transition: 1s;
        font-size: 35px;
        color: rgba(228, 228, 228, 1);
        img {
          max-width: 75%;
          margin-bottom: 10px;
        }
        .iconHome {
          margin-bottom: 5px;
        }
        span {
          display: none;
          position: absolute;
          left: 62px;
          font-size: 18px;
          background-color: rgba(30, 30, 30, 0.8);
          padding: 10px;
          border-radius: 10px;
          transition: 1s;
        }
        &:hover {
          span {
            display: inline;
          }
        }
        .Alertas {
          position: relative;
        }
        .alerts {
          width: 18px;
          height: 18px;
          display: flex;

          justify-content: center;
          align-items: center;
          position: absolute;
          top: 8px;
          right: 10px;
          background-color: rgba(246, 142, 42, 1);
          font-size: 12px;
          border-radius: 50%;
          animation: puff-in-center 0.4s cubic-bezier(0.47, 0, 0.745, 0.715)
            both;
        }
      }
      .select {
        border-bottom: 7px solid white;
      }
    }

    .sectionBottom {
      cursor: pointer;
      display: flex;
      gap: 10px;
      font-size: 35px;
      color: rgba(228, 228, 228, 1);
      .admin {
        margin-bottom: 10px;
        span {
          display: none;
          position: absolute;
          left: 50px;
          font-size: 18px;
          background-color: rgba(30, 30, 30, 0.8);
          padding: 10px;
          border-radius: 10px;
          transition: 1s;
        }
        &:hover {
          span {
            display: inline;
          }
        }
      }
      .exit {
        position: relative;
        span {
          display: none;
          position: absolute;
          left: 50px;
          font-size: 18px;
          background-color: rgba(30, 30, 30, 0.8);
          padding: 10px;
          border-radius: 10px;
          transition: 1s;
        }
        &:hover {
          span {
            display: inline;
          }
        }
      }
    }
  }
  @media (min-width: 601px) {
    display: none;
  }
  @media (max-width: 380px) {
    ul {
      li {
        width: 40px;
      }
    }
  }
`;

export const ModalAviso = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: calc(
    100vw - 60px
  ); /* 100% da largura da tela menos a largura do HeaderStyled2 */
  height: 100vh;
  max-height: 100%;
  left: 60px; /* Começa após a borda direita do HeaderStyled2 */
  background: rgba(21, 21, 21, 0.1);
  .conteiner {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 50%;
    background-color: rgba(33, 33, 33, 0.95);
    padding: 25px;
    border-radius: 18px;
    font-size: 20px;
    gap: 25px;
    position: relative;
    -webkit-animation: scale-in-center 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
      both;
    animation: scale-in-center 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    .fecharModal {
      font-size: 35px;
      position: absolute;
      top: 20px;
      right: 20px;
      color: rgba(221, 221, 221, 0.89);
      cursor: pointer;
      transition: 0.5s;
    }
    .fecharModal:hover {
      color: rgba(255, 255, 255, 1);
    }
    .icon {
      font-size: 55px;
      color: rgba(255, 252, 0, 0.9);
    }
    p {
      font-size: 18px;
      span {
        font-weight: bold;
      }
    }
    img {
      width: 80px;
      opacity: 70%;
    }
  }
  @media (max-width: 850px) {
    .conteiner {
      width: 90%;
    }
  }
`;

export const HeaderMobileStyled = styled.header`
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 10;
  scrollbar-width: none;
  .additionalSection {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 55px;
    padding-right: 5px;
    padding-top: 5px;
    background-color: rgba(29, 29, 29, 1);
    border-top: 2px solid rgba(192, 192, 192, 0.5);
    animation: slide-in-blurred-bottom 0.6s cubic-bezier(0.23, 1, 0.32, 1) both;
    z-index: 2;
    ul {
      display: flex;
      gap: 10px;
      padding-left: 5px;
      li {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border-bottom: 5px solid transparent;
        position: relative;
        width: 60px;
        padding-bottom: 5px;
        transition: 1s;
        font-size: 35px;
        color: rgba(228, 228, 228, 1);
        img {
          max-width: 75%;
          margin-bottom: 10px;
        }
        .iconHome {
          margin-bottom: 5px;
        }
        span {
          display: none;
          position: absolute;
          left: 62px;
          font-size: 18px;
          background-color: rgba(30, 30, 30, 0.8);
          padding: 10px;
          border-radius: 10px;
          transition: 1s;
        }
        &:hover {
          span {
            display: inline;
          }
        }
        .Alertas {
          position: relative;
        }
        .alerts {
          width: 18px;
          height: 18px;
          display: flex;

          justify-content: center;
          align-items: center;
          position: absolute;
          top: 8px;
          right: 10px;
          background-color: rgba(246, 142, 42, 1);
          font-size: 12px;
          border-radius: 50%;
          animation: puff-in-center 0.4s cubic-bezier(0.47, 0, 0.745, 0.715)
            both;
        }
      }
      .select {
        border-bottom: 7px solid white;
      }
    }
    .sectionBottom {
      cursor: pointer;
      display: flex;
      gap: 10px;
      font-size: 35px;
      color: rgba(228, 228, 228, 1);
      .admin {
        margin-bottom: 10px;
        span {
          display: none;
          position: absolute;
          left: 50px;
          font-size: 18px;
          background-color: rgba(30, 30, 30, 0.8);
          padding: 10px;
          border-radius: 10px;
          transition: 1s;
        }
        &:hover {
          span {
            display: inline;
          }
        }
      }
      .exit {
        position: relative;
        span {
          display: none;
          position: absolute;
          left: 50px;
          font-size: 18px;
          background-color: rgba(30, 30, 30, 0.8);
          padding: 10px;
          border-radius: 10px;
          transition: 1s;
        }
        &:hover {
          span {
            display: inline;
          }
        }
      }
    }
  }

  .fistSection {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 100%;
    overflow: auto;
    height: 55px;
    padding-right: 5px;
    padding-top: 5px;
    background-color: rgba(29, 29, 29, 1);
    color: rgba(228, 228, 228, 1);
    z-index: 99;
    ul {
      display: flex;
      gap: 5px;
      padding-left: 5px;
      li {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border-bottom: 5px solid transparent;
        position: relative;
        width: 60px;
        /* padding-bottom: 5px; */
        transition: 1s;
        font-size: 35px;
        color: rgba(228, 228, 228, 1);
        img {
          max-width: 60%;
        }
        .iconHome {
          /* margin-bottom: 5px; */
        }
        span {
          display: none;
          position: absolute;
          left: 62px;
          font-size: 18px;
          background-color: rgba(30, 30, 30, 0.8);
          padding: 10px;
          border-radius: 10px;
          transition: 1s;
        }
        &:hover {
          span {
            display: inline;
          }
        }
        .Alertas {
          position: relative;
        }
        .alerts {
          width: 18px;
          height: 18px;
          display: flex;

          justify-content: center;
          align-items: center;
          position: absolute;
          top: 8px;
          right: 10px;
          background-color: rgba(246, 142, 42, 1);
          font-size: 12px;
          border-radius: 50%;
          animation: puff-in-center 0.4s cubic-bezier(0.47, 0, 0.745, 0.715)
            both;
        }
      }
      .select {
        border-bottom: 7px solid white;
      }
    }

    .sectionBottom {
      cursor: pointer;
      display: flex;
      gap: 10px;
      font-size: 35px;
      color: rgba(228, 228, 228, 1);
      .admin {
        margin-bottom: 10px;
        span {
          display: none;
          position: absolute;
          left: 50px;
          font-size: 18px;
          background-color: rgba(30, 30, 30, 0.8);
          padding: 10px;
          border-radius: 10px;
          transition: 1s;
        }
        &:hover {
          span {
            display: inline;
          }
        }
      }
      .exit {
        position: relative;
        span {
          display: none;
          position: absolute;
          left: 50px;
          font-size: 18px;
          background-color: rgba(30, 30, 30, 0.8);
          padding: 10px;
          border-radius: 10px;
          transition: 1s;
        }
        &:hover {
          span {
            display: inline;
          }
        }
      }
    }
  }
  @media (min-width: 650px) {
    display: none;
  }
  @media (max-width: 380px) {
    ul {
      li {
        width: 40px;
      }
    }
  }
`;
