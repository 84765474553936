import React, { useContext, useState } from "react";
import { AiChat } from "../../styles/IA/chatBot";
import { BiSolidSend } from "react-icons/bi";
import { FaSquare } from "react-icons/fa6";
import { SquareLoading } from "../../components/loaders";
import aclsLogo from "../../assets/logos/novaLogoAcls.png";
import { IAContext } from "../../contexts/contexIA";
import Carousel from "../../components/carousel";
import imgExemplo01 from "../../assets/exemplosIA/ExemplosIa (1).jpeg";
import imgExemplo02 from "../../assets/exemplosIA/ExemplosIa (1).jpg";
import imgExemplo03 from "../../assets/exemplosIA/ExemplosIa (2).jpg";
import imgExemplo04 from "../../assets/exemplosIA/ExemplosIa (3).jpg";
import imgExemplo05 from "../../assets/exemplosIA/ExemplosIa (4).jpg";

const imgs = [
  imgExemplo01,
  imgExemplo02,
  imgExemplo03,
  imgExemplo04,
  imgExemplo05,
  imgExemplo05,
];

const ImgGeneretorIa = () => {
  const { output, setOutput } = useContext(IAContext);
  const [input, setInput] = useState("");
  const [loading, setLoading] = useState(false);

  const fetchModelOutput = async (input) => {
    setLoading(true);
    try {
      const response = await fetch(
        "https://api-inference.huggingface.co/models/black-forest-labs/FLUX.1-schnell",
        {
          headers: {
            Authorization: "Bearer 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", // Sua chave de API
            "Content-Type": "application/json",
          },
          method: "POST",
          body: JSON.stringify({ inputs: input }),
        }
      );

      if (!response.ok) {
        throw new Error("Erro ao chamar a API");
      }

      const blob = await response.blob();
      const imageUrl = URL.createObjectURL(blob);

      setOutput((prevOutput) => [
        ...prevOutput,
        { result: imageUrl, text: input },
      ]);
      return imageUrl;
    } catch (error) {
      console.error("Error fetching model output:", error);
      return "Ocorreu um erro ao obter a saída.";
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    await fetchModelOutput(input);
    setInput("");
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleSubmit();
    }
  };

  const handleInput = (event) => {
    const textarea = event.target;
    textarea.style.height = "auto";
    textarea.style.height = `${Math.min(textarea.scrollHeight, 4 * 24)}px`;
  };

  return (
    <AiChat>
      <section className="chatMessage">
        {loading && <SquareLoading />}
        {output.length > 0 ? (
          <ul className="MensagensIa">
            {output.map((imgIa) => {
              return (
                <li className={"bot imgBot"}>
                  <div className="inpultUser">
                    <div>
                      <span>{imgIa.text}</span>
                    </div>
                  </div>
                  <div className="imgGeneretor">
                    <img src={imgIa.result} alt="Resultado" />
                  </div>
                </li>
              );
            })}
          </ul>
        ) : (
          <div className="InicialPage">
            <h2 className="ImgH">
              <img src={aclsLogo} alt="" />
              Acls Tecnologia{" "}
            </h2>
            <div className="conteinerImg">
              <div className="text">
                <h3>
                  Transforme suas ideias em imagens: a criatividade ganha vida!
                </h3>
              </div>
              <Carousel images={imgs} interval={5000} />
            </div>
          </div>
        )}
      </section>
      <section className="InpultMenssage">
        <div className="styledInpult">
          <textarea
            rows={1}
            className="sendMessage"
            onChange={(e) => {
              if (!loading) {
                setInput(e.target.value);
              }
            }}
            value={input}
            onKeyDown={handleKeyDown}
            onInput={handleInput}
          />{" "}
          {loading ? (
            <FaSquare className="icon" />
          ) : (
            <BiSolidSend
              className="icon"
              onClick={() => {
                if (setInput !== "") {
                  handleSubmit();
                }
              }}
            />
          )}{" "}
        </div>
      </section>
    </AiChat>
  );
};

export default ImgGeneretorIa;
