import styled from "styled-components";
import imgBg from "../../assets/imgs-background/background1.png";

export const EstacaoClimaStyled = styled.main`
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: center;
  min-height: 100vh;
  width: 100%;
  background-image: url(${imgBg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  .secaoGeral {
    display: flex;
    width: 96%;
    max-width: 100%;
    min-height: 100vh;
    /* max-height: 100%; */
    /* height: 100vh; */
    position: relative;
    gap: 15px;
    padding: 8px;
    .blocosA {
      display: flex;
      flex-direction: column;
      width: 45%;
      /* max-height: 100%; */
      gap: 2%;
      .blocoA {
        width: 100%;
        height: 44%;
        background-color: var(--background);
        border-radius: 20px;
      }
      .Previsao {
        width: 100%;
        background-color: var(--background);
        border-radius: 20px;
        height: 54%;
        .diasSemanaConteiner {
          height: 100%;
          max-width: 100%;
        }
      }
    }
    .blocosB {
      display: flex;
      flex-direction: column;
      max-width: 100%;
      width: 54%;
      min-height: 100vh;
      max-height: 100%;
      background-color: var(--background);
      gap: 2%;
      padding: 10px;
      border-radius: 20px;

      .blocoB-AB {
        display: flex;
        gap: 15px;
        height: 44%;
        .blocoB-A {
          border-radius: 20px;
          width: 50%;
          max-width: 50%;
          height: 100%;
          background-color: var(--background);
          color: var(--bgBranco);
          .dadosDoVento {
            min-height: min-content;
            max-height: max-content;
          }
        }
        .blocoB-B {
          border-radius: 20px;
          width: 50%;
          max-width: 50%;
          height: 100%;
          background-color: var(--background);
          color: var(--bgBranco);
          .dadosDaUmidade {
            min-height: min-content;
          }
        }
      }
      .blocoB-C {
        display: flex;
        flex-direction: column;
        max-width: 100%;
        height: 55%;
        background-color: var(--background);
        color: var(--bgBranco);
        border-radius: 20px;
        .headerGraficos {
          padding: 15px;
          display: flex;
          justify-content: space-between;
          padding-bottom: 15px;
          position: relative;
          .botao {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 40px;
            height: 40px;
            background-color: var(--bgBotao);
            color: var(--branco);
            /* border: none; */
            font-size: 30px;
            border-radius: 10px;
            cursor: pointer;
          }
          .botao:hover {
            border: 1px solid var(--branco);
          }
          .SelectTitulo {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            position: relative;
            .titulo {
              width: 300px;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 18px;
              gap: 10px;

              .iconePressao {
                gap: 10px;
                width: 60px;
              }
              .iconeTemperatura {
                gap: 10px;
                width: 25px;
              }
            }
            .Seletor {
              list-style: none;
              display: flex;
              gap: 10px;
              flex-direction: column;
              text-align: center;
              position: absolute;
              top: 50px;
              width: 300px;
              background-color: rgba(0, 0, 0, 0.8);
              border-radius: 0 0 15px 15px;
              padding: 10px;
              z-index: 1;
              li {
                cursor: pointer;
              }
              li:hover {
                background-color: var(--bgBotao);
              }
            }
          }
          .calendario {
            display: flex;
            align-items: center;
            font-size: 50px;
            height: fit-content;
            max-height: 100%;
            position: relative;
            cursor: pointer;
            .conteinerIcon {
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;
              span {
                font-size: 18px;
                position: absolute;
                top: 19px;
                left: 14px;
              }
            }
          }
        }
      }
    }
  }

  .calendarDiv {
    display: flex;
    align-items: center;
    z-index: 9;
    padding-top: 50px;
    gap: 20px;
    flex-direction: column;
    width: 100%;
    height: 100%;
    position: absolute;
    background: rgb(0, 0, 0);
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.88) 70%,
      rgba(0, 0, 0, 0) 100%
    );
    .close {
      position: absolute;
      right: 30%;
      font-size: 30px;
      cursor: pointer;
      color: rgba(199, 198, 198, 1);
      transition: 0.5s;
    }
    .close:hover {
      color: white;
    }
    h3 {
      font-size: 25px;
      text-shadow: 3px 1px 5px rgba(0, 0, 0, 0.64);
      color: white;
    }
    .calendar {
      border-radius: 20px;
      overflow: auto;
      /* background-color: rgba(35, 35, 35, 0.82); */
      .react-calendar__navigation button {
        background-color: rgba(0, 0, 0, 0.5); /* Cor de fundo do botão */
        color: white; /* Cor do texto */
        border: none; /* Removendo a borda padrão */
      }
    }
    .buutons {
      display: flex;
      justify-content: center;
      gap: 20px;
      width: 350px;
      max-width: 100%;
      button {
        padding: 10px 20px;
        cursor: pointer;
        border-radius: 8px;
        font-size: 16px;
      }
    }
  }

  @media (max-width: 1400px) {
    .secaoGeral {
      width: 94%;
    }
  }

  //Mobile
  @media (max-width: 1000px) {
    .secaoGeral {
      flex-direction: column;
      width: 92%;

      .blocosA {
        width: 100%;
        height: 95vh;

        .blocoA {
          width: 100%;
          height: 49%;
          background-color: var(--background);
          border-radius: 20px;
        }
        .Previsao {
          .diasSemana {
            .dia {
            }
            .Status {
              font-weight: 600;
              font-size: 2vw;
              text-transform: capitalize;
              max-width: 100%;
              text-align: center;
            }

            .tempPre {
              font-size: 2vw;
            }
          }
        }
      }

      .blocosB {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .blocoB-C {
          height: 100%;
        }
      }
    }
  }

  @media (max-width: 650px) {
    align-items: center;
    .secaoGeral {
      width: 100%;
      .blocosA {
        width: 100%;
        height: 95vh;
        .blocoA {
          width: 100%;
          height: 49%;
          background-color: var(--background);
          border-radius: 20px;
        }
        .Previsao {
          .diasSemanaConteiner {
            overflow-x: auto;
            max-width: 100%;
            padding: 2px;
            scrollbar-width: thin; /* Define a largura da barra de rolagem como fina */
            scrollbar-color: rgba(161, 161, 161, 0.9) transparent;
            .diasSemana {
              width: 620px;
              max-width: 620px;
              .dia {
                width: 240px;
                max-width: 240px;
              }
              .Status {
                font-weight: 600;
                font-size: 15px;
                text-transform: capitalize;
                max-width: 100%;
                text-align: center;
              }

              .tempPre {
                font-size: 14px;
              }
            }
          }
          .diasSemanaConteiner::-webkit-scrollbar {
            height: 8px; /* Altura da barra de rolagem no eixo X */
          }

          .diasSemanaConteiner::-webkit-scrollbar-track {
            background: transparent; /* Cor do fundo da faixa da barra de rolagem */
          }

          .diasSemanaConteiner::-webkit-scrollbar-thumb {
            background: rgba(
              161,
              161,
              161,
              0.9
            ); /* Cor e opacidade da barra de rolagem */
            border-radius: 4px; /* Arredondamento das bordas da barra de rolagem */
          }
        }
      }
      .blocosB {
        min-height: min-content;
        gap: 15px;
        .blocoB-AB {
          flex-direction: column;
          height: min-content;

          .blocoB-A {
            width: 100%;
            max-width: 100%;
            height: 50%;
          }
          .blocoB-B {
            width: 100%;
            max-width: 100%;
            height: 50%;
          }
        }

        .blocoB-C {
          height: 350px;
          .headerGraficos {
            max-width: 100%;
            .SelectTitulo {
              .titulo {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
`;
