import { useContext, useEffect, useRef, useState } from "react";
import {
  CustomMarker,
  Ponteiro,
  RelogioContainer,
  StyledMapGl,
  SunGraphic,
  SunStatus,
  UserLoc,
} from "../styles/Pivo/map";
import { Layer, Marker, Source } from "react-map-gl";
import { PivoContext } from "../contexts/contexPivos";
import { HomeContext } from "../contexts/contexHome";
import PieChart from "./radarPivo";
import "mapbox-gl/dist/mapbox-gl.css";
import imgCompassRose from "../assets/icons/rosasdosventos.png";

const skyLayer = {
  id: "sky",
  type: "sky",
  paint: {
    "sky-type": "atmosphere",
    "sky-atmosphere-sun": [0.0, 0.0],
    "sky-atmosphere-sun-intensity": 15,
  },
};

// Função para calcular a posição do Sol no céu (azimute e elevação)
const calculateSunPosition = (minutes) => {
  const totalMinutesInDay = 1440; // 24 horas * 60 minutos
  const angle = (minutes / totalMinutesInDay) * 360; // Calcula o ângulo do Sol no céu

  // Converter o ângulo para radianos
  const rad = (angle * Math.PI) / 180;

  // Calcular a posição X e Y do Sol
  const x = Math.cos(rad);
  const y = Math.sin(rad);

  return [x, y];
};

// Função para alterar a cor do céu ao longo do dia
const getSkyColor = (minutes) => {
  const totalMinutesInDay = 1440;

  if (minutes < 360) {
    // Antes das 06:00
    return "hsl(240, 100%, 20%)"; // Noite
  } else if (minutes < 720) {
    // 06:00 - 12:00
    return "hsl(60, 100%, 85.88235294117648%)"; // Manhã
  } else if (minutes < 1080) {
    // 12:00 - 18:00
    return "hsl(50, 100%, 90%)"; // Tarde
  } else if (minutes < 1320) {
    // 18:00 - 22:00
    return "hsl(30.291262135922338, 100%, 79.80392156862746%)"; // Entardecer
  } else {
    // 22:00 em diante
    return "hsl(240, 100%, 30%)"; // Noite
  }
};

export const MapSun = () => {
  const {
    cameraState,
    valueSun,
    pivosMarker,
    listPivosData,
    scaleValue,
    setScaleValue,
    setValueSun,
  } = useContext(PivoContext);
  const mapRef = useRef();
  const { user } = useContext(HomeContext);

  const [percentage, setPercentage] = useState(50);

  const [viewport, setViewport] = useState({
    longitude: cameraState.center.lng,
    latitude: cameraState.center.lat,
    zoom: 14.3,
    pitch: 75,
    minZoom: 1,
    maxZoom: 16,
  });

  useEffect(() => {
    const now = new Date();
    const currentMinutes = now.getHours() * 60 + now.getMinutes();
    setValueSun(currentMinutes);
  }, []);

  const calculateScale = (zoom) => {
    let finalScale = Math.pow(2, zoom - 15);
    setScaleValue(finalScale);
  };
  const calcAzimuth = (lat1, lon1, lat2, lon2) => {
    // Converter graus para radianos
    var rad = Math.PI / 180;
    lat1 *= rad;
    lat2 *= rad;
    lon1 *= rad;
    lon2 *= rad;

    // Diferença de longitude
    var dLon = lon2 - lon1;

    // Cálculo do azimute
    var y = Math.sin(dLon) * Math.cos(lat2);
    var x =
      Math.cos(lat1) * Math.sin(lat2) -
      Math.sin(lat1) * Math.cos(lat2) * Math.cos(dLon);
    var azimuth = Math.atan2(y, x);

    // Converter de radianos para graus
    azimuth = azimuth / rad;

    // Ajuste para valores entre 0 e 360 graus
    if (azimuth < 0) {
      azimuth += 360;
    }

    return azimuth;
  };

  const handleViewportMove = (newViewport) => {
    setViewport((prevViewport) => ({
      ...prevViewport,
      longitude: newViewport.longitude,
      latitude: newViewport.latitude,
      zoom: newViewport.zoom,
      pitch: newViewport.pitch,
      // bearing: newViewport.bearing,
    }));

    calculateScale(newViewport.zoom);
  };

  useEffect(() => {
    setViewport({
      longitude: cameraState.center.lng,
      latitude: cameraState.center.lat,
      zoom: 14.3,
      pitch: 75,
      bearing: 0,
      minZoom: 1,
      maxZoom: 16,
    });
  }, [cameraState]);

  useEffect(() => {
    if (mapRef.current) {
      const map = mapRef.current.getMap();

      const updateSkyAndFog = () => {
        const sunPosition = calculateSunPosition(valueSun);

        const isNightTime = valueSun < 300 || valueSun >= 1140; // 00:00 - 05:00 e 19:00 - 23:59
        const isMorning = valueSun >= 300 && valueSun < 720; // 05:00 - 12:00
        const isAfternoon = valueSun >= 720 && valueSun < 1020; // 12:00 - 17:00
        const isLateAfternoon = valueSun >= 1020 && valueSun < 1140; // 17:00 - 19:00

        let skyColor = "#000231f9"; // Padrão para madrugada
        let fogColor = "rgba(0, 8, 45, 0.9)";
        let highColor = "rgba(0, 0, 0, 1)";
        let horizonBlend = 1;
        let starIntensity = 1.0;

        // Escurecimento progressivo durante a madrugada
        if (isNightTime) {
          const progress =
            valueSun < 300 ? valueSun / 300 : (1440 - valueSun) / 300;
          skyColor = `rgb(${Math.floor(50 * progress)}, ${Math.floor(
            50 * progress
          )}, ${Math.floor(50 * progress)})`;
          fogColor = `rgba(${Math.floor(20 + 30 * progress)}, ${Math.floor(
            20 + 30 * progress
          )}, ${Math.floor(20 + 30 * progress)}, 0.9)`;
          highColor = `rgba(${Math.floor(30 * progress)}, ${Math.floor(
            30 * progress
          )}, ${Math.floor(30 * progress)}, 1)`;
          starIntensity = 1.0 - 0.5 * progress;
        }

        // Clareamento progressivo durante a manhã até 11h
        if (isMorning) {
          const progress = (valueSun - 300) / 420; // 05:00 às 12:00
          const hue = 45; // Tom de amarelo
          const lightness = 50 + 30 * progress; // Clareamento gradual
          skyColor = `hsl(${hue}, 100%, ${lightness}%)`;
          fogColor = `rgba(${Math.floor(255 * progress)}, ${Math.floor(
            223 * progress
          )}, ${Math.floor(65 * (1 - progress))}, 0.475)`;
          highColor = `rgba(${Math.floor(36 * (1 - progress))}, ${Math.floor(
            151 * progress
          )}, ${Math.floor(223 * progress)}, 0.473)`;
          starIntensity = 0.0;
          horizonBlend = 0.02;
        }

        // A partir das 11h, deixar o amarelo bem mais claro
        if (isAfternoon) {
          const progress = (valueSun - 720) / 300; // 12:00 às 17:00
          const hue = 60; // Amarelo mais claro
          const lightness = 70 + 10 * progress; // Tom mais claro
          skyColor = `hsl(${hue}, 100%, ${lightness}%)`;
          fogColor = `rgba(255, 240, 180, 0.475)`; // Tom de neblina mais suave
          highColor = `rgba(255, 240, 180, 0.473)`; // Tons mais claros
          horizonBlend = 0.01;
        }

        // Escurecimento progressivo a partir das 17h30
        if (isLateAfternoon) {
          const progress = (valueSun - 1020) / 120; // 17:00 às 19:00
          skyColor = `hsl(60, 100%, ${80 - 40 * progress}%)`;
          fogColor = `rgba(${Math.floor(255 * (1 - progress))}, ${Math.floor(
            223 * (1 - progress)
          )}, ${Math.floor(65 * (1 - progress))}, 0.475)`;
          highColor = `rgba(${Math.floor(36 * progress)}, ${Math.floor(
            151 * (1 - progress)
          )}, ${Math.floor(223 * (1 - progress))}, 0.473)`;
        }

        map.setFog({
          color: fogColor,
          "high-color": highColor,
          "horizon-blend": horizonBlend,
          "space-color": "rgb(16, 35, 65)",
          "star-intensity": starIntensity,
        });

        if (map.getLayer("sky")) {
          map.setPaintProperty("sky", "sky-atmosphere-sun", sunPosition);
          map.setPaintProperty(
            "sky",
            "sky-atmosphere-sun-intensity",
            isNightTime ? 0.1 : 15
          );
          map.setPaintProperty("sky", "sky-gradient", {
            "10%": skyColor,
            "100%": "#000a33",
          });
        } else {
          map.addLayer(skyLayer);
        }
      };

      if (!map.isStyleLoaded()) {
        map.on("style.load", updateSkyAndFog);
      } else {
        updateSkyAndFog();
      }
    }
  }, [valueSun]);

  const calculateRotation = (pitch) => `rotateX(${pitch}deg)`;
  const calculateBearing = (bearing) => `rotate(${-bearing}deg);`;
  const calculateRotationN = (pitch) => `rotateX(${pitch - 73}deg)`;
  const calculateBearingN = (bearing) => `rotateY(${bearing}deg);`;
  const calculateZ = (bearing) => `rotateZ(${bearing}deg);`;
  const ajusteSun = (pitch, pivoSize) => {
    const percentage = (pitch / 85) * 100;

    const invertedPercentage = 100 - percentage;

    let calculatedValue = ((invertedPercentage / 100) * pivoSize) / 2.8;

    if (calculatedValue > 20 && calculatedValue < 80) {
      calculatedValue = calculatedValue - 20;
    }

    return ` translateX(${calculatedValue}px);`;
  };

  useEffect(() => {
    // Função para calcular a porcentagem
    const calculatePercentage = (value, max) => (value / max) * 100;

    // Atualiza a porcentagem com base no valueSun
    const max = 1439;
    const newPercentage = calculatePercentage(valueSun, max);

    // Define o novo estado da porcentagem
    setPercentage(newPercentage);

    // Adicionalmente, você pode adicionar lógica aqui para o que deseja fazer com a porcentagem
  }, [valueSun]);

  const handleMouseDown = (event) => {
    const sunStatusElement = document.getElementById("sun-status");

    const onMouseMove = (e) => {
      const rect = sunStatusElement.getBoundingClientRect();
      const x = e.clientX - rect.left - rect.width / 2;
      const y = e.clientY - rect.top - rect.height / 2;
      const angle = Math.atan2(y, x) * (180 / Math.PI) + 90;
      const adjustedAngle = (angle + 360) % 360; // Ajustar o ângulo para ser sempre positivo
      setPercentage((adjustedAngle / 360) * 100);
    };

    const onMouseUp = () => {
      window.removeEventListener("mousemove", onMouseMove);
      window.removeEventListener("mouseup", onMouseUp);
    };

    window.addEventListener("mousemove", onMouseMove);
    window.addEventListener("mouseup", onMouseUp);
  };

  return (
    <StyledMapGl
      ref={mapRef}
      {...viewport}
      onMove={(e) => handleViewportMove(e.viewState)}
      dragPan={false}
      touchZoom={false}
      touchRotate={false}
      scrollZoom={false}
      keyboard={false}
      // dragRotate={false}
      doubleClickZoom={false}
      mapboxAccessToken={process.env.REACT_APP_MAPSBOX_API_KEY}
      mapStyle={"mapbox://styles/mapbox/satellite-v9"}
    >
      {pivosMarker &&
        pivosMarker.map((pivo) => {
          const pivoDados = listPivosData.find((e) => pivo.id === e.pivo);

          let latitude_centro = pivoDados ? pivoDados.latitude_centro : null;
          let longitude_centro = pivoDados ? pivoDados.longitude_centro : null;
          let latitude_ponta = pivoDados ? pivoDados.latitude_ponta : null;
          let longitude_ponta = pivoDados ? pivoDados.longitude_ponta : null;

          let angulo = calcAzimuth(
            latitude_centro,
            longitude_centro,
            latitude_ponta,
            longitude_ponta
          );
          return (
            <Marker
              key={pivo.id}
              longitude={pivo.longitude}
              latitude={pivo.latitude}
              className={"Marker"}
              // onClick={() =>
              //   locateMap(pivo.latitude, pivo.longitude, 60, 15, pivo.id)
              // }
            >
              <SunGraphic
                size={`${pivo.tamanho}px`}
                sizeh={`${pivo.tamanho}px`}
                pitch={calculateRotationN(viewport.pitch)}
                bearing={calculateBearingN(viewport.bearing)}
                seila={calculateZ(viewport.bearing)}
                id="sun-status"
              >
                <SunStatus
                  percentage={percentage}
                  vaipp={`${(pivo.tamanho * 51) / 100}px`}
                  ajuste={ajusteSun(viewport.pitch, pivo.tamanho)}
                >
                  {/* <Cube /> */}
                  <div
                    className="SunPosition"
                    // onMouseDown={handleMouseDown}
                  >
                    {/* <SunSphere /> */}
                  </div>
                </SunStatus>
                <div className="container">
                  <div className="circle"></div>
                </div>
              </SunGraphic>
              {/* <Cube /> */}

              <CustomMarker
                size={`${pivo.tamanho}px`}
                scale={1.2}
                pitch={calculateRotation(viewport.pitch)}
                bearing={calculateBearing(viewport.bearing)}
              >
                <div className="compassRose">
                  <img src={imgCompassRose} alt="" />
                </div>
                <RelogioContainer>
                  {pivoDados && pivoDados.status_pivo && (
                    <PieChart
                      angulo={angulo}
                      statusAgua={pivoDados.status_agua}
                    />
                  )}
                  <Ponteiro angle={angulo} />
                  <span></span>
                </RelogioContainer>
              </CustomMarker>
            </Marker>
          );
        })}
      {user.longitude && (
        <Marker longitude={user.longitude} latitude={user.latitude}>
          <UserLoc />
        </Marker>
      )}

      <Layer {...skyLayer} />
    </StyledMapGl>
  );
};
