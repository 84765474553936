import styled, { keyframes } from "styled-components";

const animateGradiente = keyframes`
  0% {
    background-position: 0% 0;
  }
  100% {
    background-position: 100% 0;
  }
`;

export const AiChat = styled.main`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  .chatMessage {
    width: 100%;
    height: 100%;
    max-height: 100%;
    padding: 50px;
    overflow-y: auto;
    overflow-x: hidden;
    .DownButton {
      position: fixed;
      bottom: 80px;
      left: 50%;
      background-color: transparent;
      border: none;
      cursor: pointer;
      .icon {
        font-size: 25px;
        color: white;
      }
    }
    .InicialPage {
      display: flex;
      flex-direction: column;
      width: 100%;
      h2 {
        background: #ff771d;
        background: radial-gradient(
          circle farthest-corner at bottom left,
          #ff771d 37%,
          #0b04cf 100%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        width: 135px;
        max-width: 100%;
        font-size: 55px;
      }
      .ImgH {
        -webkit-text-fill-color: transparent;
        width: 50%;
        display: flex;
        align-items: start;
        gap: 10px;
        img {
          width: 60px;
        }
      }
      h3 {
        background: #574a48;
        background: radial-gradient(
          circle farthest-corner at left center,
          #574a48 0%,
          #cfcfcf 24%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

        font-size: 30px;
      }

      .imgLogo {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 80px;
        img {
          width: 60px;
          filter: grayscale(100%);
          opacity: 60%;
        }
      }
      .conteinerImg {
        display: flex;
        width: 100%;
        height: 70vh;
        align-items: center;
        justify-content: center;
        gap: 15px;
        .text {
          display: flex;
          align-items: center;
          width: 20%;
          padding-bottom: 30px;
          h3 {
            font-size: 45px;
            background-color: transparent;
            background: radial-gradient(
              circle farthest-corner at left center,
              rgba(255, 255, 255, 0) 0%,
              rgba(255, 255, 255, 0) 24%
            );
            -webkit-background-clip: border-box;
            -webkit-text-fill-color: rgba(188, 188, 188, 1);
          }
        }
      }
    }
    .MensagensIa {
      list-style: none;
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 25px;
      * {
        max-width: 100%;
      }
      .user {
        display: flex;
        justify-content: end;
        width: 100%;
        .cointeiner {
          display: flex;
          flex-direction: column;
          gap: 5px;
          padding: 15px;
          width: fit-content;

          color: white;
          span {
            font-size: 17px;
            padding-left: 10px;
          }
          .conteudo {
            background-color: rgba(0, 0, 0, 0.5);
            padding: 15px;
            border-radius: 10px;
            width: 100%;
            max-width: 600px;
          }
        }
      }
      .bot {
        display: flex;
        justify-content: center;
        .botHeader {
          display: flex;
          align-items: center;
          gap: 10px;
          img {
            width: 35px;
          }
          span {
            font-size: 22px;
          }
        }
        .cointeiner {
          color: rgba(216, 216, 216, 1);

          .conteudo {
            display: flex;
            font-family: Arial, Helvetica, sans-serif;
            line-height: 1.6;
            padding: 20px;
            width: 900px;
            max-width: 100%;
            justify-content: flex-start;
            flex-direction: column;
            gap: 15px;
          }

          .conteudo h1,
          .conteudo h2 {
            color: rgba(248, 248, 248, 1);
          }

          .conteudo ul {
            list-style-type: disc;
            margin-left: 20px;
          }

          .conteudo ol {
            margin-left: 20px;
          }

          .conteudo strong {
            font-weight: bold;
          }

          .conteudo a {
            color: white;
          }

          .conteudo pre {
            padding: 15px;
            background-color: rgba(0, 0, 0, 0.8);
            overflow-x: scroll;
            max-width: 100%;
          }
        }
      }
      .imgBot {
        display: flex;
        flex-direction: column;
        /* background-color: #574a48; */
        width: 80%;
        margin: 0 auto;
        align-items: center;
        gap: 25px;
        position: relative;
        .imgGeneretor {
          display: flex;
          width: 90%;
          max-width: fit-content;
          border-radius: 25px;
          overflow: hidden;
          height: fit-content;
          justify-content: center;
          img {
            max-width: 100%;
          }
        }
        .inpultUser {
          width: 100%;
          text-align: end;
          color: white;
          font-size: 20px;
          div {
            background-color: rgba(0, 0, 0, 0.5);
            padding: 8px;
            border-radius: 15px;
          }
        }
      }
      .headerLoading {
        display: flex;
        /* background-color: #cfcfcf; */
        width: 100%;
        .botHeader {
          width: 900px;
          max-width: 100%;
          .loading {
            width: 100%;
            background: repeating-linear-gradient(
              to right,
              rgba(7, 7, 7, 0.08) 0%,
              rgba(217, 217, 217, 0.05) 70%,
              rgba(7, 7, 7, 0.08) 100%
            );
            height: 25px;
            border-radius: 10px;
            background-size: 50% 100%;
            animation: ${animateGradiente} 2s infinite;
          }
        }
      }
      .notPri {
        z-index: 0;
      }
    }
  }

  .InpultMenssage {
    display: flex;
    width: 100%;
    height: 18%;
    align-items: end;
    justify-content: center;
    padding: 15px;
    z-index: 2;
    .styledInpult {
      width: 900px;
      max-height: 100%;
      display: flex;
      align-items: center;
      background-color: rgba(0, 0, 0, 0.5);
      padding: 10px 10px;
      border-radius: 28px;
      gap: 10px;
      border: 1px solid rgba(186, 186, 186, 0.18);
      textarea {
        width: 95%; /* Define a largura fixa */
        max-height: calc(
          4 * 1.5em
        ); /* Assume 1.5em como a altura de uma linha */
        height: auto; /* Permite ajuste automático da altura */
        max-height: 100%;
        resize: none; /* Impede o redimensionamento pelo usuário */
        border: none; /* Opcional: adiciona uma borda para visualizar melhor o textarea */
        padding: 5px 25px; /* Opcional: adiciona preenchimento interno */
        box-sizing: border-box; /* Inclui a borda e o preenchimento no cálculo da largura e altura */
        font-size: 20px;
        overflow-y: auto;
        background-color: transparent;
        color: white;
        outline: none; /* Remove a borda de foco padrão */
      }
      .icon {
        font-size: 22px;
        color: rgba(201, 201, 201, 1);
        cursor: pointer;
      }
    }
    button {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      border: none;
    }
  }

  @media (min-width: 600px) {
    /* ===== Scrollbar CSS ===== */
    /* Firefox */
    .chatMessage {
      scrollbar-width: auto;
      scrollbar-color: rgba(46, 46, 46, 1) rgba(245, 40, 145, 0);
    }

    /* Chrome, Edge, and Safari */
    .chatMessage::-webkit-scrollbar {
      width: 16px;
    }

    .chatMessage::-webkit-scrollbar-track {
      background: rgba(245, 40, 145, 0);
    }

    .chatMessage::-webkit-scrollbar-thumb {
      background-color: #000000;
      border-radius: 10px;
      border: 3px solid #ffffff;
    }
  }

  @media (max-width: 1400px) {
    .chatMessage {
      .InicialPage {
        .conteinerImg {
          .text {
            h3 {
              font-size: 30px;
            }
          }
        }
      }
    }
  }

  @media (max-width: 1000px) {
    .chatMessage {
      .InicialPage {
        .conteinerImg {
          flex-direction: column;
          .text {
            width: 100%;
            h3 {
              font-size: 30px;
            }
          }
        }
      }
    }
  }

  @media (max-width: 600px) {
    padding-bottom: 60px;
    .chatMessage {
      padding: 10px;
      .InicialPage {
        gap: 25px;
        .ImgH {
          width: 100%;
        }
        h3 {
          background: #574a48;
          background: radial-gradient(
            circle farthest-corner at left center,
            #ffffff 0%,
            #c4c4c4 24%
          );
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          font-size: 25px;
        }
      }
    }

    .InpultMenssage {
      .styledInpult {
        max-width: 100%;

        textarea {
          height: calc(4 * 0.6em);
          max-height: calc(4 * 0.6em);
          min-height: calc(4 * 0.6em);
        }
      }
    }
  }
`;
