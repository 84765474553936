import React, { useContext } from "react";
import { IaStyled } from "../../styles/IA/IA";
import { HeaderIA, HeaderMobileIA } from "./headerIA";
import { ChatAiGM } from "./geminiChat";
import { IAContext } from "../../contexts/contexIA";
import ImgGeneretorIa from "./generetImg";
import { IAPivoStyled } from "../../styles/IA/pivoIA";
import { IAPivo } from "./iaPivo";

export const IaPage = () => {
  const { iaPage } = useContext(IAContext);

  return (
    <IaStyled>
      <HeaderIA />
      <HeaderMobileIA />
      <section className="container">
        {iaPage === "Chat" && <ChatAiGM />}
        {iaPage === "Img" && <ImgGeneretorIa />}
        {iaPage === "Pivo" && <IAPivo />}
      </section>
    </IaStyled>
  );
};
