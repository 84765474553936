import { useNavigate } from "react-router-dom";
import { IoPersonCircle, IoExit } from "react-icons/io5";
import { useContext, useEffect, useState } from "react";
import { HomeContext } from "../contexts/contexHome";
import { HeaderStyled } from "../styles/header";

export const Header = ({ relative }) => {
  const { user } = useContext(HomeContext);
  const [relativeBooleano, setRelativeBoleano] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    if (relative === "false") {
      setRelativeBoleano(false);
    } else {
      setRelativeBoleano(true);
    }
  }, []);

  return (
    <HeaderStyled relative={relativeBooleano}>
      <section className="headerLeft"></section>
      <section className="headerRight">
        <span>{user.username}</span>
        <IoPersonCircle className="iconHeader" />
        <IoExit
          className="iconExit"
          onClick={() => {
            sessionStorage.removeItem("connectionsToken");
            sessionStorage.removeItem("connectionsUnit");
            navigate("/Login");
          }}
        />
      </section>
    </HeaderStyled>
  );
};
