import { Route, Routes } from "react-router-dom";
import { AdminProvider } from "../contexts/contexAdmin";
import { HomeProvider } from "../contexts/contexHome";
import { LoginProvider } from "../contexts/contexLogin";
import { PivosProvider } from "../contexts/contexPivos";
import { PageAdmin } from "../pages/admin";
import { Home } from "../pages/home";
import { Unidade1 } from "../pages/home/unidade1";
import { Login } from "../pages/login";
import { NotFound } from "../pages/notFound";
import { PagePivo } from "../pages/pivos";
import { PivoPanel } from "../pages/pivos/pivo";
import { EstacaoMeteorologica } from "../pages/meteorologia";
import { TanqueProvider } from "../contexts/contextTanque";
import { Tanque } from "../pages/tanques/tanques";
import { ClimaProvider } from "../contexts/contexClima";
import { IaPage } from "../pages/IA";
import { IAProvider } from "../contexts/contexIA";

export const RoutesMain = () => {
  return (
    <Routes>
      <Route element={<HomeProvider />}>
        <Route path="/" element={<Home />} />
        <Route path="/:unidade" element={<Unidade1 />} />

        <Route element={<AdminProvider />}>
          <Route path="/Admin" element={<PageAdmin />} />
        </Route>

        <Route element={<ClimaProvider />}>
          <Route path="/:unidade/clima" element={<EstacaoMeteorologica />} />
        </Route>

        <Route element={<TanqueProvider />}>
          <Route path="/:unidade/tanques" element={<Tanque />} />
        </Route>

        <Route element={<PivosProvider />}>
          <Route path="/:unidade/Pivos" element={<PagePivo />} />
          <Route
            path="/:unidade/PainelPivos/:section"
            element={<PivoPanel />}
          />
        </Route>
        <Route element={<IAProvider />}>
          <Route path="/:unidade/AclsIA" element={<IaPage />} />
        </Route>
      </Route>
      <Route element={<LoginProvider />}>
        <Route path="/Login" element={<Login />} />
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};
