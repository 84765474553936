import { createContext, useState } from "react";
import { Outlet } from "react-router-dom";

export const IAContext = createContext({});

export const IAProvider = () => {
  const [iaPage, setIaPage] = useState("Chat");
  const [output, setOutput] = useState([]);

  return (
    <IAContext.Provider value={{ iaPage, setIaPage, output, setOutput }}>
      <Outlet />
    </IAContext.Provider>
  );
};
