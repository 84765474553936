import { IAPivoStyled } from "../../styles/IA/pivoIA";
import pivoImg from "../../assets/icons/pivoIa (2).png";

export const IAPivo = () => {
  return (
    <IAPivoStyled>
      <div>
        <img src={pivoImg} alt="" />
        <h2>COMING SOON</h2>
      </div>
    </IAPivoStyled>
  );
};
