import styled, { keyframes } from "styled-components";

const slideIn = keyframes`
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`;

const slideOut = keyframes`
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(-100%);
    opacity: 0;
  }
`;

export const CarouselContainer = styled.div`
  position: relative;
  width: 450px;
  height: 500px;
  max-width: 100%;
  overflow: hidden;
`;

export const CarouselImage = styled.img`
  width: 100%;
  height: 0;
  border-radius: 15px;
  height: auto;
  animation: ${({ inView }) => (inView ? slideIn : slideOut)} 0.5s forwards;
`;

export const Indicators = styled.div`
  display: flex;
  height: 95%;
  justify-content: center;
  align-items: end;
  margin-top: 10px;
`;

export const Indicator = styled.span`
  width: 10px;
  height: 10px;
  margin: 0 5px;
  border-radius: 50%;
  background: ${({ active }) => (active ? "#FF9F02" : "lightgray")};
  cursor: pointer;
`;
