import { HeaderStyled2, ModalAviso } from "../styles/header";
import {
  IoHome,
  IoExit,
  IoCloseCircleOutline,
  IoWarningOutline,
} from "react-icons/io5";
import { SiPivotaltracker } from "react-icons/si";
import { FaCow } from "react-icons/fa6";
import { TbEngine } from "react-icons/tb";
import imgLogo from "../assets/logos/novaLogoAcls.png";
import { useNavigate } from "react-router-dom";
import imgPump from "../assets/icons/pumpWite.png";
import imgTanque from "../assets/icons/tanquesWhite.png";
import imgPivo from "../assets/icons/pivot2.png";
import { RiAdminFill } from "react-icons/ri";
import { useEffect, useState } from "react";
import { HomeContext } from "../contexts/contexHome";
import { useContext } from "react";
import imgLogoACLS from "../assets/logos/novaLogoAcls.png";
import { FaCloudSun } from "react-icons/fa";
import imgAgua from "../assets/icons/aguaBranca.png";
import imgAguaSuja from "../assets/icons/aguaSujaIcon.png";
import { apiConnect } from "../services/api";
import iaAClS from "../assets/icons/ia.png";

export const Header2 = () => {
  const { user, modalAvisoOpen, setModalAvisoOpen } = useContext(HomeContext);
  const navigate = useNavigate();

  const [loading, setloading] = useState(false);
  const [Unidades, setUnidades] = useState({});

  const unityExists = sessionStorage.getItem("connectionsUnit");
  const ListUnidade = [
    {
      titulo: "Pivô",
      status: true,
      navigate: `/${unityExists}/Painelpivos/Map`,
      img: imgPivo,
      icon: false,
    },
    {
      titulo: "Estações Meteorológicas",
      status: true,
      navigate: `/${unityExists}/clima`,
      img: <FaCloudSun className="icon" />,
      icon: true,
    },
    {
      titulo: "Tanques",
      status: true,
      navigate: `/${unityExists}/tanques`,
      img: imgTanque,
      icon: false,
    },
    {
      titulo: "Motobombas",
      status: false,
      navigate: `/${unityExists}/`,
      img: imgPump,
      icon: false,
    },
    {
      titulo: "Eletrobombas",
      status: false,
      navigate: `/${unityExists}/`,
      icon: true,
      img: <TbEngine className="icon" />,
    },
    {
      titulo: "Reservatório de Água Limpa",
      status: false,
      navigate: `/${unityExists}/`,
      img: imgAgua,
      icon: false,
    },
    {
      titulo: "Reservatório de Água Suja",
      status: false,
      navigate: `/${unityExists}/`,
      img: imgAguaSuja,
      icon: false,
    },
    {
      titulo: "Biodigestor",
      status: false,
      navigate: `/${unityExists}/`,
      icon: true,
      img: <FaCow className="icon" />,
    },
    {
      titulo: "Acls IA",
      status: false,
      navigate: `/${unityExists}/AclsIA`,
      icon: false,
      img: iaAClS,
    },
  ];

  const ListUnidades = async () => {
    setloading(true);
    try {
      const token = sessionStorage.getItem("connectionsToken");
      const idUnidade = sessionStorage.getItem("connectionsUnitId");

      const headerApi = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await apiConnect.get(
        `unidade/leitura/${idUnidade}`,
        headerApi
      );
      setUnidades(response.data);
      let locUnidade = {
        lat: response.data.latitude,
        long: response.data.longitude,
      };
      sessionStorage.setItem(
        "connectionsUnitIdloc",
        JSON.stringify(locUnidade)
      );
    } catch (error) {
    } finally {
      setloading(false);
    }
  };
  useEffect(() => {
    ListUnidades();
  }, []);

  return (
    <HeaderStyled2>
      {modalAvisoOpen && (
        <ModalAviso onClick={() => setModalAvisoOpen(!modalAvisoOpen)}>
          <div
            className="conteiner"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <IoCloseCircleOutline
              className="fecharModal"
              onClick={() => setModalAvisoOpen(!modalAvisoOpen)}
            />
            <IoWarningOutline className="icon" />
            <h3>Este serviço não foi contratado para esta unidade.</h3>
            <p>
              Entre em contato com ACLS caso tenha interesse em{" "}
              <span>contratar</span> este módulo.
            </p>
            <a
              href="https://aclstecnologia.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={imgLogoACLS} alt="" />
            </a>
          </div>
        </ModalAviso>
      )}

      <ul>
        <li
          className="logoLi"
          onClick={() => {
            navigate("/");
          }}
        >
          <img src={imgLogo} alt="" />
        </li>
        <li
          onClick={() => {
            navigate("/");
          }}
        >
          <IoHome className="iconHome" />
          <span>Home</span>
        </li>
        {ListUnidade.map((List, index) => {
          const aplicacaoContratada =
            Unidades &&
            Unidades.aplicacoes &&
            Unidades.aplicacoes[index] === "true";
          return (
            aplicacaoContratada && (
              <li
                key={index}
                onClick={() => {
                  navigate(List.navigate);
                }}
              >
                {/* <SiPivotaltracker /> */}
                {List.icon ? List.img : <img src={List.img} alt="" />}
                <span>{List.titulo}</span>
              </li>
            )
          );
        })}
      </ul>
      <div className="sectionBottom">
        {user.is_superuser ? (
          <div className="admin">
            <RiAdminFill
              onClick={() => {
                navigate("/admin");
                // window.location.reload();
              }}
            />
            <span>Admin</span>
          </div>
        ) : null}

        <div className="exit">
          <IoExit
            onClick={() => {
              sessionStorage.removeItem("connectionsToken");
              sessionStorage.removeItem("connectionsUnit");
              navigate("/Login");
            }}
          />
          <span>Sair</span>
        </div>
      </div>
    </HeaderStyled2>
  );
};
